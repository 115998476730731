import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';

import { getVTypeName } from '../../utils/vehicleType';
import api from '../../api';

const NestingList: FC<{
  title: string;
  children?: React.ReactNode;
  isOpen?: boolean;
  link?: string;
  dynamicItem?: {
    maker: number;
    model: number;
    partName: string;
    vehicleType: number;
  };
}> = ({
  title,
  children,
  isOpen,
  link,
  dynamicItem,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [innerContent, setInnerContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    if (isLoading) {
      return;
    }

    if (dynamicItem && !isActive) {
      const {partName, maker, model} = dynamicItem;

      setIsLoading(true);
      api
        .parts
        .getApplicableList(partName, maker, model)
        .then(res => res.json())
        .then((res) => {
          if (res?.data) {
            setInnerContent(res.data);
            setIsActive(true);
          }
        })
        .catch(error => console.error(error))
        .then(() => setIsLoading(false));
    } else {
      setIsActive(!isActive);
    }
  };

  useEffect(() => {
    setIsActive(isOpen);
  }, [isOpen]);

  return (
        <div className="nesting-list">
            <div className={cn(
              'nesting-list__head',
              { 'nesting-list__head_active': isActive },
              { 'nesting-list__head_loading': isLoading },
            )}>
                <div className="nesting-list__head-inner">
                    {
                        children || dynamicItem
                          ? <>
                            <button
                              type="button"
                              className="nesting-list__head-btn"
                              onClick={handleClick}
                              aria-label="Show/hide list"
                            />
                            <div className="nesting-list__head-title">{title}</div>
                          </>
                          : link
                            ? <a
                              href={link}
                              className="nesting-list__head-title nesting-list__head-title_link"
                            >{title}</a>
                            : null

                    }
                </div>
            </div>

            {
              isActive
                ? <div className="nesting-list__content">
                  {
                    children
                      ? children
                      : innerContent?.length > 0
                        ? innerContent.map(item => {
                          const vehicleTypeName = getVTypeName(dynamicItem.vehicleType);
                          const latestLevelTitle = [
                            item.title ?? null,
                            item.engine ? `(${item.engine})` : null,
                            item.kw,
                            item.ps,
                            [item.start_year, item.end_year].filter(Boolean).join(' – '),
                          ]
                            .filter(Boolean)
                            .join(' ');

                          return (
                            <NestingList
                              key={`applicable-car-${vehicleTypeName}-${item.id}`}
                              title={ latestLevelTitle }
                              link={`/catalog/${vehicleTypeName}/${item.id}/part/${dynamicItem.partName}`}
                            />
                          );
                        })
                        : null
                  }
                </div>
                : null
            }
        </div>
  );
};

export default NestingList;
