import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import api from '../../api';

import filter from '../../store/filter';

import DeepFilterBlock from './deep-filter-block';
import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';
import { VehicleType } from '../../utils/vehicleType';

const DeepFilter: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { search } = useLocation();
  const { vType } = useParams<{ vType: VehicleType }>();

  const rebuildSearchFilter = () => {
    const queryUrl = new URLSearchParams(search);
    let filterCriteria = [];

    if (queryUrl.get('page')) {
      queryUrl.delete('page');
    }

    queryUrl.forEach((value, queryKey) => {
      const [key, type] = queryKey.split(':');
      const element = filter.criteria.find(
        item => item.key === parseInt(key, 10),
      );
      const items = value.split('#,#');

      if (items.length > 0) {
        filterCriteria.push({
          key,
          type,
          title: element ? element.title : '',
          items: items.map(item => {
            const childElement = element?.items.find(
              childItem => childItem.key.toString() === item.toString(),
            );

            return {
              itemKey: item,
              itemTitle: childElement ? childElement.title : item,
            };
          }),
        });
      }
    });

    filter.setCriteriaFilter(filterCriteria);
  };

  const normalizeSearch = (): URLSearchParams => {
    const queryUrl = new URLSearchParams(search);

    if (queryUrl.get('page')) {
      queryUrl.delete('page');
    }

    return queryUrl;
  };

  useEffect(() => {
    const searchStr = normalizeSearch();
    let searchArr = [];

    searchStr.forEach((value, queryKey) => {
      const [key, type] = queryKey.split(':');
      searchArr.push({
        key: parseInt(key, 10),
        type: Number(type) || 1,
        items: value.split('#,#').map(itemKey => ({ key: itemKey })),
      });
    });

    if (filter.currentCategory) {
      setIsLoading(true);
      api
        .parts
        .getFilter(
          filter.currentCategory.tecdoc,
          storeCurrentVehicle.current?.id,
          vType,
          searchArr,
        )
        .then(res => res.json())
        .then(res => {
          // if (res.data?.length) {
          filter.setCriteria(res.data);
          rebuildSearchFilter();
          // }
        })
        .catch(error => console.error(error))
        .then(() => setIsLoading(false));
    }
  }, [filter.currentCategory, search]);

  if (isLoading) {
    return <div>
      {
        [...Array(3)].map((item, index) => (
          <div
            key={`filter-skeleton-item-${index}`}
            className="page-fmp__filter-skeleton"
          >
            <div className="page-fmp__filter-skeleton-title" />
            <div>
              {
                [...Array(3 + index)].map((innerItem, innerIndex) => (
                  <div
                    key={`filter-skeleton-item-${index}-${innerIndex}`}
                    className="page-fmp__filter-skeleton-val"
                  />
                ))
              }
            </div>
          </div>
        ))
      }
    </div>;
  }

  return (
    <div className="page-fmp__filter-deep">
      {filter.criteria?.length
        ? filter.criteria.map(block => (
            <DeepFilterBlock key={block.key} block={block} />
        ))
        : null}
    </div>
  );
};

export default observer(DeepFilter);
