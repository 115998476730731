import React, { FC } from 'react';
import { Route, useLocation } from 'react-router-dom';

import UserForm from '../user-form';
import UserVehicles from '../user-vehicles';
import UserLinks from '../user-links';

const userComponents = {
  '/': <UserLinks/>,
  vehicles: <UserVehicles/>,
  account: <UserForm />,
};

const UserContent: FC = () => {
  const { pathname } = useLocation();

  return (
    <div className={`grid__col grid__col_12 grid__col_lg_${pathname === '/' ? '9' : '6'}`}>
      {
        Object
          .keys(userComponents)
          .map(routeHandle => (
            <Route
              key={`user-route-${routeHandle}`}
              path={`/${routeHandle === '/' ? '' : routeHandle}`}
              children={userComponents[routeHandle]}
              exact
            />
          ))
      }
    </div>
  );
};

export default UserContent;
