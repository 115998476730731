import React, { FC } from 'react';

const Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10">
    <g fill="#E31837" transform="translate(-421 -317)" fillRule="evenodd">
      <path d="M421.92 316.9l5.1 5.1-5.1 5.1-.83-.84 4.26-4.26-4.26-4.26z" />
    </g>
  </svg>
);

const SmallIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="8">
    <path
      d="M4.56.18L.74 4l3.82 3.82.62-.63L1.98 4 5.19.8z"
      fill="#DC0032"
      fillRule="evenodd"
    />
  </svg>
);

interface CtaProps {
  isSmall?: boolean;
}

const MainFilterCta: FC<CtaProps> = ({ isSmall = false }) => {
  return (
    <div
      className={`where-to-buy__filter-cta${
        isSmall ? ' where-to-buy__filter-cta_small' : ''
      }`}
    >
      {isSmall ? SmallIcon : Icon}
    </div>
  );
};

export default MainFilterCta;
