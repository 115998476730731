import { makeAutoObservable, runInAction } from 'mobx';

import VehicleBrands from './model';

const storeVehicleBrands = makeAutoObservable<VehicleBrands>({
  list: [],
  popularList: [],
  setList: (list = [], popular = []) => {
    runInAction(() => {
      storeVehicleBrands.list = list;
      storeVehicleBrands.popularList = popular;
    });
  },
  current: null,
  setCurrent: newVehicleBrand => {
    runInAction(() => {
      storeVehicleBrands.current = newVehicleBrand;
    });
  },
  get currentId() {
    if (storeVehicleBrands.current === null) {
      return null;
    }

    return storeVehicleBrands.current.key;
  },
  initialMake: null,
  setInitialMake: makeSlug => {
    runInAction(() => {
      storeVehicleBrands.initialMake = makeSlug;
    });
  },
});

export default storeVehicleBrands;
