const icons = {
  plus: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
          <g fill-rule="nonzero" fill="none">
            <g fill="#000">
                <path d="M11.3 3.56h1.4c.13 0 .2.07.2.19v16.5c0 .13-.07.19-.2.19h-1.4c-.13 0-.2-.07-.2-.19V3.75c0-.13.07-.19.2-.19z"/>
                <path d="M4.13 11.11h15.75c.12 0 .18.06.18.19v1.4c0 .13-.06.2-.18.2H4.13c-.13 0-.2-.07-.2-.2v-1.4c0-.13.07-.2.2-.2z"/>
            </g>
            <path d="M0 0h24v24H0z"/>
          </g>
        </svg>
  `,
  minus: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <g fill-rule="nonzero" fill="none">
              <path d="M20.44 11.11H3.56c-.1 0-.19.08-.19.19v1.4c0 .1.09.2.2.2h16.87c.1 0 .18-.1.18-.2v-1.4c0-.1-.08-.2-.18-.2z" fill="#000"/>
              <path d="M0 0h24v24H0z"/>
            </g>
          </svg>
  `,
};

/**
 * Utility function for change map zoom
 * @param map - map instance
 * @param value - value to add
 */
const zoom: (map: google.maps.Map, value: number) => void = (map, value) => {
  map.setZoom(map.getZoom() + value);
};

/**
 * Create div with icon
 * @param icon - icon svg path
 */
const createElement: (icon: string) => HTMLDivElement = icon => {
  const el = document.createElement('div');
  const styles = {
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  };
  Object.assign(el.style, styles);

  el.innerHTML = icon;

  return el;
};

/**
 * Create controls
 * @param map - map instance
 */
function createZoomControl(map: google.maps.Map): HTMLDivElement {
  const zoomOuterStyles = {
    padding: '4px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    margin: '0 16px 16px 0',
  };
  const zoomUi = document.createElement('div');
  zoomUi.className = 'custom-zoom-control';
  Object.assign(zoomUi.style, zoomOuterStyles);

  const zoomInControl = createElement(icons.plus);
  zoomInControl.style.marginBottom = '8px';
  zoomInControl.addEventListener('click', () => zoom(map, +1));

  const zoomOutControls = createElement(icons.minus);
  zoomOutControls.addEventListener('click', () => zoom(map, -1));

  zoomUi.appendChild(zoomInControl);
  zoomUi.appendChild(zoomOutControls);

  return zoomUi;
}

/**
 * Adds custom controls for control zoom
 * @param map - map instance
 */
export function setCustomControls(map: google.maps.Map): void {
  const zoomControl = createZoomControl(map);
  map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControl);
}
