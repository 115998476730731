import React, { FC, useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Wrapper from './components/wrapper';

import overall from './store/overall';

const App: FC = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <BrowserRouter basename={`/${overall.baseUrl}`}>
      <Switch>
        <Route
          path={['/:vType(mc|pv|cv)', '/part/:partName']}
          component={Wrapper}
        />
        <Route path="/" exact>
          <Redirect to={{ pathname: '/pv' }} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
