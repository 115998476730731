import React, { FC, useLayoutEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom';

import VehicleSearch from '../vehicle-search';
import Breadcrumbs from '../breadcrumbs';
import Detail from '../detail';
import List from '../list';
import overall from '../../store/overall';
import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';

const Wrapper: FC = () => {
  const { vType } = useParams<{ vType: string }>();
  const match = useRouteMatch();

  const setLinkToFMP = (): any => {
    const link : HTMLLinkElement = document.querySelector("[data-attr='fmp-link']");
    if (storeCurrentVehicle.current) {
      link.href = `/catalog/${storeCurrentVehicle.current.vehicleType}/${storeCurrentVehicle.current.id}`;
    } else {
      link.href = '/catalog';
    }
  };

  useLayoutEffect(() => {
    if (!overall.prefix) {
      overall.setPrefix(vType);
    }
  }, []);

  useCallback(setLinkToFMP(), [storeCurrentVehicle.current]);

  return (
    <div className="page-fmp__app">
      <VehicleSearch />
      <Breadcrumbs />

      <Switch>
        <Route path={`${match.path}/:carId(\\d+)/part/:partName`} component={Detail} exact />
        <Route path={'/part/:partName'} component={Detail} exact />
        <Route
          path={`${match.path}/:carId(\\d+)/:catLevel1?/:catLevel2?/:catLevel3?`}
          component={List}
          exact
        />
        <Route
          path={`${match.path}/:catLevel1?/:catLevel2?/:catLevel3?`}
          component={List}
          exact
        />
      </Switch>
    </div>
  );
};

export default observer(Wrapper);
