import React, { FC, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import __ from '../../../../utils/translation';

import { EXCLUDED_PARAMS } from '../../constants';
import filter from '../../store/filter';

import Checkbox from '../checkbox';
import Icon from '../icon';

const DeepFilterBlock: FC<{ block: any }> = ({ block }) => {
  const { key, title, items, type } = block;
  const queryKey = `${key}:${type}`;

  const [isExpand, setIsExpand] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const searchCriteria = useMemo(() =>
    filter.criteriaFilter?.find(item => parseInt(item.key, 10) === key),
    [filter.criteriaFilter]
  );
  const resultList = useMemo(() => {
    const resultItems = isExpand
      ? items.filter(item => item.title.toLowerCase().startsWith(searchStr.toLowerCase()))
      : items.slice(0, 5);

    // Checked elements in filter
    if (searchCriteria?.items.length > 0 && !isExpand && items.length > 10) {
      const criteriaValues = searchCriteria.items.map(({ itemKey }) => itemKey);
      const checkedItemsToTop = items.filter(({ key }) =>
        criteriaValues.includes(key.toString())
      );
      const filteredTopItems =
        resultItems
          .filter(({ key }) => !criteriaValues.includes(key.toString()))
          .slice(0, checkedItemsToTop.length >= 5 ? 0 : 5 - checkedItemsToTop.length);

      return [...checkedItemsToTop, ...filteredTopItems];
    }

    return resultItems;
  }, [isExpand, searchStr]);
  const history = useHistory();
  const { search } = useLocation();

  const toggleExpand = () => setIsExpand(!isExpand);

  const handleChange = e => {
    const queryUrl = new URLSearchParams(search);
    const blockParam = queryUrl.get(queryKey);
    const { checked, value } = e.target;
    let values = [value];

    EXCLUDED_PARAMS.forEach((excludedParam) => queryUrl.has(excludedParam) && queryUrl.delete(excludedParam));

    if (blockParam) {
      values = blockParam.split('#,#');

      values = checked
        ? [...values, value]
        : values.filter(item => item !== value);

      // Remove Duplicates
      values = values.filter((item, pos, self) => self.indexOf(item) === pos);
    }

    if (values.length) {
      queryUrl.set(queryKey, values.join('#,#'));
    } else {
      queryUrl.delete(queryKey);
    }

    history.push({
      search: queryUrl.toString(),
    });
  };

  const handleSearchKeydown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.code === 'Escape') {
      setSearchStr('');
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchStr(e.target.value);
  };

  return (
    <div className="page-fmp__filter-block">
      <div className="page-fmp__filter-block-title">{title}</div>
      {
        // Search field if expanded
        isExpand && items.length > 10
          ?
          <div className="page-fmp__filter-search">
            <Icon
              name="search"
              className="page-fmp__filter-search-icon"
            />
            <input
              className="page-fmp__filter-search-input"
              type="text"
              placeholder="Search"
              value={searchStr}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeydown}
              autoFocus
            />
          </div>
          : null
      }
      <div className={cn(
        'page-fmp__filter-block-content',
        {'page-fmp__filter-block-content_flat': items.length <= 5},
      )}>
        <div className="page-fmp__filter-block-scroller">
          {
            resultList.length
              ? resultList.map(item => {
                const rowKey = item.key.toString();
                const checked =
                    searchCriteria &&
                    searchCriteria.items.map(item => item.itemKey).includes(rowKey);

                return (
                    <div
                      key={`${key}-${rowKey}`}
                      className="page-fmp__filter-block-row"
                    >
                      <Checkbox
                        name={key}
                        label={item.title}
                        value={rowKey}
                        checked={checked}
                        onChange={handleChange}
                        docCount={item.doc_count}
                      />
                    </div>
                );
              })
              : isExpand
                ? <div className="page-fmp__filter-block-empty">{ __('No matches found') }</div>
                : null

          }
        </div>
      </div>

      {
        items.length > 5
          ? (
            <div className="page-fmp__filter-block-foot">
              <button
                className="page-fmp__filter-block-expand"
                type="button"
                onClick={toggleExpand}
              >{isExpand ? __('Show less') : __('Show more') }</button>
            </div>
        )
        : null
      }
    </div>
  );
};

export default observer(DeepFilterBlock);
