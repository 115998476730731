import { useState, useEffect, useCallback } from 'react';

const resize = (): boolean => {
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  let waiter;

  const handleResize = useCallback(() => {
    clearTimeout(waiter);

    waiter = setTimeout(() => {
      setCurrentWidth(window.innerWidth);
    }, 300);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return currentWidth >= 1024;
};

export default resize;
