import { debounce } from 'lodash-es';

const [partSidebar, header, sectionCatcher] : HTMLDivElement[] = [
  document.querySelector('.part-sidebar'),
  document.querySelector('.header'),
  document.querySelector('.section-catcher'),
];

const toggleStickyClass = () => {
  const isSmallScreen = window.innerHeight
      < partSidebar.clientHeight
      + header.clientHeight
      + sectionCatcher.clientHeight;

  partSidebar.classList.toggle('part-sidebar_no-sticky', isSmallScreen);
};

const debounceToggle = debounce(toggleStickyClass, 300);

const init = (): void => {
  if (partSidebar) {
    debounceToggle();
    window.addEventListener('resize', debounceToggle, false);
  }
};

export default {
  init,
};
