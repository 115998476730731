import storeLocationFilters from '../store/filters/location-filters';
import storeProductGroupFilters from '../store/filters/product-group-filters';
import storeSalesFilters from '../store/filters/sales-filters';
import storeStoresFilters from '../store/filters/store-filters';
import storeCurrentFilters from '../store/filters';
import { FiltersResponse } from '../api';
import storeTooltips from '../store/filters/tooltips';
import storeServiceTypes from '../store/filters/service-types';
import storeProductTypes from '../store/filters/product-type';

export function setStoreFilters(filters: FiltersResponse) {
  storeLocationFilters.setList(filters.type_of_locations);
  storeProductGroupFilters.setList(filters.product_group);
  storeSalesFilters.setList(filters.type_of_sales);
  storeStoresFilters.setList(filters.type_of_store);
  storeTooltips.setTooltips(filters.tooltips);
  storeServiceTypes.setList(filters.service_types);
  storeProductTypes.setList(filters.product_types);
}

export function setDefaultFilter() {
  const urlParams = new URLSearchParams(window.location.search);
  const defaultFilterParam = urlParams.get('filter');
  const availableFilters = ['location', 'services'];
  const currentFilter = availableFilters.indexOf(defaultFilterParam);

  if (currentFilter !== -1) {
    const locationToBuyFilter = storeStoresFilters.list[currentFilter];

    storeCurrentFilters.setCurrentFilters(
      [locationToBuyFilter],
      storeStoresFilters.alias,
    );
  }
}
