import { makeAutoObservable, runInAction } from 'mobx';
import { ServiceTypes } from '../model';

const storeProductTypes = makeAutoObservable<ServiceTypes>({
  list: {},
  label: 'Products',
  alias: 'products',
  setList(serviceType) {
    runInAction(() => {
      storeProductTypes.list = serviceType;
    });
  },
});

export default storeProductTypes;