import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useResize } from '../../hooks';

import __ from '../../../../utils/translation';

import parts from '../../store/parts';

const DetailNavigation: FC = () => {
  const isDesktop = useResize();

  const links = [
    {
      title: __('Application specifications'),
      anchor: '#app-spec',
      part: parts.currentPart.notes,
    },
    {
      title: __('Part specifications'),
      anchor: '#part-spec',
      part: parts.specifications,
    },
    {
      title: __('Documentation'),
      anchor: '#doc',
      part: parts.currentPart.downloads,
    },
    {
      title: __('OE numbers'),
      anchor: '#oe',
      part: parts.currentPart.ref_numbers,
    },
    {
      title: __('General product information'),
      anchor: '#general',
      part: parts.currentPart.product_information,
    },
  ].filter(link => link.part.length);

  const scrollToTitle = (title: string) : void => {
    const titles: HTMLHeadingElement[] = [...document.querySelectorAll('h3.heading_h3') as NodeListOf<HTMLHeadingElement>];
    const header = document.querySelector('.header__main') as HTMLDivElement;
    const currentLink: HTMLHeadingElement = titles.find(el => el.innerHTML === title);

    if (currentLink) {
      let offset =
          currentLink.getBoundingClientRect().top +
          window.scrollY -
          header.offsetHeight;

      if (offset) {
        window.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleLinkClick = (item) => () => {
    scrollToTitle(item.title);
  };

  if (!isDesktop) {
    return null;
  }

  return (
        <div className='detail-navigation'>
              <div className="wrapper">
                <div className="detail-navigation__container">
                  {
                    links.map((item, idx) => {
                      return (
                          <a className="detail-navigation__item"
                             href={item.anchor}
                             key={`nav-${idx}`}
                             onClick={ handleLinkClick(item) }
                          >
                            {item.title}
                          </a>
                      );
                    })
                  }
                </div>
              </div>
        </div>
  );
};

export default observer(DetailNavigation);
