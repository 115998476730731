import React, { FC } from 'react';

import { useResize } from '../../hooks';

import __ from '../../../../utils/translation';

import Icon from '../icon';

const AlertBox: FC = () => {
  const isDesktop = useResize();

  const handleClick = (e) => {
    const firstElement = document.querySelector('.d-search-bar__dd-current') as HTMLElement;

    e.preventDefault();

    if (!isDesktop) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      return;
    }

    if (firstElement) {
      firstElement.click();
    }
  };

  return (
        <div className="page-fmp__content-block">
            <div className="alert-box alert-box_green">
                <div className="alert-box__content">
                    <div className="alert-box__content-title">
                        <div className="alert-box__content-title-icon">
                            <Icon name="car" />
                        </div>
                        <div className="alert-box__content-title-icon">
                            <Icon name="check" />
                        </div>
                        <div className="alert-box__content-title-text">{ __('Show parts for your vehicle only') }</div>
                    </div>
                    <div className="alert-box__content-descr">{ __('Enter your make and model to show parts that are only applicable to your vehicle') }</div>
                </div>
                <div className="alert-box__btn">
                    <a
                        href="#"
                        className="alert-box__btn-link"
                        onClick={handleClick}
                    >{ __('Filter') }</a>
                </div>
            </div>
        </div>
  );
};

export default AlertBox;
