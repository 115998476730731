import React, { FC } from 'react';

import __ from '../../../../utils/translation';

import parts from '../../store/parts';

import { useResize } from '../../hooks';

import DetailHider from './hider';
import Specs from '../specs';
import DetailCarApplicability from './car-applicability';

const DetailOE: FC = () => {
  const isDesktop = useResize();
  const title = __('OE numbers');
  const OENumbers =
    parts.currentPart?.ref_numbers?.length > 0
      ? parts.currentPart.ref_numbers
      .map(item => ({
        id: item.maker_tid,
        label: item.maker,
        value: item.numbers,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
      : null;

  if (!OENumbers) {
    return null;
  }

  if (!isDesktop) {
    return (
      <DetailHider title={title}>
        <Specs list={OENumbers} isBorder isCut />
      </DetailHider>
    );
  }

  return  (
    <div className="catalog-detail__block">
      <div className="catalog-detail__block-title catalog-detail__block-title_oe">
        <h2 className="heading heading_h3">{ title }</h2>

        {
          parts.currentPart.cars?.length > 0
            ? (
              <DetailCarApplicability
                partName={`${ __('DENSO part number') }: ${ parts.currentPart.name }`}
                list={ parts.currentPart.cars }
                isApplicable={ parts.currentPart.applicable }
              />
            )
            : null
        }
      </div>

      <div className="catalog-detail__block-content">
        <Specs list={OENumbers} isBorder isCut />
      </div>
    </div>
  );
};

export default DetailOE;
