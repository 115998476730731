import React, { FC, useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import api from '../../../../api';

import storeVehicleBrands from '../../store/vehicle-brands';
import storeVehicleModels from '../../store/vehicle-model';
import storeCarTypes from '../../store/car-types';
import { VehicleModel } from '../../store/vehicle-model/model';

import refreshVehicleStore from '../../utils/refresh-store';

import DropDown from '../dropdown';
import Column from '../column';

const VehicleModelField: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const selected = storeVehicleModels.current
    ? {
      val: storeVehicleModels.current.val,
      key: storeVehicleModels.current.key,
    }
    : null;

  const handleChange = (selectedItem: VehicleModel) => {
    refreshVehicleStore('model', selectedItem);
  };

  useEffect(() => {
    if (storeVehicleBrands.current && !storeVehicleModels.current) {
      api
        .car
        .getModels(storeVehicleBrands.currentId, storeCarTypes.currentId)
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            storeVehicleModels.setList(res.data.sort((a, b) => a.slug.localeCompare(b.slug)));
          }

          setIsLoading(false);
        })
        .catch(error => console.error(error));
    }

  }, [storeVehicleBrands.current]);

  const fieldTitle = useMemo(() => __('Select model'), [storeCarTypes.current]);

  return (
        <Column label={ __('Model') }>
            <DropDown
                placeholder={fieldTitle}
                selected={selected}
                list={storeVehicleModels.list}
                onChange={handleChange}
                withSearch
                disabled={!storeVehicleBrands.current}
                isOpen={storeVehicleBrands.current && !isLoading}
            />
        </Column>
  );
};


export default observer(VehicleModelField);
