import React from 'react';
import ReactDOM from 'react-dom';

import api from '../../api';

import storeUserAccount from './store/user-account';

import visitRole from '../visit-role';
import App from './app';

let entryPoint: HTMLElement;

const checkActualInformation = (): void => {
  api
    .auth
    .userInfo()
    .then(res => res.json())
    .then(({ data }) => {
      if (data?.length === 0) {
        localStorage.removeItem('denso-current-user');
      } else {
        localStorage.setItem('denso-current-user', JSON.stringify(data));

        storeUserAccount.setUserId(parseInt(data.userId));
        storeUserAccount.setIsCanAddVehicles(data.role === 'drivers');
        storeUserAccount.setUserVehicles(data.vehicles);
        storeUserAccount.setUserLinks(data.links);
      }

      visitRole.redrawData();
      ReactDOM.unmountComponentAtNode(entryPoint);
      ReactDOM.render(
        React.createElement(App, {
          isAuth: data?.length !== 0,
          isAccountPage: false,
        }),
        entryPoint,
      );
    })
    .catch(error => console.error(error));
};

const initApp = (): void => {
  // User Account ID
  if (entryPoint.dataset.isAuth) {
    storeUserAccount.setUserId(parseInt(entryPoint.dataset.isAuth));
  }

  // User Can Add Vehicle Flag
  if (entryPoint.dataset.isCanAddVehicles) {
    storeUserAccount.setIsCanAddVehicles(true);
  }

  // User Account Vehicles
  if (entryPoint.dataset.vehicles) {
    const vehiclesArray = JSON.parse(entryPoint.dataset.vehicles);

    storeUserAccount.setUserVehicles(vehiclesArray);

    delete entryPoint.dataset.vehicles;
  }

  // User Account Links
  if (entryPoint.dataset.links) {
    const linksArray = JSON.parse(entryPoint.dataset.links);

    storeUserAccount.setUserLinks(linksArray);

    delete entryPoint.dataset.links;
  }

  ReactDOM.render(
    React.createElement(App, {
      isAuth: typeof entryPoint.dataset.isAuth !== 'undefined',
      isAccountPage: typeof entryPoint.dataset.isAccount !== 'undefined',
    }),
    entryPoint,
  );
};

const refreshFreeFormsToken = (token: string): void => {
  const forms = document.querySelectorAll('form[data-freeform]');

  if (forms.length > 0) {
    forms.forEach((form) => {
      const csrfTokenInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]') as HTMLInputElement;

      if (csrfTokenInput) {
        csrfTokenInput.value = token;
      }
    });
  }
};

const init = (): void => {
  entryPoint = document.querySelector('#denso-auth');

  if (entryPoint) {
    initApp();

    if (entryPoint.dataset.dynamic) {
      fetch('/actions/blitz/csrf/json')
        .then(res => res.json())
        .then(({ param, token }) => {
          (window as any).DENSO = (window as any).DENSO || {};
          (window as any).DENSO.csrfTokenName = param;
          (window as any).DENSO.csrfTokenValue = token;

          // Refresh all free-forms token
          refreshFreeFormsToken(token);
        })
        .catch(err => console.error(err))
        .then(() => {
          checkActualInformation();
        });
    }
  }
};

export default {
  init,
};
