import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import storeUserLocation from '../../store/user-location';
import storeMap from '../../store/map';
import { DEFAULT_ZOOM } from '../../constants';
import { setCustomControls, setMapEvents } from '../../helpers/map';
import { findShops } from '../../api';
import storeStores from '../../store/stores';
import { setFiltersListeners } from '../../helpers/filtersListeners';
import { setDefaultFilter } from '../../helpers/store';
import { DEFAULT_LOCATION } from '../../helpers/location';
import { setStoreListener } from '../../helpers/storeListener';

const MapComponent: FC = () => {
  if (!('google' in window)) return null;

  const { position } = storeUserLocation;
  const mapOptions = {
    center: {
      lat: position?.lat() ?? DEFAULT_LOCATION.lat,
      lng: position?.lng() ?? DEFAULT_LOCATION.lng,
    },
    clickableIcons: false,
    zoom: position ? DEFAULT_ZOOM : 4.5,
    disableDefaultUI: true,
  };

  useEffect(() => {
    try {
      const map = new google.maps.Map(
        document.querySelector('.where-to-buy__map') as HTMLElement,
        mapOptions,
      );
      setMapEvents(map);
      setCustomControls(map);
      setStoreListener(map);
      storeMap.setInstance(map);
      findShops().then((list) => {
        storeStores.setGlobalStoresList(list);
        setFiltersListeners();
        setDefaultFilter();
        storeMap.setLoading(false);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  return <div className="where-to-buy__map" />;
};

export default observer(MapComponent);
