import { apiRequest } from '../';

import { PER_PAGE } from '../../constants';
import { getVTypeId, VehicleType } from '../../utils/vehicleType';

const endpointPrefix = '/parts';

const getList = (
  generalArticles: number[],
  offset?: number,
  limit?: number,
  filters?: object[],
  carId?: number,
  vType?: VehicleType,
) => {
  const search = new URLSearchParams();

  search.append('offset', (offset || 0).toString());
  search.append('vt', getVTypeId(vType).toString());
  search.append('limit', (limit || PER_PAGE).toString());

  return apiRequest(`${endpointPrefix}?${search.toString()}`, 'POST', {
    generic_articles: generalArticles,
    filters: filters || [],
    car: carId || '',
  });
};

const getFilter = (generalArticles: number[], carId?: number, vType?: VehicleType, filters?: object[]) => {
  const search = new URLSearchParams();
  search.append('vt', getVTypeId(vType).toString());

  return apiRequest(`/criteria?${search.toString()}`, 'POST', {
    generic_articles: generalArticles,
    filters: filters || [],
    car: carId || '',
  });
};

const getDetail = (partName: string, carId?: number, vType?: VehicleType, levelsArray?: string[]) => {
  const search = new URLSearchParams();

  search.append('vt', getVTypeId(vType).toString());

  if (carId) {
    search.append('car', carId.toString());
  }

  if (levelsArray?.length > 0) {
    search.append('levels', levelsArray.join(','));
  }

  return apiRequest(
    `${endpointPrefix}/${partName}?${search.toString()}`,
    'GET',
  );
};

const getApplicableList = (partName: string, maker: number, model: number) => {
  return apiRequest(
    `${endpointPrefix}/${partName}/applicable?maker=${maker}&model=${model}`,
    'GET',
  );
};

const parts = {
  getList,
  getFilter,
  getDetail,
  getApplicableList,
};

export default parts;
