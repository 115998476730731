import { Calculator } from '@googlemaps/markerclustererplus/dist/markerclusterer';
import { ClusterColors } from '.';
import { CustomMarker } from './customMarker';



function getClusterIndex(types: Set<number>): number {
  if (types.size > 2) {
    return ClusterColors.rgb;
  }

  switch (true) {
    case types.has(0) && types.has(1):
      return ClusterColors.rb;
    case types.has(0) && types.has(2):
      return ClusterColors.gb;
    case types.has(1) && types.has(2):
      return ClusterColors.rg;
    case types.has(0):
      return ClusterColors.b;
    case types.has(1):
      return ClusterColors.r;
    case types.has(2):
      return ClusterColors.g;
  }
}

export const clusterCalculator: Calculator = (markers: CustomMarker[]) => {
  const count = markers.length;
  const types = new Set(markers.map(({ type }) => type));

  return {
    index: getClusterIndex(types) + 1,
    title: '',
    text: count.toString(),
  };
};
