const specificationsListByLang = (item) => {
  if (item?.criteria?.length === 0) {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return item.criteria.map(({ tid, label, label_tid, val }) => {
    const langUnit = null; //unit && unit.length > 0 ? unit.find(item => item.lang_tid === langId) : null;
    const value = `${val} ${langUnit ? ` ${langUnit.val}` : ''}`;

    return {
      id: tid,
      label,
      labelTid: label_tid,
      value,
    };
  });
};

export default specificationsListByLang;
