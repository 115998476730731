import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';

let entryPoint: HTMLButtonElement;

const initApp = (): void => {
  const mobileMenu = entryPoint.dataset.mmenu
    ? JSON.parse(entryPoint.dataset.mmenu)
    : null;

  ReactDOM.render(
    React.createElement(App, {
      mobileMenu,
    }),
    entryPoint,
  );
};

const init = (): void => {
  entryPoint = document.querySelector('#denso-mobile-menu');

  if (entryPoint) {
    initApp();
  }
};

export default {
  init,
};
