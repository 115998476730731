import React, { FC } from 'react';

import { GlobalSearchItem } from '../../model';

const SearchResultParts: FC<{
  resultList?: GlobalSearchItem[];
  keyName?: string,
}> = ({
  resultList = [],
  keyName = 'parts',
}) => {
  if (resultList?.length === 0) {
    return null;
  }

  return <>
    {
      resultList.map(({
        key,
        val,
        description,
        image,
        url,
      }) => (
        <li
          key={`denso-global-${keyName}-${key}`}
          className="global-search__modal-list-item"
        >
          <div className="global-search__part">
            <div className="global-search__part-media">
              {
                image
                  ? <img
                    src={image}
                    alt={description}
                    className="global-search__part-img"
                  />
                  : null
              }
            </div>
            <div className="global-search__part-content">
              <a
                href={url}
                className="global-search__part-link"
                tabIndex={-1}
              >{val}</a>

              {
                description
                  ? <div className="global-search__part-number">{description}</div>
                  : null
              }
            </div>
          </div>
        </li>
      ))
    }
  </>;
};

export default SearchResultParts;
