import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import storeStoresFilters from '../../../../store/filters/store-filters';
import storeSalesFilters from '../../../../store/filters/sales-filters';
import storeLocationFilters from '../../../../store/filters/location-filters';
import AdditionalFilterGroup from './components/additional-filter-group';
import storeCurrentFilters from '../../../../store/filters';

const AdditionalFilters: FC = () => {
  if (!storeCurrentFilters.currentFilters[storeStoresFilters.alias].length)
    return null;

  const MAIN_TO_ADDITIONAL_FILTERS_MAP = {
    1: [storeLocationFilters, storeSalesFilters],
    2: [storeSalesFilters],
    3: [],
  };

  const filterGroups = MAIN_TO_ADDITIONAL_FILTERS_MAP[
    storeCurrentFilters.currentFilters[storeStoresFilters.alias][0].id
  ].map(store => {
    if (store.alias === 'type_of_sales') return null;

    return (
      <AdditionalFilterGroup
        key={store.label}
        label={store.label}
        filters={store.list}
        filterAlias={store.alias}
      />
    );
  });

  return (
    <div className="where-to-buy__sidebar-additional-filters">
      {filterGroups}
    </div>
  );
};

export default observer(AdditionalFilters);
