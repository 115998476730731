import React, { FC } from 'react';
import { Filter } from '../../../../../../store/filters/model';
import { observer } from 'mobx-react-lite';
import Checkbox from '../../../checkbox';
import storeCurrentFilters from '../../../../../../store/filters';
import Tooltip from '../../../../../../../tooltip';
import storeTooltips from '../../../../../../store/filters/tooltips';

interface AdditionalFilterGroupProps {
  label: string;
  filters: Filter[];
  filterAlias: string;
}

const AdditionalFilterGroup: FC<AdditionalFilterGroupProps> = ({ filterAlias, filters, label }) => {
  const toggleFilter = (filter: Filter) => {
    storeCurrentFilters.setCurrentFilters(filter, filterAlias, true);
  };

  if (storeCurrentFilters?.currentFilters?.type_of_store[0]?.id === 2) {
    return null;
  }

  const checkboxes = filters.map(filter => {
    return (
       <li key={`li-${filter.id} ${label}`}>
        <Checkbox
            key={`checkbox-${filter.id} ${label}`}
            name={label}
            onClick={toggleFilter}
            value={filter}
            id={`${filter.id}${label}`}
            label={filter.label}
        />
      </li>

    );
  });

  return (
    <div className="additional-filter-group">
      <div className="where-to-buy__sidebar-filter-label">
          {label}
          {filterAlias === 'type_of_location' && storeTooltips.tooltips?.typeOfLocation
            ? <Tooltip className="where-to-buy__sidebar-tooltip-hint">
                  <div className="where-to-buy__sidebar-tooltip-info" dangerouslySetInnerHTML={
                      { __html: storeTooltips.tooltips?.typeOfLocation as string }
                  } />
              </Tooltip>
            : null
          }
      </div>
      <ul className="additional-filter-group__list">
        {checkboxes}
      </ul>
    </div>
  );
};

export default observer(AdditionalFilterGroup);
