import storeCarTypes from '../store/car-types';
import storeVehicleBrands from '../store/vehicle-brands';
import storeVehicleModels from '../store/vehicle-model';
import storeVehicleYears from '../store/vehicle-year';
import storeVehicleBodies from '../store/vehicle-body';
import storeVehicleVolumes from '../store/vehicle-volume';
import storeVehicleTypes from '../store/vehicle-type';
import storeAlternativeSearch from '../store/alternative';

const refreshVehicleStore = (
  fieldName: 'brand' | 'model' | 'year' | 'body' | 'volume' | 'type',
  fieldValue,
): void => {
  switch (fieldName) {
    case 'brand':
      storeVehicleBrands.setCurrent(fieldValue);
      storeVehicleModels.setCurrent(null);
      storeVehicleYears.setCurrent(null);
      storeVehicleBodies.setCurrent(null);
      storeVehicleVolumes.setCurrent(null);
      storeVehicleTypes.setCurrent(null);
      storeAlternativeSearch.setCurrent('');
      break;

    case 'model':
      storeVehicleModels.setCurrent(fieldValue);
      storeVehicleYears.setCurrent(
        storeCarTypes.currentId === 3 ? { key: 0, val: 0 } : null,
      );
      storeVehicleBodies.setCurrent(
        storeCarTypes.currentId === 3 ? { key: 0, val: 0 } : null,
      );
      storeVehicleVolumes.setCurrent(
        storeCarTypes.currentId === 3 ? { key: 0, val: 0 } : null,
      );
      storeVehicleTypes.setCurrent(null);
      break;

    case 'year':
      storeVehicleYears.setCurrent(fieldValue);
      storeVehicleBodies.setCurrent(
        storeCarTypes.currentId === 1 ? null : { key: 0, val: 0 },
      );
      storeVehicleVolumes.setCurrent(
        storeCarTypes.currentId === 1 ? null : { key: 0, val: 0 },
      );
      storeVehicleTypes.setCurrent(null);
      break;

    case 'body':
      storeVehicleBodies.setCurrent(fieldValue);
      storeVehicleVolumes.setCurrent(null);
      storeVehicleTypes.setCurrent(null);
      break;

    case 'volume':
      storeVehicleVolumes.setCurrent(fieldValue);
      storeVehicleTypes.setCurrent(null);
      break;

    case 'type':
      storeVehicleTypes.setCurrent(fieldValue);
      break;
  }
};

export default refreshVehicleStore;

export const pageSearchString = (): string => {
  const searchFields = {
    carType: storeCarTypes.currentId,
    brand: storeVehicleBrands.currentId,
    model: storeVehicleModels.currentId,
    year: storeVehicleYears.currentId,
    body: storeVehicleBodies.currentId,
  };
  const searchString = Object.keys(searchFields).reduce((acc, value) => {
    const returnValue = searchFields[value]
      ? `${value}=${searchFields[value]}`
      : '';
    const separator = acc.length > 0 ? '&' : '';

    return searchFields[value] ? `${acc}${separator}${returnValue}` : acc;
  }, '');

  return `?${searchString}`;
};
