import React, { FC } from 'react';

import AccountDD from './components/account-dd';
import AuthModals from './components/modals';

const App: FC<{
  isAuth: boolean,
  isAccountPage: boolean,
}> = ({
  isAuth = false,
  isAccountPage = false,
}) => {
  return (
        <div className="auth">
            {
                isAuth
                  ? <AccountDD isAccountPage={isAccountPage} />
                  : <AuthModals />
            }
        </div>
  );
};

export default App;
