import { makeAutoObservable, runInAction } from 'mobx';
import { ServiceTypes } from '../model';

const storeServiceTypes = makeAutoObservable<ServiceTypes>({
  list: {},
  label: 'Services',
  alias: 'services',
  setList(serviceType) {
    runInAction(() => {
      storeServiceTypes.list = serviceType;
    });
  },
});

export default storeServiceTypes;