import React, { FC, useState } from 'react';
import cn from 'classnames';

import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';

import Modal from '../modal';
import Icon from '../icon';
import DetailCarApplicabilityTable from './car-applicability-table';
import ArrowLink from '../arrow-link';

const DetailCarApplicability: FC<{
  partName: string,
  list: any[],
  isApplicable?: boolean,
}> = ({
  partName,
  list,
  isApplicable,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);
  const subtitle = `${partName} fit on the cars listed below`;

  const handleClick = () => {
    setIsShow(true);
  };

  const handleClose = () => {
    setIsShow(false);
  };

  const toggleFullList = (e) => {
    e.preventDefault();

    setIsShowAll(!isShowAll);
  };

  const applicableString = (): string => {
    if (typeof isApplicable !== 'undefined') {
      const currentBrand = storeCurrentVehicle?.current?.brand;

      return isApplicable
        ? `Applicable to ${currentBrand}`
        : 'Not applicable to the selected vehicle';
    }

    return 'Also fits these makes and models';
  };

  return (
        <>
            <button
                type="button"
                className={cn(
                  'catalog-detail__overall-check',
                  { [`catalog-detail__overall-check_${ isApplicable ? 'check' : 'not' }`]: typeof isApplicable !== 'undefined' },
                )}
                onClick={handleClick}
            >
                <Icon
                    name="car"
                    className="catalog-detail__overall-check-icon"
                />
                <Icon
                    name={typeof isApplicable !== 'undefined' && !isApplicable ? 'alert' : 'check'}
                    className="catalog-detail__overall-check-icon"
                />
                <span className="catalog-detail__overall-check-text">{applicableString()}</span>
            </button>

            {
                isShow
                  ? <Modal onClose={handleClose}>
                        <div className="catalog-detail__applicability">
                            <div className="catalog-detail__applicability-title">
                                <Icon
                                    name="car"
                                    className="catalog-detail__applicability-title-icon"
                                />
                                <Icon
                                    name="check"
                                    className="catalog-detail__applicability-title-icon"
                                />
                                <div className="heading heading_h3 catalog-detail__applicability-title-text">Also fits these makes and models</div>
                            </div>
                            <div className="catalog-detail__applicability-extra">
                                <div className="catalog-detail__applicability-subtitle">{subtitle}</div>
                                <ArrowLink
                                    title={`${isShowAll ? 'Hide' : 'Show'} full list`}
                                    link="#"
                                    onClick={toggleFullList}
                                />
                            </div>
                            <div className="catalog-detail__applicability-table">
                                <DetailCarApplicabilityTable
                                    list={list}
                                    isShowAll={isShowAll}
                                />
                            </div>
                        </div>
                    </Modal>
                  : null
            }
        </>
  );
};

export default DetailCarApplicability;
