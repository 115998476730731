import { apiRequest } from '../';
import { getVTypeId, VehicleType } from '../../utils/vehicleType';

function getList({
  vType,
  carId,
}: {
  vType?: VehicleType;
  carId?: number;
}): Promise<Response> {
  const params = new URLSearchParams();

  if (vType) {
    params.append('vt', String(getVTypeId(vType)));
  }
  if (carId) {
    params.append('car', String(carId));
  }

  return apiRequest(`/categories?${params}`, 'GET');
}

const categories = {
  getList,
};

export default categories;
