let filter: HTMLElement;
let filterForm: HTMLFormElement;
let footerObserver: IntersectionObserver;

const handleSubmit = (e): void => {
  const formData = new FormData(filterForm);
  const queryUrl = new URLSearchParams();

  e.preventDefault();

  formData.forEach((val, key) => {
    if (!queryUrl.get(key)) {
      const queryValue = formData.getAll(key).join(',');

      queryUrl.append(key, queryValue);
    }
  });

  window.location.href = queryUrl.toString() === ''
    ? filterForm.action
    : `${filterForm.action}?${queryUrl.toString()}`;
};

const handleClick = (e): void => {
  const target = e.target as HTMLButtonElement;
  const availableClasses = ['denso-filter__header-btn', 'denso-filter__header-close', 'denso-filter__fixed-btn', 'denso-filter__bg'];

  if (availableClasses.some(item => target.classList.contains(item))) {
    filter.classList.toggle('denso-filter_open');
  }
};

const events = (): void => {
  filter.addEventListener('click', handleClick, { passive: true });
  filterForm.addEventListener('submit', handleSubmit);
};

const catchFooter = (e: IntersectionObserverEntry[]): void => {
  filter.classList.toggle('denso-filter_hide-shadow', e[0].isIntersecting);
};

const initFooterObserver = (): void => {
  const footer = document.querySelector('.footer');
  const observerOptions = {
    rootMargin: '0px 0px -56px 0px',
  };

  if (footer) {
    footerObserver = new IntersectionObserver(catchFooter, observerOptions);
    footerObserver.observe(footer);
  }
};

const init = (): void => {
  filter = document.querySelector('.denso-filter');

  if (filter) {
    filterForm = filter.querySelector('.denso-filter__form');

    events();

    if (window.innerWidth < 1024) {
      initFooterObserver();
      filter.classList.remove('denso-filter_open');
    }
  }
};

export default {
  init,
};
