import { makeAutoObservable, runInAction } from 'mobx';

import UserForm from './model';

const storeUserForm = makeAutoObservable<UserForm>({
  fields: [],
  setFields: formFields => {
    runInAction(() => {
      storeUserForm.fields = formFields;
    });
  },
});

export default storeUserForm;
