import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../utils/translation';

import api from '../../api';

import storeCarTypes from './store/car-types';
import storeVehicleBrands from './store/vehicle-brands';

import Header from './components/header';
import Content from './components/content';
import { CAR_TYPES } from './constants';
import { CarType } from './store/car-types/model';

const App: FC<{ initialTypeSlug?: string; typeList?: CarType[] }> = ({
  initialTypeSlug,
  typeList,
}) => {
  useEffect(() => {
    if (initialTypeSlug) {
      storeCarTypes.setCurrent(
        CAR_TYPES.find(({ slug }) => slug === initialTypeSlug),
      );
    }
  }, []);

  useEffect(() => {
    storeCarTypes.setInitialList(typeList);
  }, [typeList]);

  useEffect(() => {
    api.car
      .getMakes(storeCarTypes.currentId)
      .then(res => res.json())
      .then(res => {
        if (res.data.all) {
          storeVehicleBrands.setList(
            res.data.all.sort((a, b) => a.slug.localeCompare(b.slug)),
            res.data.top
              ? res.data.top.sort((a, b) => a.slug.localeCompare(b.slug))
              : [],
          );

          if (storeVehicleBrands.initialMake) {
            const elementInList = res.data.all.find(make => make.slug === storeVehicleBrands.initialMake);

            if (elementInList) {
              storeVehicleBrands.setCurrent(elementInList);
            }

            storeVehicleBrands.setInitialMake(null);
          }
        }
      })
      .catch(error => console.error(error));
  }, [storeCarTypes.currentId]);

  return (
    <div className="d-search-bar">
      <Header
        title={ __('Search for your vehicle parts') }
        subtitle={ __('Select your make and model or search for a part number') }
      />
      <Content />
    </div>
  );
};

export default observer(App);
