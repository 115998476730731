import * as React from 'react';
import cn from 'classnames';

const Button: React.FC<{
  width?: ('full'),
  onClick?: any,
  disabled?: boolean,
}> = ({
  children,
  width,
  onClick,
  disabled,
}) => {
  return <button
        className={cn(
          'd-search-bar__btn',
          { [`d-search-bar__btn_${width}`]: width },
        )}
        onClick={ onClick }
        disabled={disabled}
    >{children}</button>;
};

export default Button;
