import { makeAutoObservable, runInAction } from 'mobx';

import Breadcrumbs from './model';

const breadcrumbs = makeAutoObservable<Breadcrumbs>({
  list: [],
  setList: list => {
    runInAction(() => {
      breadcrumbs.list = list;
    });
  },
  addItem: item => {
    if (item) {
      runInAction(() => {
        breadcrumbs.list = [...breadcrumbs.list, item];
      });
    }
  },
});

export default breadcrumbs;
