const AUTH_BASE_URL = '/actions/users';
const authRequest = (
  endPoint: string,
  formData?: FormData,
  requestParams?: object,
): Promise<Response> => {
  if (formData) {
    formData.append('CRAFT_CSRF_TOKEN', (window as any).DENSO.csrfTokenValue);
  }

  return fetch(`${AUTH_BASE_URL}${endPoint}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
    },
    body: formData ? formData : null,
    ...requestParams,
  });
};

const login = (formData: FormData): Promise<Response> => {
  if (formData) {
    formData.append('CRAFT_CSRF_TOKEN', (window as any).DENSO.csrfTokenValue);
  }

  return fetch('/denso/auth/login', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
    },
    body: formData,
  });
};

const resetPassword = (formData: FormData): Promise<Response> => authRequest('/send-password-reset-email', formData);

const register = (formData: FormData): Promise<Response> => authRequest('/save-user', formData);

const userInfo = (): Promise<Response> => fetch('/denso/auth/current-user');

const auth = {
  login,
  resetPassword,
  register,
  userInfo,
};

export default auth;
