import * as Sentry from '@sentry/browser';

const init = (): void => {
  const { DENSO } = window as any;

  try {
    Sentry.init(DENSO.sentry);

    if (DENSO.user) {
      Sentry.setUser(DENSO.user);
    }
  } catch (e) {
    console.error(e);
  }
};

export default {
  init,
};
