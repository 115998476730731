import React, { FC } from 'react';

const Radio: FC<{
  name: string,
  label: string,
  value: string | number,
  checked?: boolean,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}> = ({
  name,
  label,
  value,
  checked = false,
  onChange,
}) => {
  return (
        <label className="denso-radio">
            <input
                className="denso-radio__input"
                type="radio"
                value={value}
                name={name}
                checked={checked}
                onChange={onChange}
            />
            <span className="denso-radio__box" aria-hidden="true" />
            <span className="denso-radio__label">{label}</span>
        </label>
  );
};

export default Radio;
