import { makeAutoObservable, runInAction } from 'mobx';

const storeCurrentVehicle = makeAutoObservable({
  current: null,
  setCurrent: newCar => {
    runInAction(() => {
      storeCurrentVehicle.current = newCar;
    });
  },
  get currentId() {
    return storeCurrentVehicle.current?.id;
  },
});

export default storeCurrentVehicle;
