/**
 * The translation function gets a dictionary from the `DENSO.t` global variable
 * @param translationString
 * @return translation or current string
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
const __ = (translationString: string): string => {
  const dictionary = (window as any).DENSO?.t;

  return (dictionary && dictionary[translationString]) || translationString;
};

export default __;
