import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import api from '../../../../api';

import storeVehicleBrands from '../../store/vehicle-brands';
import storeVehicleModels from '../../store/vehicle-model';
import storeVehicleYears from '../../store/vehicle-year';
import storeVehicleBodies from '../../store/vehicle-body';
import storeVehicleVolumes from '../../store/vehicle-volume';
import { VehicleVolume } from '../../store/vehicle-volume/model';

import refreshVehicleStore from '../../utils/refresh-store';

import DropDown from '../dropdown';
import Column from '../column';

const VehicleVolumeField: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const selected = storeVehicleVolumes.current
    ? storeVehicleVolumes.current
    : null;

  const handleChange = (selectedItem: VehicleVolume) => {
    refreshVehicleStore('volume', selectedItem);
  };

  useEffect(() => {
    if (storeVehicleBodies.current && !storeVehicleVolumes.current) {
      api
        .car
        .getVolumes(
          storeVehicleBrands.currentId,
          storeVehicleModels.currentId,
          storeVehicleYears.currentId,
          storeVehicleBodies.currentId,
        )
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            storeVehicleVolumes.setList(res.data);

            if (res.data.length === 1) {
              storeVehicleVolumes.setCurrent(res.data[0]);
            }
          }
          setIsLoading(false);
        })
        .catch(error => console.error(error));
    }
  }, [storeVehicleBodies.current]);

  return (
        <Column label={ __('Engine type') }>
            <DropDown
                placeholder={ __('Select engine type') }
                selected={selected}
                list={storeVehicleVolumes.list}
                onChange={handleChange}
                withIncludeSearch
                disabled={!storeVehicleBodies.current}
                isOpen={storeVehicleBodies.current && storeVehicleVolumes.list?.length > 1 && !isLoading}
            />
        </Column>
  );
};

export default observer(VehicleVolumeField);
