import React, { FC, useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { observer } from 'mobx-react-lite';
import Sidebar from './components/sidebar';
import MapComponent from './components/map';
import { getUserLocation } from './helpers/location';
import storeUserLocation from './store/user-location';
import { DESKTOP_WIDTH, MAP_API_KEY } from './constants';
import { getFilters } from './api';
import { setStoreFilters } from './helpers/store';
import CurrentVendor from './components/current-vendor';
import { useWindowSize } from './helpers/windowSizeHook';
import AddressField from './components/sidebar/components/address-field';
import storeMap from './store/map';
import storeStores from './store/stores';
import { useBodyHeight } from './helpers/useBodyHeight';
import storeCurrentFilters from './store/filters';

const App: FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [mobileFiltersOpened, setMobileFiltersOpened] = useState(false);
  const [label, setLabel] = useState('Filter');
  const windowSize = useWindowSize();
  useBodyHeight();

  const mapLoader = new Loader({
    apiKey: MAP_API_KEY,
    version: 'weekly',
    libraries: ['places'],
    language: 'en',
  });

  // @ts-ignore
  useEffect(async () => {
    storeMap.setLoading(true);
    await getFilters().then(filters => {
      setStoreFilters(filters);
    });
    mapLoader.load().then(() => {
      getUserLocation().then(position => {
        storeUserLocation.setPosition(position);
        setLoaded(true);
      });
    });
  }, []);

  useEffect(() => {
    const isChosen = !!(storeCurrentFilters.currentFilters?.type_of_location.length
        || storeCurrentFilters.currentFilters?.type_of_sales.length
        || storeCurrentFilters.currentFilters?.type_of_store.length);

    if (isChosen) {
      setLabel('Show results');
    } else {
      setLabel('Filter');
    }

    if (!mobileFiltersOpened) {
      setLabel('Filter');
    }
  }, [storeStores.visibleStoreList, mobileFiltersOpened]);


  useEffect(() => {
    if (storeCurrentFilters.currentFilters.type_of_store[0]?.id === 3) {
      setMobileFiltersOpened(false);
    }
  }, [storeCurrentFilters.currentFilters.type_of_store]);

  return (
    <div className="where-to-buy">
      <Sidebar
        hideFilters={() => setMobileFiltersOpened(false)}
        showMobileFilters={mobileFiltersOpened}
      />
      {loaded && <MapComponent />}
      {!!storeStores.currentStore && (
        <CurrentVendor
          handleClose={() => {
            storeStores.setCurrentStore(null);
          }}
          vendor={storeStores.currentStore}
        />
      )}
      {windowSize.width < DESKTOP_WIDTH && <AddressField />}
      {windowSize.width < DESKTOP_WIDTH && (
        <div className="where-to-buy__sidebar-trigger">
          <button
            onClick={() => {
              setMobileFiltersOpened(!mobileFiltersOpened);
            }}
            className="button"
          >
            { label }
          </button>
        </div>
      )}
    </div>
  );
};

export default observer(App);
