import React, { FC, useEffect, useContext } from 'react';
import cn from 'classnames';

import MobileMenuContext from '../../context';

import DefaultModal from '../modals';
import MenuSub from '../modals/sub';
import MenuAccount from '../modals/account';
import MenuFMP from '../modals/fmp';
import AuthModal from '../modals/auth';

const MenuModal: FC = () => {
  const { currentModal } = useContext(MobileMenuContext);
  const modals = {
    fmp: <MenuFMP />,
    sub: <MenuSub />,
    account: <MenuAccount />,
    login: <AuthModal />,
  };

  useEffect(() => {
    const latestTopOffset = window.scrollY;

    document.documentElement.classList.add('is-fixed');
    document.documentElement.scrollTop = latestTopOffset;
    document.body.scrollTop = latestTopOffset;

    return () => {
      document.documentElement.classList.remove('is-fixed');
      document.documentElement.scrollTop = latestTopOffset;
      document.body.scrollTop = latestTopOffset;
    };
  }, []);

  return (
    <div className={cn(
      'mobile-menu__modal',
      {'mobile-menu__modal_full': currentModal},
    )}>
      <div className="mobile-menu__modal-inner">
        {
          modals[currentModal] || <DefaultModal />
        }
      </div>
    </div>
  )
};

export default MenuModal;
