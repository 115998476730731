import React, { FC } from 'react';

import __ from '../../../../utils/translation';

import MenuWrap from '../menu-wrap';
import MenuItem from '../menu-item';

const MenuAccount: FC = () => {
  return (
    <MenuWrap title={ __('Account') }>
      <MenuItem
        title={ __('Account settings') }
        link="/account"
      />
      <MenuItem
        title={ __('Log out') }
        link="/logout"
      />
    </MenuWrap>
  );
};

export default MenuAccount;
