import __ from '../../utils/translation';

// Available User Menu
export const USER_SIDEBAR_MENU = [{
  label: __('Links'),
  handle: 'links',
  isRoot: true,
}, {
  label: __('My vehicles'),
  handle: 'vehicles',
}, {
  label: __('Account settings'),
  handle: 'account',
}];
