import { makeAutoObservable, runInAction } from 'mobx';
import { Tooltips } from '../model';

const storeTooltips = makeAutoObservable<Tooltips>({
  tooltips: {},
  label: 'Tooltips',
  alias: 'tooltips',
  setTooltips(tooltips) {
    runInAction(() => {
      storeTooltips.tooltips = tooltips;
    });
  },
});

export default storeTooltips;