let contactForm = document.querySelector('[data-freeform]') as HTMLDivElement;
const inputFile = document.querySelector(
  '#form-input-uploadFiles',
) as HTMLFormElement;
let checkbox;
let countrySelect;
let label;

const scrollToFn = elem => {
  elem = document.querySelector(elem) as HTMLDivElement;
  let headerHeight = document.querySelector('.header__main') as HTMLDivElement;
  let headerHeightMob = document.querySelector('.header') as HTMLDivElement;
  let offset =
    elem.getBoundingClientRect().top +
    window.scrollY -
    headerHeight.offsetHeight;

  if (window.innerWidth < 768) {
    offset =
      elem.getBoundingClientRect().top +
      window.scrollY -
      headerHeightMob.offsetHeight;
  }

  if (offset) {
    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }
};

const updateForm = (): void => {
  const formSuccessMsg = contactForm.querySelector('.freeform-form-success');

  if (formSuccessMsg) {
    let headerHeight = document.querySelector(
      '.header__main',
    ) as HTMLDivElement;
    let headerHeightMob = document.querySelector('.header') as HTMLDivElement;
    let offset =
      contactForm.getBoundingClientRect().top +
      window.scrollY -
      headerHeight.offsetHeight;

    if (window.screen.width < 768) {
      offset =
        contactForm.getBoundingClientRect().top +
        window.scrollY -
        headerHeightMob.offsetHeight;
    }

    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }
};

const errorForm = (): void => {
  scrollToFn('[data-freeform]');
};

const updateCheckboxes = () => {
  checkbox.forEach(item => {
    const parentEl = item.parentElement;
    let fakeEl = document.createElement('i');

    parentEl.classList.add('form-checkbox-wrap');
    item.classList.add('form-checkbox');
    item.after(fakeEl);
  });
};

const fileBtnRemove = () => {
  const target = document.querySelector('.file-btn') as HTMLElement;

  inputFile.value = '';
  label.classList.remove('state-active');
  target.remove();
};

const fileBtnGenerate = () => {
  if (inputFile.value) {
    const fileBtn = document.createElement('span');
    fileBtn.className = 'file-btn';
    label.classList.add('state-active');
    fileBtn.innerHTML = `${inputFile.files[0].name} <span class="file-btn__close"></span>`;

    label.after(fileBtn);
  }
};

const catchLink = (e: Event): void => {
  const target = e.target as HTMLLinkElement;

  if (target.classList.contains('service__hero-btn')) {
    e.preventDefault();
    scrollToFn(target.getAttribute('href'));
  }

  if (target.classList.contains('file-btn__close')) {
    e.preventDefault();
    fileBtnRemove();
  }
};

const updateSelect = (): void => {
  if (countrySelect.selectedIndex > 0) {
    countrySelect.classList.remove('is-empty');
  } else {
    countrySelect.classList.add('is-empty');
  }
};

const createLabelBtn = () => {
  label.innerHTML += `<span class="file-label__btn">${label.getAttribute(
    'data-input-text',
  )}</span>`;
};

const events = () => {
  if (document.querySelector('#mainContactForm')) {
    let mainContactForm = document.querySelector('#mainContactForm');
    countrySelect = mainContactForm.querySelector(
      '#form-input-country',
    ) as HTMLFormElement;

    mainContactForm.addEventListener('freeform-ajax-success', updateForm);

    if (countrySelect) {
      countrySelect[0].setAttribute('disabled', 'disabled');
      countrySelect[0].setAttribute('hidden', 'hidden');
      countrySelect.classList.add('is-empty');

      countrySelect.addEventListener('change', updateSelect);
    }
  }

  contactForm.addEventListener('freeform-ajax-error', errorForm);

  if (inputFile) {
    inputFile.addEventListener('change', fileBtnGenerate);
    label = inputFile.previousElementSibling as HTMLFormElement;
    createLabelBtn();
  }

  document.addEventListener('click', catchLink);
};

const init = (): void => {
  if (contactForm) {
    checkbox = contactForm.querySelectorAll('input[type="checkbox"]');

    events();

    if (checkbox) {
      updateCheckboxes();
    }
  }
};

export default {
  init,
};
