import { makeAutoObservable, runInAction } from 'mobx';

import VehicleYears from './model';

const storeVehicleYears = makeAutoObservable<VehicleYears>({
  list: [],
  setList: (list = []) => {
    runInAction(() => {
      storeVehicleYears.list = list;
    });
  },
  current: null,
  setCurrent: (newVehicleYear) => {
    runInAction(() => {
      storeVehicleYears.current = newVehicleYear;
    });
  },
  get currentId() {
    if (storeVehicleYears.current === null) {
      return null;
    }

    return storeVehicleYears.current.key;
  },
});

export default storeVehicleYears;
