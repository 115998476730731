import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import storeCarTypes from '../../store/car-types';
import { CarType } from '../../store/car-types/model';

import refreshVehicleStore from '../../utils/refresh-store';

import Tab from '../tab';

const Header: FC<{
  title: string;
  subtitle?: string;
}> = ({
  title,
  subtitle,
}) => {
  const { list }: { list: CarType[] } = storeCarTypes;
  const currentTypeId: number = storeCarTypes.currentId;

  const handleTabChange = (carType: CarType): void => {
    refreshVehicleStore('brand', null);
    storeCarTypes.setCurrent(carType);
    carType.onClick?.(carType);
  };

  return (
    <header className="d-search-bar__header">
      <div className="d-search-bar__header-text">
        <div className="d-search-bar__title heading heading_h2">{title}</div>
        {subtitle ? (
          <div className="d-search-bar__subtitle">{subtitle}</div>
        ) : null}
      </div>
      <div className="d-search-bar__header-scroller">
        <div className="d-search-bar__header-tabs">
          {
            list
              .map(item => (
                <Tab
                  key={`search-tab-type-${item.id}`}
                  isActive={currentTypeId === item.id}
                  value={item}
                  onClick={handleTabChange}
                >{ item.label }</Tab>
              ))
          }
        </div>
      </div>
    </header>
  );
};

export default observer(Header);
