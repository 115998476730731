import { makeAutoObservable, runInAction } from 'mobx';

import VehicleModel from './model';

const storeVehicleModels = makeAutoObservable<VehicleModel>({
  list: [],
  setList: (list = []) => {
    runInAction(() => {
      storeVehicleModels.list = list;
    });
  },
  current: null,
  setCurrent: (newVehicleModel) => {
    runInAction(() => {
      storeVehicleModels.current = newVehicleModel;
    });
  },
  get currentId() {
    if (storeVehicleModels.current === null) {
      return null;
    }

    return storeVehicleModels.current.key;
  },
});

export default storeVehicleModels;
