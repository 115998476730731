import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import {USER_SIDEBAR_MENU} from '../../constants';

const UserHeading: FC = () => {
  return (
    <div className="user-account__header">
      {
        USER_SIDEBAR_MENU.map(item => (
          <Route
            key={`account-menu-item-${item.handle}`}
            path={item.isRoot ? '/' : `/${item.handle}`}
            children={<h1 className="heading heading_h2 user-account__header-title">{ item.label }</h1>}
            exact
          />
        ))
      }
    </div>
  );
};

export default UserHeading;
