import React, { FC, useState, useEffect, SyntheticEvent } from 'react';

import { VehicleType } from '../../../denso-catalog/utils/vehicleType';
import { CarType } from '../../../d-search-bar/store/car-types/model';
import { CAR_TYPES } from '../../../d-search-bar/constants';

import api from '../../../denso-catalog/api';
import __ from '../../../../utils/translation';

import MenuWrap from '../menu-wrap';
import MenuItem from '../menu-item';
import Icon from '../../../denso-catalog/components/icon';

const CategoriesList: FC<{
  vehicleType: CarType,
  onCategoryClick: (categorySelected: boolean) => void,
}> = ({
  vehicleType,
  onCategoryClick,
}) => {
  const [list, setList] = useState(null);
  const [currentList, setCurrentList] = useState(null);
  const resultList = currentList ? currentList.children : list;
  const allProductsText = currentList ? currentList.title : vehicleType.label;

  useEffect(() => {
    api
      .categories
      .getList({
        vType: (vehicleType.slug as VehicleType),
      })
      .then(res => res.json())
      .then((res) => {
        if (res.data?.length > 0) {
          const sortedList = res.data.sort((a, b) =>
            a.title.localeCompare(b.title),
          );

          setList(sortedList);
        }
      });
  }, []);

  const handleCategoryClick = (category) => (e) => {
    if (category.children?.length > 0) {
      e.preventDefault();

      setCurrentList(category);
      onCategoryClick(true);
    }
  };

  const handleBackClick = (e: SyntheticEvent): void => {
    e.preventDefault();

    setCurrentList(null);
    onCategoryClick(false);
  };

  if (!list) {
    return null;
  }

  return (
    <>
      {
        currentList
          ? <MenuItem
            title={ __('All categories') }
            link={'#'}
            onClick={handleBackClick}
            isReverse
          />
          : null
      }

      <div className="mobile-menu__link mobile-menu__link_single">
        <a
          href={`/catalog/${vehicleType.slug}${ currentList ? `/${currentList.slug}` : '' }`}
          className="mobile-menu__link-text"
        >
          <span className="mobile-menu__link-text-highlight">All products</span>
          <span className="mobile-menu__link-text-sub">in { allProductsText }</span>
        </a>
        <div className="mobile-menu__link-icon">
          <Icon
            name="right-arrow"
            className="mobile-menu__link-svg"
          />
        </div>
      </div>

      {
        resultList.map((item) => (
          <MenuItem
            title={item.title}
            link={`/catalog/${vehicleType.slug}${ currentList ? `/${currentList.slug}` : '' }/${item.slug}`}
            onClick={handleCategoryClick(item)}
          />
        ))
      }
    </>
  );
};

const MenuFMP: FC = () => {
  const [title, setTitle] = useState(null);
  const [currentType, setCurrentType] = useState(null);
  const [isCategory, setIsCategory] = useState(null);
  const FMPString = __('Find my part');

  const handleTypeClick = (newType) => (e) => {
    e.preventDefault();

    setTitle(newType ? newType.label : null);
    setCurrentType(newType);
  };

  const handleCategoryClick = (categorySelected) => setIsCategory(categorySelected);

  return (<MenuWrap title={ title ?? FMPString }>
    {
      currentType
        ? (
          isCategory
            ? null
            : <MenuItem
              title={FMPString}
              link={'#'}
              onClick={handleTypeClick(null)}
              isReverse
            />
        )
        : CAR_TYPES.map((item) => (
          <MenuItem
            key={`car-type-${item.id}`}
            title={item.label}
            link={`/catalog/${item.slug}`}
            onClick={handleTypeClick(item)}
          />
        ))
    }

    {
      currentType
        ? <CategoriesList
          vehicleType={currentType}
          onCategoryClick={handleCategoryClick}
        />
        : null
    }
  </MenuWrap>);
};

export default MenuFMP;
