import { makeAutoObservable, runInAction } from 'mobx';

import Overall from './model';

const overall = makeAutoObservable<Overall>({
  isLinkSearch: false,
  setIsLinkSearch: (isSearch = false) => {
    runInAction(() => {
      overall.isLinkSearch = isSearch;
    });
  },
});

export default overall;
