import React, { FC, useState } from 'react';

import __ from '../../../../utils/translation';

import Modal from '../../../denso-catalog/components/modal';
import AuthLogin from '../login';
import AuthRegister from '../register';
import AuthForgot from '../forgot';

const AuthModals: FC = () => {
  const [isShowModal, setIsShowModal] = useState(() => {
    const {search, hash} = window.location;

    return new URLSearchParams(search).has('isLogin') || hash === '#login';
  });
  const [currentModal, setCurrentModal] = useState<'login' | 'register' | 'forgot'>('login');

  const handleLoginClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    setIsShowModal(true);
  };

  const handleClose = () => {
    setIsShowModal(false);
    setCurrentModal('login');
  };

  const changeModalType = (newModalType: 'login') => {
    setCurrentModal(newModalType);
  };

  const availableModals = {
    login: <AuthLogin handleModalChange={changeModalType} />,
    register: <AuthRegister handleModalChange={changeModalType} />,
    forgot: <AuthForgot handleModalChange={changeModalType} />,
  };

  return (
        <>
            <a
                href="#"
                className="header__nav-link"
                onClick={handleLoginClick}
            >{ __('Login') }</a>

            {
                isShowModal
                  ? <Modal
                        className="auth__modal"
                        onClose={handleClose}
                    >
                        <div className="auth__modal-inner">
                            { availableModals[currentModal] }
                        </div>
                    </Modal>
                  : null
            }
        </>
  );
};

export default AuthModals;
