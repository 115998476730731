import React from 'react';
import ReactDOM from 'react-dom';

import storeUserForm from './store/user-account';

import App from './app';

let entryPoint: HTMLElement;

const initApp = (): void => {
  if (entryPoint.dataset.userForm) {
    storeUserForm.setFields(JSON.parse(entryPoint.dataset.userForm));
  }

  ReactDOM.render(
    React.createElement(App),
    entryPoint,
  );
};

const init = (): void => {
  entryPoint = document.querySelector('#denso-account');

  if (entryPoint) {
    initApp();
  }
};

export default {
  init,
};
