import { makeAutoObservable, runInAction } from 'mobx';

import VehicleVolumes from './model';

const storeVehicleVolumes = makeAutoObservable<VehicleVolumes>({
  list: [],
  setList: (list = []) => {
    runInAction(() => {
      storeVehicleVolumes.list = list;
    });
  },
  current: null,
  setCurrent: (newVehicleBody) => {
    runInAction(() => {
      storeVehicleVolumes.current = newVehicleBody;
    });
  },
  get currentId() {
    if (storeVehicleVolumes.current === null) {
      return null;
    }

    return storeVehicleVolumes.current.key;
  },
});

export default storeVehicleVolumes;
