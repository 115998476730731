import { apiRequest } from '../';
import { vehicleTypeFormat } from '../utils/vehicle-type-format';

const getMakes = (vehicleType: number = 1) => {
  const requestString = vehicleTypeFormat('/makers', vehicleType);

  return apiRequest(requestString, 'GET');
};

const getModels = (makeId: number, vehicleType: number = 1) => {
  const requestString = vehicleTypeFormat(
    `/makers/${makeId}/models`,
    vehicleType,
  );

  return apiRequest(requestString, 'GET');
};

const getYears = (makeId: number, modelId: number, vehicleType: number = 1) => {
  const requestString = vehicleTypeFormat(
    `/makers/${makeId}/models/${modelId}/years`,
    vehicleType,
  );

  return apiRequest(requestString, 'GET');
};

const getBodies = (
  makeId: number,
  modelId: number,
  year: number,
  vehicleType: number = 1,
) => {
  const requestString = vehicleTypeFormat(
    `/makers/${makeId}/models/${modelId}/years/${year}/bodies`,
    vehicleType,
  );

  return apiRequest(requestString, 'GET');
};

const getVolumes = (
  makeId: number,
  modelId: number,
  year: number,
  bodyId: number,
  vehicleType: number = 1,
) => {
  const requestString = vehicleTypeFormat(
    `/makers/${makeId}/models/${modelId}/years/${year}/bodies/${bodyId}/volumes`,
    vehicleType,
  );

  return apiRequest(requestString, 'GET');
};

const getTypes = (
  makeId: number,
  modelId: number,
  year: number,
  bodyId: number,
  volumeId: number,
  vehicleType: number = 1,
) => {
  const requestString = vehicleTypeFormat(
    `/makers/${makeId}/models/${modelId}/years/${year}/bodies/${bodyId}/volumes/${volumeId}/types`,
    vehicleType,
  );

  return apiRequest(requestString, 'GET');
};

const getCar = (
  makeId: number,
  modelId: number,
  year: number,
  bodyId: number,
  volumeId: number,
  typeId: number,
) => {
  return apiRequest('/cars', 'POST', {
    filters: {
      maker_tid: makeId,
      model_tid: modelId,
      year: year,
      body_tid: bodyId,
      volume: volumeId,
      fuel_tid: typeId,
    },
  });
};

const cars = {
  getMakes,
  getModels,
  getYears,
  getBodies,
  getVolumes,
  getTypes,
  getCar,
};

export default cars;
