import { reaction } from 'mobx';
import overall from '.';
import breadcrumbs from '../breadcrumbs';

export function setOverallListeners() {
  reaction(
    () => overall.prefix,
    () => {
      breadcrumbs.setList([
        {
          title: overall.title,
          link: `/${overall.prefix}`,
        },
      ]);
    },
  );
}
