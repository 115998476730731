import { debounce } from 'lodash-es';
import { useEffect } from 'react';

function measureHeight(): number | null {
  return document.documentElement?.clientHeight || window.innerHeight;
}

function setHeight(height: number): void {
  document.documentElement?.style?.setProperty(
    '--d-body-height',
    `${height}px`,
  );
}

/**
 * Adds css variable with really 100 view height
 * @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
export function useBodyHeight(): void {
  useEffect(() => {
    function setMeasuredHeight() {
      const measuredHeight = measureHeight();
      setHeight(measuredHeight);
    }

    const debouncedFn = debounce(setMeasuredHeight, 100);

    setMeasuredHeight();
    window.addEventListener('resize', debouncedFn);
    return () => window.removeEventListener('resize', debouncedFn);
  }, []);
}
