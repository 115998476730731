import { apiRequest } from '../';

const findPart = (q: string, signal: AbortSignal) => apiRequest(`/altsearch?q=${q}`, 'GET', null, { signal });

const findGlobal = (q: string, signal: AbortSignal) => apiRequest(`/search?q=${q}`, 'GET', null, { signal });

const altSearch = {
  findPart,
  findGlobal,
};

export default altSearch;
