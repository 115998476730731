import { CarType } from './store/car-types/model';
import __ from '../../utils/translation';

export const CAR_TYPES: CarType[] = [
  {
    id: 1,
    label: __('Passenger vehicle'),
    typeName: __('Automotive car'),
    slug: 'pv',
  },
  {
    id: 2,
    label: __('Commercial vehicle'),
    typeName: __('Commercial'),
    slug: 'cv',
  },
  {
    id: 3,
    label: __('Motorcycle'),
    typeName: __('Motorcycle'),
    slug: 'mc',
  },
];

export const CAR_BRANDS = [
  {
    id: 1,
    label: 'Abarth',
  },
  {
    id: 2,
    label: 'Alfa Romeo',
  },
  {
    id: 3,
    label: 'Aston Martin',
  },
  {
    id: 4,
    label: 'Audi',
  },
  {
    id: 5,
    label: 'Bentley',
  },
  {
    id: 6,
    label: 'BMW',
  },
  {
    id: 7,
    label: 'Bugatti',
  },
  {
    id: 8,
    label: 'Cadillac',
  },
  {
    id: 9,
    label: 'Chevrolet',
  },
  {
    id: 10,
    label: 'Chrysler',
  },
  {
    id: 11,
    label: 'Citroën',
  },
  {
    id: 12,
    label: 'Dacia',
  },
  {
    id: 13,
    label: 'Daewoo',
  },
  {
    id: 14,
    label: 'Daihatsu',
  },
  {
    id: 15,
    label: 'Dodge',
  },
  {
    id: 16,
    label: 'Donkervoort',
  },
  {
    id: 17,
    label: 'DS',
  },
  {
    id: 18,
    label: 'Ferrari',
  },
  {
    id: 19,
    label: 'Fiat',
  },
  {
    id: 20,
    label: 'Fisker',
  },
  {
    id: 21,
    label: 'Ford',
  },
  {
    id: 22,
    label: 'Honda',
  },
  {
    id: 23,
    label: 'Hummer',
  },
  {
    id: 24,
    label: 'Hyundai',
  },
  {
    id: 25,
    label: 'Infiniti',
  },
  {
    id: 26,
    label: 'Iveco',
  },
  {
    id: 27,
    label: 'Jaguar',
  },
  {
    id: 28,
    label: 'Jeep',
  },
  {
    id: 29,
    label: 'Kia',
  },
  {
    id: 30,
    label: 'KTM',
  },
  {
    id: 31,
    label: 'Lada',
  },
  {
    id: 32,
    label: 'Lamborghini',
  },
  {
    id: 33,
    label: 'Lancia',
  },
  {
    id: 34,
    label: 'Land Rover',
  },
  {
    id: 35,
    label: 'Landwind',
  },
  {
    id: 36,
    label: 'Lexus',
  },
  {
    id: 37,
    label: 'Lotus',
  },
  {
    id: 38,
    label: 'Maserati',
  },
  {
    id: 39,
    label: 'Maybach',
  },
  {
    id: 40,
    label: 'Mazda',
  },
  {
    id: 41,
    label: 'McLaren',
  },
  {
    id: 42,
    label: 'Mercedes-Benz',
  },
  {
    id: 43,
    label: 'MG',
  },
  {
    id: 44,
    label: 'Mini',
  },
  {
    id: 45,
    label: 'Mitsubishi',
  },
  {
    id: 46,
    label: 'Morgan',
  },
  {
    id: 47,
    label: 'Nissan',
  },
  {
    id: 48,
    label: 'Opel',
  },
  {
    id: 49,
    label: 'Peugeot',
  },
  {
    id: 50,
    label: 'Porsche',
  },
  {
    id: 51,
    label: 'Renault',
  },
  {
    id: 52,
    label: 'Rolls-Royce',
  },
  {
    id: 53,
    label: 'Rover',
  },
  {
    id: 54,
    label: 'Saab',
  },
  {
    id: 55,
    label: 'Seat',
  },
  {
    id: 56,
    label: 'Skoda',
  },
  {
    id: 57,
    label: 'Smart',
  },
  {
    id: 58,
    label: 'SsangYong',
  },
  {
    id: 59,
    label: 'Subaru',
  },
  {
    id: 60,
    label: 'Suzuki',
  },
  {
    id: 61,
    label: 'Tesla',
  },
  {
    id: 62,
    label: 'Toyota',
  },
  {
    id: 63,
    label: 'Volkswagen',
  },
  {
    id: 64,
    label: 'Volvo',
  },
];

export const CAR_MODELS = [
  {
    id: 1,
    label: '1-Series',
  },
  {
    id: 2,
    label: '1-Series Active Tourer',
  },
  {
    id: 3,
    label: '2-Series',
  },
  {
    id: 4,
    label: '3-Series',
  },
  {
    id: 5,
    label: '4-Series Gran Coupe',
  },
  {
    id: 6,
    label: '5-Series',
  },
  {
    id: 7,
    label: 'X-Series',
  },
];

export const CAR_ENGINES = [
  {
    id: 1,
    label: '114i',
  },
  {
    id: 2,
    label: '116i',
  },
  {
    id: 3,
    label: '116i Automat',
  },
  {
    id: 4,
    label: '118i',
  },
  {
    id: 5,
    label: '125i',
  },
  {
    id: 6,
    label: '114d',
  },
  {
    id: 7,
    label: '116d',
  },
  {
    id: 8,
    label: '118d',
  },
];
