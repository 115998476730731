import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import filter from '../../store/filter';

import Icon from '../icon';

const BannerImage: FC = () => {
  // Get only first or second level of category
  const levelsCount = filter.levels.length;
  const currentBanner = levelsCount > 0
    ? filter.levels[levelsCount === 1 ? 0 : 1]
    : {
      subtitle: 'Working away unseen for virtually all car manufacturers in the world.',
      link: '/products',
    };
  const bannerTitle = currentBanner?.subtitle || `${currentBanner?.title} Products`;

  if (!currentBanner) {
    return null;
  }

  return (
        <div className="banner">
            <picture className="banner__bg" aria-hidden="true">
                {
                    currentBanner.cover
                      ? <img
                            className="banner__bg-img"
                            src={currentBanner.cover.src}
                            width={currentBanner.cover.width}
                            height={currentBanner.cover.height}
                            alt={bannerTitle}
                            loading="lazy"
                            decoding="async"
                        />
                      : null
                }
            </picture>

            <div className="banner__inner">
                <div className="banner__title">
                    <h2 className="heading heading_h3 heading_color_white">{bannerTitle}</h2>
                </div>
                <div className="banner__foot">
                    <div className="banner__foot-icon">
                        <Icon name="right-arrow" />
                    </div>
                    <a
                        href={currentBanner.link}
                        className="banner__foot-link"
                    >Learn more</a>
                </div>
            </div>
        </div>
  );
};

export default observer(BannerImage);
