import __ from '../../../utils/translation';

const declOfNum = (n: number, titles: string[]) => {
  return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
};

export const resultText = (partsCount: number = 0) => {
  const prefix = partsCount === 0 ? __('No') : partsCount;
  const text = declOfNum(partsCount, ['result', 'results', 'results']);

  return `${prefix} ${ __(text) }`;
};

export default declOfNum;
