import { makeAutoObservable, runInAction } from 'mobx';

import VehicleBodies from './model';

const storeVehicleBodies = makeAutoObservable<VehicleBodies>({
  list: [],
  setList: (list = []) => {
    runInAction(() => {
      storeVehicleBodies.list = list;
    });
  },
  current: null,
  setCurrent: newVehicleBody => {
    runInAction(() => {
      storeVehicleBodies.current = newVehicleBody;
    });
  },
  get currentId() {
    return storeVehicleBodies.current?.key;
  },
});

export default storeVehicleBodies;
