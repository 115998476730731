import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import storeStoresFilters from '../../../../store/filters/store-filters';
import MainFiltersItem from '../main-filters-item';
import { Filter } from '../../../../store/filters/model';
import { getFilterSubtype } from '../../../../api';
import storeCurrentFilters from '../../../../store/filters';

const MainFilters: FC = () => {
  const mainFilters = storeStoresFilters.list;
  const mainSubfilters = storeStoresFilters.subfiltersList;

  const pickFilter = async (filter: Filter, isSubfilter: boolean = false) => {
    if (!isSubfilter) {
      storeCurrentFilters.setCurrentFilters([filter], storeStoresFilters.alias);
      const subfilters = await getFilterSubtype(filter.id);
      storeStoresFilters.setList(subfilters, true);
    } else {
      storeCurrentFilters.setCurrentFilters(
        [filter],
        storeStoresFilters.subfilterAlias,
      );
    }
  };

  const mainPicked: boolean = !!storeCurrentFilters.currentFilters[
    storeStoresFilters.alias
  ].length;
  const subPicked: boolean = !!storeCurrentFilters.currentFilters[
    storeStoresFilters.subfilterAlias
  ].length;

  const activeFilters = mainPicked ? mainSubfilters : mainFilters;

  const filtersList = !activeFilters.length
    ? null
    : activeFilters.map(filterItem => {
      return (
          <MainFiltersItem
            handleClick={() => pickFilter(filterItem, mainPicked)}
            key={filterItem.id}
            filter={filterItem}
            isSubfilter={mainPicked}
          />
      );
    });

  if (!filtersList || (subPicked && mainPicked)) return null;

  return (
    <div className="where-to-buy__sidebar-main-filters">
      <div className="where-to-buy__sidebar-filter-label">
        What are you looking for?
      </div>
      {filtersList}
    </div>
  );
};

export default observer(MainFilters);
