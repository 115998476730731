import React, { FC, useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import overall from '../../store/overall';
import filter from '../../store/filter';
import { PER_PAGE } from '../../constants';
import { resultText } from '../../utils/declOfNum';

// import Icon from '../icon';
import Products from '../products';
import Pagination from '../pagination';

const CategoriesGrid: FC<{
  isLoading: boolean;
}> = ({
  isLoading = true,
}) => {
  const [page, setPage] = useState(0);
  const { pathname, search } = useLocation();
  const removeEmpty = (list = []) => list.filter(item => item.parts > 0);
  const currentList = filter.currentCategory
    ? removeEmpty(filter.currentCategory.children) || []
    : filter.categories.reduce(
      (acc, curr) => [...acc, ...(removeEmpty(curr.children) || [])],
      [],
    );
  const totalResults = filter.currentCategory
    ? filter.currentCategory.parts
    : filter.categories.reduce((acc, curr) => acc + (curr.parts || 0), 0);
  const chunkList = currentList.slice(
    page * PER_PAGE,
    page * PER_PAGE + PER_PAGE,
  );

  useEffect(() => {
    const searchPage = new URLSearchParams(search).get('page');
    const nextPage = searchPage ? parseInt(searchPage, 10) - 1 : 0;

    setPage(nextPage < 0 ? 0 : nextPage);
  }, [search]);

  // Scroll To
  const scrollToElement = useRef(null);
  const scrollToY = scrollToElement?.current?.offsetTop - 74 - 16;

  return (
    <div className="page-fmp__content-block" ref={scrollToElement}>
      <div className="page-fmp__result">
        <div className="page-fmp__result-count">
          {
            isLoading
              ? <span className="page-fmp__result-count-loading" />
              : resultText(totalResults)
          }
        </div>
        {/*}
        <div className="page-fmp__result-filter">
          <button className="button page-fmp__result-btn">
            <Icon
              name="filter"
              className="page-fmp__result-btn-svg"
            />
            <span className="page-fmp__result-btn-text">Filter{ totalResults > 0 ? ` ${resultText(totalResults)}` : '' }</span>
          </button>
        </div>
        {*/}
        <div className="page-fmp__result-list">
          <Products list={chunkList} prefix={overall.prefix} />
        </div>

        {chunkList &&
        chunkList.length &&
        totalResults !== 0 &&
        currentList.length > PER_PAGE + 1 ? (
          <div className="page-fmp__result-pagination">
            <Pagination
              total={currentList.length}
              current={page}
              pathname={pathname}
              scrollOnChangeTo={scrollToY}
            />
          </div>
          ) : null}
      </div>
    </div>
  );
};

export default observer(CategoriesGrid);
