function getYearsTable(innovationCards: HTMLCollectionOf<HTMLDivElement>) {
  const yearsTable = {};

  for (const card of Array.from(innovationCards)) {
    const year = card.querySelector('.year').innerHTML;
    if (yearsTable.hasOwnProperty(year)) {
      yearsTable[year] += 1;
    } else {
      yearsTable[year] = 1;
    }
  }

  return yearsTable;
}

export class TimelineMap {
  private progressElement: HTMLDivElement;

  private readonly yearsTable;

  private pointsElements: NodeListOf<HTMLDivElement>;

  public currentYearIndex = 1;

  constructor(innovationCards: HTMLCollectionOf<HTMLDivElement>) {
    this.yearsTable = getYearsTable(innovationCards);
    this.drawTimelinePoints();
    this.progressElement = document.querySelector('.map-progress');
    this.pointsElements = document.querySelectorAll('.map-point');
    this.goTo(this.currentYearIndex);
  }

  public goTo(yearIndex: number) {
    const distanceBetweenPoints = this.getDistanceBetweenPoints();
    this.currentYearIndex = yearIndex;

    const newProgressWidth = yearIndex * distanceBetweenPoints;
    const pointsOffset = this.getPointsOffset(yearIndex);
    this.progressElement.style.width = `${newProgressWidth + pointsOffset}px`;
    this.updatePointClasses();
  }

  private updatePointClasses() {
    this.pointsElements.forEach((element, index) => {
      element.classList.remove('active');
      if (index < this.currentYearIndex) {
        element.classList.add('passed');
      } else {
        element.classList.remove('passed');
      }
    });
    this.pointsElements[this.currentYearIndex - 1].classList.add('active');
  }

  private getPointsOffset(yearIndex: number) {
    const year = Object.keys(this.yearsTable)[yearIndex - 1];
    let offset = 0;
    for (let i = 0; i < yearIndex; i++) {
      offset += this.pointsElements[i].offsetWidth / this.yearsTable[year];
    }
    return offset;
  }

  private drawTimelinePoints() {
    const timelineMapPoints = document.querySelector(
      '.map-points',
    ) as HTMLDivElement;

    for (let key in this.yearsTable) {
      const mapPoint = document.createElement('div');
      mapPoint.className = 'map-point';
      mapPoint.setAttribute('data-after', key);

      const circleWrapper = document.createElement('div');
      circleWrapper.className = 'circle-wrapper';
      if (this.yearsTable[key] > 1)
        circleWrapper.style.transform = 'translateX(calc(-50% + 2px))';

      for (let i = 0; i < this.yearsTable[key]; i++) {
        const circle = document.createElement('div');
        circle.className = 'circle';
        circleWrapper.appendChild(circle);
      }

      mapPoint.appendChild(circleWrapper);

      timelineMapPoints.appendChild(mapPoint);
    }
  }

  private getDistanceBetweenPoints() {
    if (!this.pointsElements.length) return 0;
    const aRect = this.pointsElements[0].getBoundingClientRect();
    const bRect = this.pointsElements[1].getBoundingClientRect();
    return bRect.left - aRect.right;
  }
}
