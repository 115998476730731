let moreBlocks: NodeListOf<HTMLElement>;
let timeoutCatcher: ReturnType<typeof setTimeout>;

const checkContentHeight = (): void => {
  moreBlocks.forEach((block) => {
    const contentBlock: HTMLElement = block.querySelector('.more-block__text');

    block.classList.toggle('more-block_flat', !(contentBlock && contentBlock.offsetHeight > 96));
  });
};

const toggleBlock = (toggleBtn: HTMLButtonElement) => {
  const parentItem = toggleBtn.closest('.more-block');

  if (parentItem) {
    parentItem.classList.toggle('more-block_open');
  }
};

const catchButton = (e: Event): void => {
  const target = e.target as HTMLButtonElement;

  if (target.classList.contains('more-block__toggle')) {
    e.preventDefault();

    toggleBlock(target);
  }
};

const recalculateBlocksHeight = (): void => {
  clearTimeout(timeoutCatcher);

  timeoutCatcher = setTimeout(checkContentHeight, 300);
};

const events = (): void => {
  document.addEventListener('click', catchButton);
  window.addEventListener('resize', recalculateBlocksHeight);
};

const init = (): void => {
  moreBlocks = document.querySelectorAll('.more-block');

  if (moreBlocks.length > 0) {
    checkContentHeight();
    events();
  }
};

export default  {
  init,
};
