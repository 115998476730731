import React, { FC, useState } from 'react';
import cn from 'classnames';

import __ from '../../../../utils/translation';

import api from '../../../../api';
import Checkbox from '../../../denso-catalog/components/checkbox';

const AuthLogin: FC<{
  isFlat?: boolean;
  handleModalChange: (string) => void;
}> = ({
  isFlat,
  handleModalChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [formValues, setFormValues] = useState({
    loginName: '',
    password: '',
    remember: false,
  });
  const isAvailableSend = formValues.loginName.replace(/ /g, '').length > 0
        && formValues.password.replace(/ /g, '').length > 0;

  const handleChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };
  const handleChangeClick = (modalName: string) => () => handleModalChange(modalName);
  const handleSubmit = (e: React.FormEvent) => {
    const formData = new FormData(e.target as HTMLFormElement);

    e.preventDefault();

    if (isLoading || !isAvailableSend) {
      return false;
    }

    setIsLoading(true);
    api
      .auth
      .login(formData)
      .then(res => res.json())
      .then((res) => {
        if (res.success) {
          localStorage.removeItem('denso-user-role');
          localStorage.setItem('denso-current-user', JSON.stringify(res.customData));
          window.location.replace('/account');
        }

        if (res.error) {
          setAuthError(res.error);
        }
      })
      .catch(err => console.error(err))
      .then(() => setIsLoading(false));
  };

  return (
    <>
      {
        !isFlat
          ? <h2 className="heading heading_h2">{ __('Login') }</h2>
          : null
      }

      {
        authError
          ? <div className="auth__error">
                <div className="alert-box">{authError}</div>
            </div>
          : null
      }

        <form
            className={cn(
              'auth__form',
              { 'auth__form_loading': isLoading },
            )}
            onSubmit={handleSubmit}
        >
            <div className="auth__form-row">
                <label
                    htmlFor="login-user-email"
                    className="d-search-bar__content-label"
                >{ __('Email') }</label>
                <input
                    id="login-user-email"
                    className="d-search-bar__input"
                    name="loginName"
                    type="email"
                    placeholder={ __('Email') }
                    formNoValidate={true}
                    value={formValues.loginName}
                    onChange={handleChange}
                />
            </div>
            <div className="auth__form-row">
                <label
                    htmlFor="login-user-password"
                    className="d-search-bar__content-label"
                >{ __('Password') }</label>
                <input
                    id="login-user-password"
                    className="d-search-bar__input"
                    name="password"
                    type="password"
                    placeholder={ __('Password') }
                    value={formValues.password}
                    onChange={handleChange}
                />
            </div>
            <div className="auth__form-row">
                <Checkbox
                    name="rememberMe"
                    label={ __('Remember me') }
                    value="1"
                    checked={formValues.remember}
                    onChange={() => {setFormValues({ ...formValues, remember: !formValues.remember });}}
                />
            </div>
            <div className="auth__form-row auth__form-row_flex">
                <button
                    className="button auth__form-login"
                    disabled={!isAvailableSend}
                >{ __('Login') }</button>
                <button
                    className="auth__form-forgot"
                    type="button"
                    onClick={handleChangeClick('forgot')}
                >{ __('Forgot password?') }</button>
            </div>
            <div className="auth__form-row">
                <div className="auth__form-cta">
                    <div className="auth__form-cta-text">{ __('Don’t have an account yet?') }</div>
                    <button
                        className="auth__form-cta-btn"
                        type="button"
                        onClick={handleChangeClick('register')}
                    >{ __('Register here') }</button>
                </div>
            </div>
        </form>
    </>
  );
};

export default AuthLogin;
