import { makeAutoObservable, runInAction } from 'mobx';

import __ from '../../../../utils/translation';

import specificationsListByLang from '../../utils/specificationsListByLang';

const parts = makeAutoObservable({
  currentPart: null,
  setCurrentPart: (part: object) => {
    runInAction(() => {
      parts.currentPart = part;
    });
  },
  get specifications() {
    return specificationsListByLang(parts.currentPart);
  },
  get overview() {
    const overviewRows = {
      ean: __('GTIN/EAN'),
      packing_unit: __('Packing unit'),
      qnty_per_unit: __('Quantity per packing unit'),
      status: __('Status'),
    };

    return Object.keys(overviewRows)
      .map((key, index) => {
        if (parts.currentPart[key]) {
          return {
            id: `overview-row-${index}`,
            label: overviewRows[key],
            value: parts.currentPart[key],
          };
        }

        return null;
      })
      .filter(Boolean);
  },
  get productInformation() {
    if (!parts.currentPart.description) {
      return [];
    }

    return [{
      id: 'product-information-row',
      label: __('Additional information'),
      value: parts.currentPart.description,
    }];
  },
  get otherInfo() {
    return parts.currentPart.info.map(({ title, val }, index) => ({
      id: `info-row-${index}`,
      label: title,
      value: val,
    }));
  },
  list: [],
  setList: (list = []) => {
    runInAction(() => {
      parts.list = list;
    });
  },
  total: 0,
  setTotal: (total: number) => {
    runInAction(() => {
      parts.total = total;
    });
  },
});

export default parts;
