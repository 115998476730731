const titleByLang = (titles, langId, prefix?): string => {
  if (titles?.length === 0) {
    return '';
  }

  const titleObj = titles.find(title => title.lang_tid === langId);

  if (titleByLang) {
    return `${prefix || ''}${titleObj.val}`;
  }

  return '';
};

export default titleByLang;
