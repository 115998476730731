import { makeAutoObservable, runInAction } from 'mobx';
import MapModel from './model';

const storeMap = makeAutoObservable<MapModel>({
  instance: null,
  clusterer: null,
  isLoading: false,
  setClusterer(clusterer) {
    runInAction(() => {
      storeMap.clusterer = clusterer;
    });
  },
  setInstance(instance) {
    runInAction(() => {
      storeMap.instance = instance;
    });
  },
  setLoading(isLoading){
    runInAction(() =>{
      storeMap.isLoading = isLoading;
    });
  },
});

export default storeMap;
