import React, {
  FC,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
} from 'react';

import { GlobalSearchItem } from './model';

import api from '../../api';
import Icon from '../denso-catalog/components/icon';
import SearchResultParts from './components/result-parts';
import SearchResultOther from './components/result-other';
import __ from '../../utils/translation';

let debounce = null;

let abortController: AbortController;
let abortSignal: AbortSignal;

const App: FC = () => {
  const searchInput = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [resultList, setResultList] = useState<{
    parts?: GlobalSearchItem[];
    products?: GlobalSearchItem[];
    other?: GlobalSearchItem[];
  }>({});
  const isEmptyResult = useMemo(() => Object.keys(resultList).every(item => resultList[item].length === 0), [resultList]);
  const resultText = isSearching
    ? __('Searching...')
    : __('No matches found');

  const getSearchData = () => {
    api
      .altSearch
      .findGlobal(searchString.trim(), abortSignal)
      .then(res => res.json())
      .then((res) => {
        if (res.data) {
          const isEmptyResult = Object.keys(res.data).every(item => res.data[item].length === 0);

          setResultList(isEmptyResult ? {} : res.data);
        }
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
        }
      })
      .then(() => {
        setIsSearching(false);
      });
  };

  const catchSearchObserve = useCallback(([{ isIntersecting, target }]) => {
    if (!isIntersecting) {
      target.blur();
    }
  }, [searchInput]);

  useEffect(() => {
    const intersectionOptions = {
      rootMargin: '-20px 0px 0px 0px',
    };
    const visibleObserver = new IntersectionObserver(catchSearchObserve, intersectionOptions);

    if (searchInput.current) {
      visibleObserver.observe(searchInput.current);
    }

    return () => {
      visibleObserver.disconnect();
    };
  }, [searchInput]);

  useEffect(() => {
    if (searchString.trim().length > 0) {
      setIsSearching(true);

      // Clear timeout and abort signal
      if (abortController) {
        abortController.abort();
      }
      clearTimeout(debounce);

      // Create new signal with timeout
      abortController = new AbortController();
      abortSignal = abortController.signal;
      debounce = setTimeout(getSearchData, 500);
    }
  }, [searchString]);

  const handleClear = () => setSearchString('');

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    // Clear search input
    if (e.code === 'Escape') {
      handleClear();
    }

    // Choose first item from list
    if (e.code === 'Enter' && !isSearching && !isEmptyResult) {
      window.location.href = [
        ...resultList.parts,
        ...resultList.products,
        ...resultList.other,
      ][0].url;
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value);

  const handleFocus = (isFocus = false) => () => setIsFocus(isFocus);

  const handleBlur = (e: FocusEvent) => {
    const fromTarget = e.relatedTarget as HTMLInputElement;
    const isLinkClick = fromTarget !== null
      && (
        fromTarget.classList.contains('global-search__other-link')
          || fromTarget.classList.contains('global-search__part-link')
      );

    if (isLinkClick) {
      e.preventDefault();

      return;
    }

    handleFocus()();
  };

  return (
    <div className="global-search__form">
      <Icon
        name="search"
        className="global-search__icon"
      />
      <input
        className="global-search__field"
        type="text"
        placeholder={__('Search for parts among 100.000 products')}
        aria-label={__('Search for parts')}
        name="search"
        autoCapitalize="off"
        autoComplete="off"
        value={searchString}
        onChange={handleChange}
        onFocus={handleFocus(true)}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        ref={searchInput}
      />

      {
        // Mobile clear button
        searchString.trim().length > 0
          ? <button
            type="button"
            className="global-search__clear"
            onClick={handleClear}
          >
            <Icon
              name="cross"
              className="global-search__clear-icon"
            />
          </button>
          : null
      }

      {
        isFocus && searchString.trim().length > 0
          ? <div className="global-search__modal">
            {
              isSearching || isEmptyResult
                ? <div className="global-search__modal-text">{ resultText }</div>
                : <ul className="global-search__modal-list">
                  <SearchResultParts resultList={resultList.parts} />
                  <SearchResultParts keyName="products" resultList={resultList.products} />
                  <SearchResultOther resultList={resultList.other} />
                </ul>
            }
          </div>
          : null
      }
    </div>
  );
};

export default App;
