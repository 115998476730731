import React, { FC, ReactNode, useState, useEffect, useCallback, memo } from 'react';
import ReactDOM from 'react-dom';
import Icon from '../icon';

const Modal: FC<{
  children?: ReactNode,
  className?: string,
  onClose?: () => void,
  isImage?: Boolean,
}> = ({
  children,
  className,
  onClose,
  isImage = false,
}) => {
  const [modalRoot] = useState(document.createElement('div'));
  const modalMarkup = (
      <>
        <div
          className="denso-modal__bg"
          onClick={onClose}
        />
        <div className="denso-modal__content">
          <div className="denso-modal__close">
            <button
              type="button"
              className="close-button denso-modal__close-btn"
              onClick={onClose}
            >
              <Icon
                name="cross"
                className="close-button__icon"
              />
            </button>
          </div>
          <div className="denso-modal__content-inner">{ children }</div>
        </div>
      </>
  );

  const catchESC = useCallback((e) => {
    if (e.code === 'Escape') {
      onClose();
    }
  }, []);

  useEffect(() => {
    modalRoot.className = `denso-modal denso-modal_scrollable${ isImage ? ' denso-modal_image' : ''}`;

    if (className) {
      modalRoot.classList.add(className);
    }

    document.body.appendChild(modalRoot);
    document.addEventListener('keyup', catchESC);
    document.documentElement.style.paddingRight = `${window.innerWidth - document.documentElement.offsetWidth}px`;
    document.documentElement.classList.add('is-fixed');

    return () => {
      document.body.removeChild(modalRoot);
      document.removeEventListener('keyup', catchESC);
      document.documentElement.style.paddingRight = null;
      document.documentElement.classList.remove('is-fixed');
    };
  }, []);

  return ReactDOM.createPortal(modalMarkup, modalRoot);
};

export default memo(Modal);
