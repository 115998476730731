import React, { FC, useState } from 'react';
import cn from 'classnames';

import __ from '../../../../utils/translation';

import api from '../../../../api';
import Radio from '../../../denso-catalog/components/radio';

import { AVAILABLE_USER_GROUPS } from '../../constants';

const AuthRegister: FC<{
  isFlat?: boolean;
  handleModalChange: (string) => void;
}> = ({
  isFlat,
  handleModalChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const [errors, setErrors] = useState<Record<string, string[]>>(
    {},
  );
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    userType: AVAILABLE_USER_GROUPS[0].handle,
  });
  const isAvailableSend =
    formValues.email.replace(/ /g, '').length > 0 &&
    formValues.password.replace(/ /g, '').length > 0;

  const handleChangeClick = (modalName: string) => () =>
    handleModalChange(modalName);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e: React.FormEvent) => {
    const formData = new FormData(e.target as HTMLFormElement);

    e.preventDefault();

    if (isLoading || !isAvailableSend) {
      return false;
    }

    setIsLoading(true);
    api.auth
      .register(formData)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          setIsSended(true);
          setErrors({});
        }

        if (res.errors) {
          setErrors(res.errors);
        }
      })
      .catch(err => console.error(err))
      .then(() => setIsLoading(false));
  };

  return (
    <>
      {
        !isFlat
          ? <h2 className="heading heading_h2">{ __('Register') }</h2>
          : null
      }
      <div className="auth__descr">{ __('Create an account within 2 minutes.') }</div>

      {isSended ? (
        <>
          <div className="auth__success-text">
            { __('An email with activation account link has been sent to the') }{' '}
            <strong>{formValues.email}</strong>
          </div>
          <div className="auth__form">
            <div className="auth__form-row">
              <div className="auth__form-cta">
                <div className="auth__form-cta-text">
                  { __('Is your account already activated?') }
                </div>
                <button
                  className="auth__form-cta-btn"
                  type="button"
                  onClick={handleChangeClick('login')}
                >
                  { __('Log in') }
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <form
          className={cn('auth__form', { auth__form_loading: isLoading })}
          onSubmit={handleSubmit}
        >
          <div className="auth__form-row">
            <label className="d-search-bar__content-label">{ __('User type') }</label>
            <div className="auth__radio-list">
              {AVAILABLE_USER_GROUPS.map(group => (
                <div
                  key={`user-group-${group.handle}`}
                  className="auth__radio-list-item"
                >
                  <Radio
                    name="group"
                    label={group.label}
                    value={group.handle}
                    checked={formValues.userType === group.handle}
                    onChange={e => {
                      setFormValues({
                        ...formValues,
                        userType: e.target.value,
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="auth__form-row">
            <label className="d-search-bar__content-label">{ __('Email') }</label>
            <input
              className={cn('d-search-bar__input', {
                'd-search-bar__input_error': errors.hasOwnProperty('email'),
              })}
              name="email"
              type="email"
              placeholder={ __('Email') }
              value={formValues.email}
              onChange={handleChange}
            />

            {errors.hasOwnProperty('email') ? (
              <div className="auth__form-row-error">
                {errors.email.join('\n')}
              </div>
            ) : null}
          </div>
          <div className="auth__form-row">
            <label className="d-search-bar__content-label">{ __('Password') }</label>
            <input
              className={cn('d-search-bar__input', {
                'd-search-bar__input_error': errors.hasOwnProperty('password'),
              })}
              name="password"
              type="password"
              placeholder={ __('Password') }
              value={formValues.password}
              onChange={handleChange}
            />

            {errors.hasOwnProperty('password') ? (
              <div className="auth__form-row-error">
                {errors.password.join('\n')}
              </div>
            ) : null}
          </div>
          <div className="auth__form-row auth__form-row_flex">
            <button
              className="button auth__form-login"
              disabled={!isAvailableSend}
            >
              { __('Register') }
            </button>
          </div>
          <div className="auth__form-row">
            <div className="auth__form-cta">
              <div className="auth__form-cta-text">
                { __('Already have an account?') }
              </div>
              <button
                className="auth__form-cta-btn"
                type="button"
                onClick={handleChangeClick('login')}
              >
                { __('Log in') }
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default AuthRegister;
