import { ClusterIconStyle } from '@googlemaps/markerclustererplus';

export enum ClusterColors {
  'empty',
  'rgb',
  'rb',
  'gb',
  'rg',
  'r',
  'g',
  'b',
}

export const getClusterStyles: () => ClusterIconStyle[] = () => {
  const defaultParams: ClusterIconStyle = { height: 48, width: 48 };
  const defaultClass = 'denso-marker-cluster';

  return Object.values(ClusterColors)
    .filter(v => typeof v === 'string')
    .map(color => ({ ...defaultParams, className: `${defaultClass} ${defaultClass}_${color}` }));
};