import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';
import refreshVehicleStore from '../../../d-search-bar/utils/refresh-store';

import __ from '../../../../utils/translation';

import SaveVehicle from '../save-vehicle';
import breadcrumbs from '../../store/breadcrumbs';
import storeCarTypes from '../../../d-search-bar/store/car-types';
import storeVehicleTypes from '../../../d-search-bar/store/vehicle-type';
import { useHistory } from 'react-router-dom';
import filter from '../../store/filter';

enum VehicleFields {
  'Make',
  'Model',
  'Year',
  'Body',
  'Volume',
  'Type',
}

const CurrentCar: FC = () => {
  const history = useHistory();

  if (!storeCurrentVehicle.current) {
    return null;
  }
  const currentCarFields = [
    storeCurrentVehicle.current.brand,
    storeCurrentVehicle.current.model,
    storeCurrentVehicle.current.year,
    storeCurrentVehicle.current.body,
    storeCurrentVehicle.current.volume,
    storeCurrentVehicle.current.type,
  ].filter((item) => {
    const trimmedItem = item.toString().trim();

    return trimmedItem && trimmedItem !== '0';
  });

  const handleEdit = () => {
    storeCurrentVehicle.setCurrent(null);
  };

  const handleRemove = () => {
    refreshVehicleStore('brand', null);
    storeCurrentVehicle.setCurrent(null);

    if (breadcrumbs.list.length > 1) {
      filter.resetInitialLevels();
      history.push(`/${storeCarTypes.current?.slug ? `${storeCarTypes.current.slug}/` : ''}${storeVehicleTypes.current?.key || ''}`);
    }
  };

  return (
    <div className="page-fmp__vehicle">
      <div className="wrapper">
        <div className="page-fmp__vehicle-inner">
          {/*
                      * I hope the picture still appears
                      * (┛ಠ_ಠ)┛彡┻━┻

                    <div className="page-fmp__vehicle-media">
                        {
                            storeCurrentVehicle.current?.image
                                ? <img
                                    src={storeCurrentVehicle.current.image.src}
                                    className="page-fmp__vehicle-media-img"
                                />
                                : <div className="page-fmp__vehicle-media-empty">
                                    <Icon
                                       name="car"
                                       className="page-fmp__vehicle-media-icon"
                                    />
                                    <div className="page-fmp__vehicle-media-text">No image available</div>
                                </div>
                        }
                    </div>
                    */}
          <div className="page-fmp__vehicle-content">
            <h2 className="heading heading_h2 page-fmp__vehicle-title">
              {currentCarFields.join(' ')}
            </h2>
            <div className="page-fmp__vehicle-tags">
              <div className="page-fmp__vehicle-tags-inner">
                {currentCarFields.map((tag, index) => (
                  <div
                    key={`car-tag-${index}`}
                    className="page-fmp__vehicle-tags-item"
                    title={ __(VehicleFields[index]) }
                  >
                    {tag}
                  </div>
                ))}
              </div>
            </div>
            <div className="page-fmp__vehicle-actions">
              <div className="page-fmp__vehicle-actions-save">
                <SaveVehicle />
              </div>
              <div className="page-fmp__vehicle-actions-list">
                <button
                  type="button"
                  className="page-fmp__vehicle-link"
                  onClick={handleEdit}
                >{ __('Edit vehicle') }</button>
                <button
                  type="button"
                  className="page-fmp__vehicle-link page-fmp__vehicle-link_remove"
                  onClick={handleRemove}
                >{ __('Remove') }</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CurrentCar);
