import { makeAutoObservable, runInAction } from 'mobx';

import Filter from './model';

const filter = makeAutoObservable<Filter>({
  currentCategory: null,
  initialLevels: [],
  setInitialLevels: levels => {
    runInAction(() => {
      if (levels.filter(Boolean).length > 0) {
        filter.initialLevels = levels;
      }
    });
  },
  resetInitialLevels: () => {
    runInAction(() => {
      filter.initialLevels = [];
    });
  },
  levels: [],
  setCurrentCategory: activeCategories => {
    runInAction(() => {
      if (activeCategories.length === 0) {
        filter.levels = [];
        filter.currentCategory = null;
        return;
      }

      const flatten = arr => {
        return arr.reduce((acc, curr) => {
          const element = curr;
          let children = [];

          if (curr.children && curr.children.length) {
            children = flatten(curr.children);
          }

          return [...acc, element, ...children];
        }, []);
      };
      const flatCats = flatten(filter.categories);

      filter.levels = activeCategories.map(cat =>
        flatCats.find(item => item.slug === cat),
      );
      filter.currentCategory = filter.levels[filter.levels.length - 1];
    });
  },
  categories: [],
  setCategoryList: (list = []) => {
    runInAction(() => {
      filter.categories = list;
    });
  },
  get currentCategories() {
    if (filter.currentCategory) {
      return filter.currentCategory.children;
    }

    return filter.categories;
  },
  criteria: [],
  setCriteria: (list = []) => {
    runInAction(() => {
      filter.criteria = list;
    });
  },
  criteriaFilter: [],
  setCriteriaFilter: (list = []) => {
    runInAction(() => {
      filter.criteriaFilter = list;
    });
  },
  // clearCriteriaFilter: () => {
  //     runInAction(() => {
  //         filter.criteriaFilter = [];
  //     });
  // },
});

export default filter;
