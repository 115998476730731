import React, { FC, useState } from 'react';
import cn from 'classnames';

import __ from '../../../../../../utils/translation';

import { UserVehicle } from '../../model';

import storeUserAccount from '../../../../../auth/store/user-account';
import api from '../../../../../../api';

import UserVehicleSelect from '../user-vehicle-select';

const UserVehiclesItem: FC<{
  title: string,
  vehicleId: number,
  vehicleType: number,
}> = ({
  title,
  vehicleId,
  vehicleType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState(null);

  const handleSave = (vehicleData: UserVehicle): void => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    api
      .account
      .saveVehicle(storeUserAccount.userId, vehicleData)
      .then(res => res.json())
      .then((res) => {
        if (res && res.status === 'success') {
          setCurrentVehicle(null);
          storeUserAccount.setUserVehicles(res.data);
        }
      })
      .catch(err => console.error(err))
      .then(() => setIsLoading(false));
  };

  const handleCancelEdit = () => setCurrentVehicle(null);

  const handleEdit = (): void => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    api
      .account
      .editVehicle(storeUserAccount.userId, vehicleId, vehicleType)
      .then(res => res.json())
      .then((res) => {
        if (res.data) {
          setCurrentVehicle(res.data);
        }
      })
      .catch(err => console.error(err))
      .then(() => setIsLoading(false));
  };

  const handleRemove = (): void => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    api
      .account
      .removeVehicle(storeUserAccount.userId, vehicleId, vehicleType)
      .then(res => res.json())
      .then((res) => {
        if (res && res.status === 'success') {
          const filteredVehicles = storeUserAccount.userVehicles.filter(vehicle => vehicle.id !== vehicleId);

          storeUserAccount.setUserVehicles(filteredVehicles);
        }
      })
      .catch(err => console.error(err))
      .then(() => setIsLoading(false));
  };

  if (currentVehicle) {
    return (
      <div className={cn(
        'user-account__vehicles-item',
        {'user-account__vehicles-item_loading': isLoading},
      )}>
        <UserVehicleSelect
          name={title}
          carType={vehicleType}
          initialVehicle={currentVehicle}
          onSave={handleSave}
          onCancel={handleCancelEdit}
        />
      </div>
    )
  }

  return (
    <div className={cn(
      'user-account__vehicles-item',
      {'user-account__vehicles-item_loading': isLoading},
    )}>
      <h4 className="heading heading_h4 user-account__vehicles-item-title">{ title }</h4>
      <div className="user-account__vehicles-action">
        <button
          className="user-account__vehicles-btn"
          type="button"
          onClick={handleEdit}
        >{ __('Edit') }</button>
        <button
          className="user-account__vehicles-btn user-account__vehicles-btn_remove"
          type="button"
          onClick={handleRemove}
        >{ __('Remove') }</button>
      </div>
    </div>
  );
};

export default UserVehiclesItem;
