import * as React from 'react';
import cn from 'classnames';

import { CarType } from '../../store/car-types/model';

const Tab: React.FC<{
  isActive?: Boolean;
  value: CarType;
  onClick: (newCarType: CarType) => void;
}> = ({ children, isActive = false, value, onClick }) => {
  const handleClick = (selectedTab: CarType) => (
    e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();

    onClick(selectedTab);
  };

  return (
    <button
      className={cn('d-search-bar__tab', {
        'd-search-bar__tab_active': isActive,
      })}
      onClick={handleClick(value)}
    >
      {children}
    </button>
  );
};

export default Tab;
