import React, { FC, useState } from 'react';
import cn from 'classnames';

import api from '../../../../api';
import __ from '../../../../utils/translation';

const AuthForgot: FC<{
  isFlat?: boolean;
  handleModalChange: (string) => void;
}> = ({
  isFlat,
  handleModalChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [resetEmail, setResetEmail] = useState('');
  const isAvailableSend = resetEmail.replace(/ /g, '').length > 0;

  const handleChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    setResetEmail(target.value);
  };
  const handleChangeClick = (modalName: string) => () => handleModalChange(modalName);
  const handleSubmit = (e: React.FormEvent) => {
    const formData = new FormData(e.target as HTMLFormElement);

    e.preventDefault();

    if (isLoading || !isAvailableSend) {
      return false;
    }

    setIsLoading(true);
    api
      .auth
      .resetPassword(formData)
      .then(res => res.json())
      .then((res) => {
        if (res.success) {
          setIsSended(true);
          setAuthError(null);
        }

        if (res.error) {
          setAuthError(res.error);
        }
      })
      .catch(err => console.error(err))
      .then(() => setIsLoading(false));
  };

  return (
        <>
            {
              !isFlat
                ? <h2 className="heading heading_h2">{ __('Forgot password?') }</h2>
                : null
            }
            <div className="auth__descr">{ __('Enter the email address that is associated with your DENSO AM account.') }</div>

            {
                isSended
                  ? <>
                        <div className="auth__success-text">{ __('An email with instructions on how to reset your password has been sent to the') } <strong>{resetEmail}</strong></div>
                        <div className="auth__form">
                            <div className="auth__form-row">
                                <div className="auth__form-cta">
                                    <div className="auth__form-cta-text">{ __('Already remembered or reset your password?') }</div>
                                    <button
                                        className="auth__form-cta-btn"
                                        type="button"
                                        onClick={handleChangeClick('login')}
                                    >{ __('Log in') }</button>
                                </div>
                            </div>
                        </div>
                    </>
                  :
                    <>
                        {
                            authError
                              ? <div className="auth__error">
                                    <div className="alert-box">{authError}</div>
                                </div>
                              : null
                        }

                        <form
                            className={cn(
                              'auth__form',
                              { 'auth__form_loading': isLoading },
                            )}
                            onSubmit={handleSubmit}
                        >
                            <div className="auth__form-row">
                                <label className="d-search-bar__content-label">{ __('Email') }</label>
                                <input
                                    className="d-search-bar__input"
                                    name="loginName"
                                    type="email"
                                    placeholder={ __('Enter your email') }
                                    value={resetEmail}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="auth__form-row auth__form-row_flex">
                                <button
                                    className="button auth__form-login"
                                    disabled={!isAvailableSend}
                                >{ __('Reset password') }</button>
                                <button
                                    className="auth__form-forgot"
                                    type="button"
                                    onClick={handleChangeClick('login')}
                                >{ __('Log in') }</button>
                            </div>
                        </form>
                    </>
            }
        </>
  );
};

export default AuthForgot;
