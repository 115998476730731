const activeClass = 'visit-role_open';
const localStorageName = 'denso-user-role';

let visitRoles;
let activePopup = null;
let isSimple = false;

const redrawData = (): void => {
  const currentUser = JSON.parse(localStorage.getItem('denso-current-user'));
  const roles = JSON.parse(visitRoles[0].dataset.roles);

  if (currentUser) {
    const { userText } = visitRoles[0].dataset;
    const currentRole = roles[currentUser.role];

    visitRoles.forEach((roleBlock) => {
      roleBlock.innerHTML = `<div class="visit-role__text">${ userText } <strong>${ currentRole }</strong></div>`;
    });
  } else {
    const { roleText } = visitRoles[0].dataset;
    const currentRole = localStorage.getItem(localStorageName) || 'drivers';
    const currentRoleText = roles[currentRole] || roles.drivers;

    const emptyBlock = `<div class="visit-role__text">${ roleText }</div>
      <button class="visit-role__btn" type="button">${ currentRoleText }</button>
      <div class="visit-role__dd">
        ${ Object.keys(roles).map((role) => `
              <div class="visit-role__dd-item">
                  <a
                      href="#${ role }"
                      class="visit-role__dd-link${ role === currentRole ? ' visit-role__dd-link_active' : '' }"
                  >${ roles[role] }</a>
              </div>
        `).join('') }
      </div>`;

    visitRoles.forEach((roleBlock) => {
      roleBlock.innerHTML = emptyBlock;
    });
  }
};

const clearPopup = (): void => {
  activePopup.classList.remove(activeClass);
  activePopup = null;
};

const setActiveMenuItem = (role: string): void => {
  localStorage.setItem(localStorageName, role);

  visitRoles.forEach((roleBlock) => {
    const btn = roleBlock.querySelector('.visit-role__btn');
    const list = roleBlock.querySelectorAll('.visit-role__dd-link');

    list.forEach((listItem) => {
      const listItemRole = listItem.hash.substr(1);
      const isActive = listItem.classList.toggle('visit-role__dd-link_active', role === listItemRole);

      if (isActive) {
        btn.textContent = listItem.textContent;
      }
    });
  });
};

const setGA = (role: string) => {
  const { ga } = window as any;
  if (typeof ga === 'function') {
    ga('set', 'user-role', role);
  }
};

const catchVisitRoles = (e: Event): void => {
  const target = e.target as HTMLElement;

  // Catch button click
  if (target.classList.contains('visit-role__btn')) {
    const { parentElement } = target;
    const isPopupOpen = parentElement.classList.toggle(activeClass);

    if (activePopup && !parentElement.isSameNode(activePopup)) {
      clearPopup();
    }

    activePopup = isPopupOpen ? parentElement : null;

    return;
  }

  // Catch link click
  if (target.classList.contains('visit-role__dd-link')) {
    const nextRole = (target as HTMLAnchorElement).hash.substr(1);

    e.preventDefault();

    setGA(nextRole);
    setActiveMenuItem(nextRole);
  }

  if (activePopup) {
    clearPopup();
  }
};

const events = (): void => {
  // Don't add listener if just text for authorised user
  if (isSimple) {
    return;
  }

  document.addEventListener('click', catchVisitRoles);
};

const checkInitialValue = (): void => {
  const currentValue = localStorage.getItem(localStorageName);
  const { userRole } = visitRoles[0].dataset;

  if (userRole) {
    isSimple = true;
    setGA(userRole);
  } else if (currentValue) {
    setActiveMenuItem(currentValue);
  }
};

const init = (): void => {
  visitRoles = document.querySelectorAll('.visit-role');

  if (visitRoles.length) {
    checkInitialValue();
    events();
  }
};

export default {
  init,
  redrawData,
};
