import { Store } from '../../store/stores/model';
import { getShopCustomerType } from '../shops';

export function generateMarker(store: Store) {
  const FILTER_ICON_MAP = {
    0: `
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <g transform="translate(-24 -310)" fill="none" fill-rule="evenodd">
              <path d="M24 310h24v24H24z" />
              <path fill="#FFFFFF" d="M27 329.72V331a1 1 0 001 1h1a1 1 0 001-1v-1h12v1a1 1 0 001 1h1a1 1 0 001-1v-1.28c.62-.35 1-1 1-1.72v-3a2 2 0 00-1.23-1.84l-1.37-4.1a3 3 0 00-2.84-2.06h-9.12a3 3 0 00-2.84 2.05l-1.37 4.1A2 2 0 0026 325v3a2 2 0 001 1.72zm2.5-1.72a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm13 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-11.06-9h9.12a1 1 0 01.95.68l1.1 3.32H29.4l1.1-3.32a1 1 0 01.95-.68z" />
              <path fill="#FFFFFF" d="M46 317.39v-2.11l-9.68-3.23a1 1 0 00-.66.01L26 315.57v2.13l10.02-3.64 9.98 3.33z" />
            </g>
          </svg>`,
    1: `
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <g transform="translate(-24 -375)" fill="none" fill-rule="evenodd">
              <path d="M24 375h24v24H24z" />
              <path fill="#FFFFFF" d="M28.91 378c-.74 0-1.36.47-1.59 1.12l-2.32 6.7v8.94c0 .62.5 1.12 1.12 1.12h1.11c.62 0 1.12-.5 1.12-1.11v-1.12h5.93a7.82 7.82 0 013.48-8.94H27.22l1.68-5.03h12.3l1.31 3.94c.69.07 1.36.24 2 .48l-1.73-4.98a1.68 1.68 0 00-1.58-1.12H28.9m11.74 7.82a.3.3 0 00-.3.24l-.2 1.47c-.34.15-.67.33-.96.53l-1.38-.56c-.13 0-.27 0-.35.14l-1.12 1.94a.28.28 0 00.07.36l1.19.91c-.05.37-.05.75 0 1.12l-1.19.92a.3.3 0 00-.07.36l1.12 1.93c.07.14.21.14.35.14l1.38-.56c.3.2.6.4.95.53l.22 1.48c.02.13.13.23.29.23h2.23c.13 0 .25-.1.27-.23l.21-1.48c.34-.14.64-.32.94-.53l1.38.56c.14 0 .29 0 .37-.14l1.11-1.93a.3.3 0 00-.06-.36l-1.2-.92c.02-.19.05-.37.05-.56 0-.19-.02-.37-.05-.56l1.19-.91a.3.3 0 00.06-.36l-1.12-1.93c-.06-.15-.2-.15-.35-.15l-1.38.56c-.3-.2-.6-.4-.95-.53l-.21-1.47a.26.26 0 00-.26-.24h-2.23m-11.74 1.12a1.68 1.68 0 110 3.35 1.68 1.68 0 010-3.35m12.86 2.8a1.68 1.68 0 110 3.35 1.68 1.68 0 010-3.35z" />
            </g>
          </svg>`,
    2: `
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <g transform="translate(-24 -440)" fill="none" fill-rule="evenodd">
              <path d="M24 440h24v24H24z" />
              <path fill="#FFFFFF" fill-rule="nonzero" d="M44.77 457.63l-7.44-7.44a5.25 5.25 0 00-1.22-5.64 5.4 5.4 0 00-6.05-1.06l3.51 3.51-2.45 2.46-3.6-3.52A5.27 5.27 0 0028.6 452a5.25 5.25 0 005.64 1.23l7.44 7.43c.32.33.81.33 1.14 0l1.88-1.88c.41-.32.41-.9.08-1.14z" />
            </g>
          </svg>`,
  };

  const FILTER_COLOR_MAP = {
    0: '#009CDD',
    1: '#DC0032',
    2: '#3DBD5D',
  };

  const customerType = getShopCustomerType(store);

  return `<div style="
        width: 48px;
        height: 48px;
        background-color: ${FILTER_COLOR_MAP[customerType]};
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.32);
        border: solid 2px #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      "
      >
        ${FILTER_ICON_MAP[customerType]}
      </div>`;
}