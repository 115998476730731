import React, { FC } from 'react';
import cn from 'classnames';

const DropDownItem: FC<{
  selected?: boolean,
  hovered?: boolean,
  title: string,
  value: number,
  index: number,
  onClick?: (itemValue: number) => void,
  onHover?: (activeIndex: number | null) => void,
  onMouseDown?: () => void,
}> = ({
  selected,
  hovered,
  title,
  value,
  index,
  onClick,
  onHover,
  onMouseDown,
}) => {
  const handleClick = (itemValue: number) => () => {
    onClick(itemValue);
  };

  const handleMouseOver = (mouseIndex: number) => () => {
    onHover(mouseIndex > -1 ? mouseIndex : null);
  };

  return (
        <li
            title={title}
            className={cn(
              'd-search-bar__dd-item',
              { 'd-search-bar__dd-item_hovered': hovered },
              { 'd-search-bar__dd-item_selected': selected },
            )}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            onClick={handleClick(value)}
            onMouseDown={onMouseDown}
            onMouseOver={handleMouseOver(index)}
            onMouseOut={handleMouseOver(-1)}
        />
  );
};

export default DropDownItem;
