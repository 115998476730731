import React, { FC } from 'react';

import BannerImage from './banner-image';
import BannerAnchors from './banner-anchors';

const Banner: FC = () => {
  return (
        <div className="page-fmp__content-block page-fmp__content-block_banner">
            <BannerImage />
            <BannerAnchors />
        </div>
  );
};

export default Banner;
