import { makeAutoObservable, runInAction } from 'mobx';

import CarTypes from './model';

import { CAR_TYPES } from '../../constants';

const storeCarTypes = makeAutoObservable<CarTypes>({
  list: CAR_TYPES,
  current: CAR_TYPES[0],
  setCurrent: (newCarType = CAR_TYPES[0]) => {
    runInAction(() => {
      storeCarTypes.current = newCarType;
    });
  },
  setInitialList: list => {
    if (list?.length) {
      runInAction(() => {
        storeCarTypes.list = list;
      });
    }
  },
  get currentName() {
    return storeCarTypes.current?.label;
  },
  get currentId() {
    return storeCarTypes.current?.id;
  },
});

export default storeCarTypes;
