import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

import overall from './store/overall';
import storeOverall from '../denso-catalog/store/overall';

let entryPoint: HTMLElement;

const initApp = (): void => {
  if (entryPoint.dataset.hasOwnProperty('isSearch')) {
    overall.setIsLinkSearch(true);
  }

  if (entryPoint.dataset.hasOwnProperty('isVin')) {
    storeOverall.setIsVINAvailable(true);
  }

  ReactDOM.render(React.createElement(App), entryPoint);
};

const init = (): void => {
  entryPoint = document.querySelector('.d-search-bar');

  if (entryPoint && entryPoint.dataset.hasOwnProperty('single')) {
    initApp();
  }
};

export default {
  init,
};
