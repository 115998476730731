import React, { FC, useState } from 'react';
import cn from 'classnames';

import __ from '../../../../utils/translation';

const ExpandList: FC<{
  list: string[],
}> = ({
  list,
}) => {
  if (list?.length === 0) {
    return null;
  }

  const [isFull, setIsFull] = useState(false);
  const normalizeList = isFull
    ? list
    : list.slice(0, 3);

  const toggleFull = () => setIsFull(!isFull);

  return (
        <div className={cn(
          'expand-list',
          { 'expand-list_full': isFull },
        )}>
            <div className="expand-list__main">
                <div className="expand-list__main-inner">
                    {
                        normalizeList.map((item, key) => (
                            <div
                                key={key}
                                className="expand-list__main-item"
                            >{item}</div>
                        ))
                    }

                    {
                        list.length > 3 && !isFull
                          ? <div className="expand-list__main-item expand-list__main-item_count">+{list.length - 3}</div>
                          : null
                    }
                </div>
            </div>

            {
                list.length > 3
                  ? <div className="expand-list__toggler">
                        <button
                            type="button"
                            className="expand-list__btn"
                            onClick={toggleFull}
                        >
                            <span className="expand-list__btn-icon" />
                            <span className="expand-list__btn-text">{isFull ? __('Show less') : __('Show all') }</span>
                        </button>
                    </div>
                  : null
            }
        </div>
  );
};

export default ExpandList;
