import { Store } from '../store/stores/model';

export function getShopCustomerType(store: Store): number {
  const customerTypes = [
    'customer_type_1',
    'customer_type_2',
    'customer_type_3',
  ];

  const index = customerTypes.findIndex(type => store[type]);

  return index >= 0 ? index : 0;
}

export function getMapsDirectionsLink(store: Store): string {
  const baseUrl = 'https://www.google.com/maps/dir/?api=1&';
  return `${baseUrl}destination=${store.lat},${store.lng}`;
}
