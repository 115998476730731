import __ from '../../../utils/translation';

const overviewPartInfo = (part): Array<{
  id: string | number,
  label: string,
  value: string,
  labelTid?: string,
}> => {
  const overviewRows = {
    ean: __('GTIN/EAN'),
    packing_unit: __('Packing unit'),
    qnty_per_unit: __('Quantity per packing unit'),
    status: __('Status'),
  };

  return Object
    .keys(overviewRows)
    .map((key, index) => {
      if (part[key]) {
        const labelTidIndex = ['status', 'qnty_per_unit'].indexOf(key);

        return {
          id: `overview-row-${index}`,
          label: overviewRows[key],
          labelTid: labelTidIndex >= 0 ? labelTidIndex.toString() : null,
          value: part[key],
        };
      }

      return null;
    })
    .filter(Boolean);
};

export default overviewPartInfo;
