import debounce from 'lodash-es/debounce';
import storeStores from '../../store/stores';
import { getVisibleStores } from '../getVisibleStores';

export function setMapEvents(map: google.maps.Map): void {
  map.addListener(
    'center_changed',
    debounce(() => {
      const viewStores = getVisibleStores(storeStores.globalStoreList, map);
      storeStores.setVisibleStoresList(viewStores);
    }, 500),
  );
}
