import React, { FC, useRef, useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import api from '../../api';

import __ from '../../../../utils/translation';

import filter from '../../store/filter';
import parts from '../../store/parts';
import overall from '../../store/overall';
import { PER_PAGE } from '../../constants';

import { resultText } from '../../utils/declOfNum';
import { useResize } from '../../hooks';

import Chips from '../chips';
import PartsItem from './item';
import Pagination from '../pagination';
import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';
import Icon from '../icon';
import { VehicleType } from '../../utils/vehicleType';

const Parts: FC = () => {
  const isDesktop = useResize();
  const [page, setPage] = useState(0);
  const [filterSearch, setFilterSearch] = useState(null);
  const [isShowViewTooltip, setTsShowViewTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gridView, setGridView] = useState(
    localStorage.getItem('denso-grid-view') || 'full',
  );
  const { pathname, search } = useLocation();
  const { catLevel3, vType } = useParams<{ catLevel3: string, vType: VehicleType }>();
  const gridViewWrapper = React.useRef<HTMLDivElement>(null);

  const normalizeSearch = (): URLSearchParams => {
    const queryUrl = new URLSearchParams(search);

    if (queryUrl.get('page')) {
      queryUrl.delete('page');
    }

    return queryUrl;
  };

  const getParts = (offset = 0) => {
    if (filter.currentCategory) {
      const searchStr = normalizeSearch();
      let searchArr = [];

      searchStr.forEach((value, queryKey) => {
        const [key, type] = queryKey.split(':');
        searchArr.push({
          key: parseInt(key, 10),
          type: Number(type) || 1,
          items: value.split('#,#').map(itemKey => ({ key: itemKey })),
        });
      });

      setIsLoading(true);
      api.parts
        .getList(
          filter.currentCategory.tecdoc,
          offset,
          null,
          searchArr,
          storeCurrentVehicle.current?.id,
          vType,
        )
        .then(res => res.json())
        .then(res => {
          parts.setList(res.data);
          parts.setTotal(res.total);

          // Change grid view based on total result parts
          if (!localStorage.getItem('denso-grid-view')) {
            setGridView(res.total > 2 ? 'compact' : 'full');
          }
        })
        .catch(error => console.error(error))
        .then(() => setIsLoading(false));
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    const target = e.target as HTMLElement;

    if (gridViewWrapper && !gridViewWrapper.current?.contains(target)) {
      setTsShowViewTooltip(false);
    }
  };

  useEffect(() => {
    const searchPage = new URLSearchParams(search).get('page');
    const nextPage = searchPage ? parseInt(searchPage, 10) - 1 : 0;

    setFilterSearch(normalizeSearch().toString());
    setPage(nextPage < 0 ? 0 : nextPage);
    getParts(nextPage * PER_PAGE);
  }, [search, catLevel3]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Scroll To
  const scrollToElement = useRef(null);
  const scrollToY = scrollToElement?.current?.offsetTop - 74 - 16;

  if (!filter.currentCategory) {
    return null;
  }

  const toggleGridViewTooltip = () => setTsShowViewTooltip(!isShowViewTooltip);

  const changeGridView = (grid = 'full') => () => {
    setGridView(grid);
    localStorage.setItem('denso-grid-view', grid);
    setTsShowViewTooltip(false);
  };

  const memoResultText = useMemo(() => resultText(parts.total), [parts.total]);

  return (
    <div className="page-fmp__content-block" ref={scrollToElement}>
      <div className="page-fmp__result">
        <div className="page-fmp__result-top">
          <div className="page-fmp__result-count">
            {
              isLoading
                ? <span className="page-fmp__result-count-loading" />
                : memoResultText
            }
          </div>
          <div className="page-fmp__result-view" ref={gridViewWrapper}>
            <button
              className={cn('page-fmp__result-view-btn', {
                'page-fmp__result-view-btn_active': isShowViewTooltip,
              })}
              type="button"
              onClick={toggleGridViewTooltip}
            >
              <Icon name="gear" className="page-fmp__result-view-icon" />
              <span className="page-fmp__result-view-text">{ __('List density') }</span>
            </button>

            {isShowViewTooltip ? (
              <div className="page-fmp__result-view-tooltip">
                <button
                  className={cn('page-fmp__result-view-toggler', {
                    'page-fmp__result-view-toggler_active':
                      gridView === 'compact',
                  })}
                  type="button"
                  onClick={changeGridView('compact')}
                >
                  <svg
                    className="page-fmp__result-view-toggler-icon"
                    viewBox="0 0 74 31"
                  >
                    <path
                      fillRule="evenodd"
                      d="M73.5.5v30H.5V.5h73zm-1 15.5h-71v13.5h71V16zM10 21v5H5v-5h5zm59 0v5H14v-5h55zm3.5-19.5h-71V15h71V1.5zM10 5v5H5V5h5zm59 0v5H14V5h55z"
                    />
                  </svg>
                  <span className="page-fmp__result-view-toggler-text">
                    { __('Compact') }
                  </span>
                </button>
                <button
                  className={cn('page-fmp__result-view-toggler', {
                    'page-fmp__result-view-toggler_active': gridView === 'full',
                  })}
                  type="button"
                  onClick={changeGridView('full')}
                >
                  <svg
                    className="page-fmp__result-view-toggler-icon"
                    viewBox="0 0 74 31"
                  >
                    <path
                      fillRule="evenodd"
                      d="M73.5.5v30H.5V.5h73zm-1 1h-71v28h71v-28zM26 5v21H5V5h21zm12 16v5h-8v-5h8zm31-8v5H30v-5h39zm0-8v5H30V5h39z"
                    />
                  </svg>
                  <span className="page-fmp__result-view-toggler-text">
                    { __('Full') }
                  </span>
                </button>
              </div>
            ) : null}
          </div>
        </div>

        <div className="page-fmp__result-list">
          <Chips />

          {isLoading ? (
            [...Array(3)].map((item, index) => (
              <div
                key={`skeleton-item-${index}`}
                className="page-fmp__skeleton"
              >
                <div className="page-fmp__skeleton-media" />
                <div className="page-fmp__skeleton-content">
                  <div className="page-fmp__skeleton-content-sub" />
                  <div className="page-fmp__skeleton-content-title" />
                  <div className="page-fmp__skeleton-content-table" />
                </div>
              </div>
            ))
          ) : (
            <div className="parts-list">
              {parts.list?.length > 0
                ? parts.list.map(item => (
                    <PartsItem
                      key={item.name}
                      prefix={ storeCurrentVehicle.currentId ? overall.prefix : null }
                      item={item}
                      filter={filter.criteriaFilter}
                      title={`DENSO ${filter.currentCategory.title}`}
                      langId={overall.langId}
                      isFull={gridView === 'full'}
                      isDesktop={isDesktop}
                    />
                ))
                : null}
            </div>
          )}

          {parts.total > PER_PAGE ? (
            <div className="page-fmp__result-pagination">
              <Pagination
                total={parts.total}
                current={page}
                pathname={pathname}
                filter={filterSearch}
                scrollOnChangeTo={scrollToY}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default observer(Parts);
