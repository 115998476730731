import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import api from '../../../../api';

import storeVehicleBrands from '../../store/vehicle-brands';
import storeVehicleModels from '../../store/vehicle-model';
import storeVehicleYears from '../../store/vehicle-year';
import storeVehicleBodies from '../../store/vehicle-body';
import storeVehicleVolumes from '../../store/vehicle-volume';
import storeVehicleTypes from '../../store/vehicle-type';
import storeCarTypes from '../../store/car-types';
import { VehicleField } from '../../store/vehicle-type/model';

import refreshVehicleStore from '../../utils/refresh-store';

import DropDown from '../dropdown';
import Column from '../column';

const VehicleTypeField: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const selected = storeVehicleTypes.current
    ? storeVehicleTypes.current
    : null;

  const handleChange = (selectedItem: VehicleField) => {
    refreshVehicleStore('type', selectedItem);
  };

  useEffect(() => {
    if (storeVehicleVolumes.current && !storeVehicleTypes.current) {
      api
        .car
        .getTypes(
          storeVehicleBrands.currentId,
          storeVehicleModels.currentId,
          storeVehicleYears.currentId,
          storeVehicleBodies.currentId,
          storeVehicleVolumes.currentId,
          storeCarTypes.currentId,
        )
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            storeVehicleTypes.setList(res.data);

            if (res.data.length === 1) {
              storeVehicleTypes.setCurrent(res.data[0]);
            }
          }

          setIsLoading(false);
        })
        .catch(error => console.error(error));
    }
  }, [storeVehicleVolumes.current]);

  return (
        <Column label={ __('Trim') } type="type">
            <DropDown
                placeholder={ __('Select trim') }
                selected={selected}
                list={storeVehicleTypes.list}
                onChange={handleChange}
                withIncludeSearch
                disabled={!storeVehicleVolumes.current}
                isOpen={storeVehicleVolumes.current && storeVehicleTypes.list?.length > 1 && !isLoading}
            />
        </Column>
  );
};

export default observer(VehicleTypeField);
