import React, { FC, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import storeMap from '../../../../store/map';
import storeUserLocation from '../../../../store/user-location';
import {
  handlePlaceChange,
  initAutocomplete,
} from '../../../../helpers/addressField';
import { getUserLocation } from '../../../../helpers/location';

const AddressField: FC = () => {
  const map = storeMap.instance;
  const input = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  if (map) {
    const autocomplete = initAutocomplete(
      storeUserLocation.position,
      'autocomplete-input',
    );
    autocomplete.addListener('place_changed', () => {
      handlePlaceChange(map, autocomplete);
    });
  }

  function clearAddress() {
    input.current.value = '';
  }

  async function setUserLocation() {
    const location = await getUserLocation();
    if (!location) {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 3000);
      return;
    }
    storeUserLocation.setPosition(location);
    storeMap.instance.setCenter(location);
  }

  const locationIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
      <g transform="translate(-40 -202)" fill="#333" fillRule="nonzero">
        <path d="M48 207.09a2.91 2.91 0 100 5.82 2.91 2.91 0 000-5.82zm6.5 2.18a6.54 6.54 0 00-5.77-5.77V202h-1.46v1.5a6.54 6.54 0 00-5.77 5.77H40v1.46h1.5a6.54 6.54 0 005.77 5.77v1.5h1.46v-1.5a6.54 6.54 0 005.77-5.77H56v-1.46h-1.5zM48 215.1a5.09 5.09 0 110-10.17 5.09 5.09 0 010 10.17z" />
      </g>
    </svg>
  );

  return (
    <div className="where-to-buy__address-field">
      <input
        ref={input}
        type="text"
        id="autocomplete-input"
        placeholder="Enter address..."
      />
      <div
        className="where-to-buy__address-field-icon"
        onClick={setUserLocation}
      >
        {locationIcon}
        <div
          className={`where-to-buy__address-field-tooltip${
            showTooltip ? ' active' : ''
          }`}
        >
          Please enable location permissions in order to go to your location on
          the map.
        </div>
      </div>
      <div onClick={clearAddress} className="where-to-buy__address-field-clear">
        &times;
      </div>
    </div>
  );
};

export default observer(AddressField);
