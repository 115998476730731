import { createContext } from 'react';

const MobileMenuContext = createContext<{
  currentModal: string | null;
  setCurrentModal: (newModalName: string) => void;
  currentMenu: any,
  setCurrentMenu: (newMenu: any) => void,
  mobileMenu: {
    id: string,
    title: string,
    url: string,
    items: {
      title: string,
      url: string,
    }[],
  }[];
}>({
      currentModal: null,
      setCurrentModal: () => {},
      currentMenu: null,
      setCurrentMenu: () => {},
      mobileMenu: [],
    });

export default MobileMenuContext;
