import { makeAutoObservable, runInAction } from 'mobx';

import UserAccount from './model';

const storeUserAccount = makeAutoObservable<UserAccount>({
  userId: null,
  setUserId: userId => {
    runInAction(() => {
      storeUserAccount.userId = userId;
    });
  },
  isCanAddVehicles: false,
  setIsCanAddVehicles: isCanAddVehicles => {
    runInAction(() => {
      storeUserAccount.isCanAddVehicles = isCanAddVehicles;
    });
  },
  userLinks: [],
  setUserLinks: userLinks => {
    runInAction(() => {
      storeUserAccount.userLinks = userLinks;
    });
  },
  selectedVehicle: null,
  setSelectedVehicle: userVehicle => {
    runInAction(() => {
      storeUserAccount.selectedVehicle = userVehicle;
    });
  },
  userVehicles: [],
  setUserVehicles: userVehicles => {
    runInAction(() => {
      storeUserAccount.userVehicles = userVehicles;
    });
  },
});

export default storeUserAccount;
