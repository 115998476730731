import { makeAutoObservable, runInAction } from 'mobx';

import AlternateSearch from './model';

const storeAlternativeSearch = makeAutoObservable<AlternateSearch>({
  current: '',
  setCurrent: newSearchString => {
    runInAction(() => {
      if (storeAlternativeSearch.error) {
        storeAlternativeSearch.setError(null);
      }

      storeAlternativeSearch.current = newSearchString;
    });
  },
  get isAvailable() {
    return storeAlternativeSearch.current.length >= 8;
  },
  error: null,
  setError: newError => {
    runInAction(() => {
      storeAlternativeSearch.error = newError;
    });
  },
});

export default storeAlternativeSearch;
