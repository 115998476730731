import * as React from 'react';
import cn from 'classnames';

const Column: React.FC<{
  label?: string,
  type?: ('or' | 'vin' | 'search' | 'type'),
}> = ({
  children,
  label,
  type,
}) => {
  return (
        <div
            className={cn(
              'd-search-bar__content-col',
              { [`d-search-bar__content-col_${type}`]: type },
            )}
        >
            {
                label
                  ? <div className="d-search-bar__content-label">{label}</div>
                  : null
            }
            {children}
        </div>
  );
};

export default Column;
