import React, { FC } from 'react';

const Icon: FC<{
  name: string,
  className?: string,
}> = ({
  name,
  className,
}) => {
  return (
        <svg className={className}>
            <use xlinkHref={`/dist/images/denso-sprite.svg#${name}`} />
        </svg>
  );
};

export default Icon;
