import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';

const CurrentCarText: FC = () => {
  if (!storeCurrentVehicle.current) {
    return null;
  }

  const currentCarFields = [
    storeCurrentVehicle.current.brand,
    storeCurrentVehicle.current.model,
    storeCurrentVehicle.current.year,
    storeCurrentVehicle.current.body,
    storeCurrentVehicle.current.volume,
    storeCurrentVehicle.current.type,
  ];

  return (
    <div className="page-fmp__content-car">
      {currentCarFields.filter(Boolean).join(' ')}
    </div>
  );
};

export default observer(CurrentCarText);
