let repairStationBtn: HTMLElement;
let anchorElement: HTMLElement;

const handleClick = (e: MouseEvent): void => {
  e.preventDefault();

  if (anchorElement) {
    window.scrollTo({
      top: anchorElement.offsetTop - 74,
      behavior: 'smooth',
    });
  }
};

const events = (): void => {
  repairStationBtn.addEventListener('click', handleClick);
};

const init = (): void => {
  repairStationBtn = document.querySelector('.repair-station__foot-link');
  anchorElement = document.getElementById('automative-parts');

  if (repairStationBtn) {
    events();
  }
};

export default {
  init,
};
