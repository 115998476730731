import { reaction } from 'mobx';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { DEFAULT_ZOOM } from '../constants';
import storeMap from '../store/map';
import storeStores from '../store/stores';
import { clusterCalculator, CustomMarker, CustomOverlay, generateMarker, getClusterStyles } from './map';
import { getShopCustomerType } from './shops';
import { getVisibleStores } from './getVisibleStores';

export function setStoreListener(map: google.maps.Map): void {
  reaction(
    () => storeStores.globalStoreList,
    storesList => {
      if (storeMap.clusterer) storeMap.clusterer.clearMarkers();
      const markers = storesList.map((store) => {
        const marker = new CustomOverlay(
          generateMarker(store),
          store.latlng,
          getShopCustomerType(store),
        );
        marker.addListener('click', () => {
          storeStores.setCurrentStore(store);
        });
        return marker as unknown as  CustomMarker;
      });

      const viewStores = getVisibleStores(storesList, map);
      storeStores.setVisibleStoresList(viewStores);

      if (storeMap.clusterer) {
        storeMap.clusterer.addMarkers(markers);
      } else {
        const markerClusterer = new MarkerClusterer(map, markers, {
          minimumClusterSize: 2,
          maxZoom: DEFAULT_ZOOM,
          styles: getClusterStyles(),
          calculator: clusterCalculator,
        });
        storeMap.setClusterer(markerClusterer);
      }
    },
  );
}
