import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

let entryPoint: HTMLElement;

const initApp = (): void => {
  ReactDOM.render(React.createElement(App), entryPoint);
};

const init = (): void => {
  entryPoint = document.querySelector('.where-to-buy-entry');

  if (entryPoint) {
    initApp();
  }
};

export default {
  init,
};
