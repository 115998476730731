import React, { FC, KeyboardEvent, useEffect } from 'react';

import __ from '../../../../../../utils/translation';

const DropDownSearch: FC<{
  currentSearch: string,
  onChange?: (searchString: string) => void,
  onEnter?: () => void,
}> = ({
  currentSearch = '',
  onChange,
  onEnter,
}) => {
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape' && currentSearch.length !== 0) {
      e.stopPropagation();
      onChange('');
    }

    if (e.key === 'Enter') {
      onEnter();
    }
  };

  useEffect(() => {
    if (searchInputRef) {
      searchInputRef.current.focus();
    }
  });

  return (
        <div className="d-search-bar__dd-search">
            <svg className="d-search-bar__dd-search-icon">
                <use xlinkHref="/dist/images/denso-sprite.svg#search" />
            </svg>
            <input
                className="d-search-bar__dd-search-input"
                type="text"
                placeholder={ __('Search') }
                value={currentSearch}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                ref={searchInputRef}
            />
        </div>
  );
};

export default DropDownSearch;
