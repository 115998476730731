let simplePageWrapper: HTMLElement;

const catchAnchorLink = (e: MouseEvent): void => {
  const target = e.target as HTMLAnchorElement;

  if (target.tagName === 'A' && target.hash.length > 0) {
    const findAnchorOnPage = simplePageWrapper.querySelector(target.hash) as HTMLElement;

    if (findAnchorOnPage) {
      const headerOffset = window.innerWidth >= 1024
        ? 74
        : 128;

      e.preventDefault();

      window.scrollTo({
        top: findAnchorOnPage.offsetTop - headerOffset - 16,
        behavior: 'smooth',
      });
    }
  }
};

const events = (): void => {
  simplePageWrapper.addEventListener('click', catchAnchorLink);
};

const init = (): void => {
  simplePageWrapper = document.querySelector('.simple-page');

  if (simplePageWrapper) {
    events();
  }
};

export default {
  init,
};
