import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import parts from '../../store/parts';
import { useResize } from '../../hooks';

import DetailHider from './hider';
import Specs from '../specs';

const DetailSpecifications: FC = () => {
  const isDesktop = useResize();
  const title = __('Part specifications');
  const specifications = useMemo(() => [
    ...parts.productInformation,
    ...parts.otherInfo,
    ...parts.specifications,
  ], []);

  if (!isDesktop) {
    return (
      <DetailHider title={ title }>
        <Specs
          list={ specifications }
          isBorder
        />
      </DetailHider>
    );
  }

  return (
    <div className="catalog-detail__block" id="part-specifications">
      <div className="catalog-detail__block-title">
        <h2 className="heading heading_h3">{ title }</h2>
      </div>
      <div className="catalog-detail__block-content">
        <Specs
          list={ specifications }
          isBorder
        />
      </div>
    </div>
  );
};

export default observer(DetailSpecifications);
