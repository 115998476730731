import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { FilterCategory } from '../../store/filter/model';
import { resultText } from '../../utils/declOfNum';

import Icon from '../icon';

const Products: FC<{
  list: FilterCategory[];
  prefix?: string;
}> = ({ list = [], prefix = '' }) => {
  if (!list && list.length === 0) {
    return null;
  }

  return (
    <div className="products">
      {list.map(({ id, title, parts, uri, image }) => {

        const link = `/${prefix}${uri}`;

        return (
          <div key={`part-${id}`} className="products__item">
            <div className="card">
              <picture
                className={cn('card__media', { card__media_empty: !image })}
              >
                {image ? (
                  <img
                    className="card__media-img"
                    src={image.src}
                    width={image.width}
                    height={image.height}
                    alt={title}
                    loading="lazy"
                    decoding="async"
                  />
                ) : (
                  <div className="card__media-empty">
                    <Icon
                      name="empty-image"
                      className="card__media-empty-icon"
                    />
                  </div>
                )}
              </picture>
              <div className="card__title">
                <div className="card__title-main">
                  <Link to={{ pathname: link }} className="card__title-link">
                    {title}
                  </Link>
                  <div className="card__title-count">{resultText(parts)}</div>
                </div>
                <div className="card__title-icon">
                  <Icon name="right-arrow" className="card__title-icon-svg" />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Products;
