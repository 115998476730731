import { makeAutoObservable, runInAction } from 'mobx';
import { CurrentFiltersStore } from './model';
import { ShopFilters } from '../../api';

const storeCurrentFilters = makeAutoObservable<CurrentFiltersStore>({
  currentFilters: {
    type_of_sales: [],
    type_of_store: [],
    type_of_location: [],
    subtype_of_store: [],
  },
  setCurrentFilters(filter, alias, isArray = false) {
    runInAction(() => {
      if (isArray) {
        if (
          storeCurrentFilters.currentFilters[alias].find(
            //@ts-ignore
            entry => entry.id === filter.id,
          )
        ) {
          storeCurrentFilters.currentFilters[
            alias
          ] = storeCurrentFilters.currentFilters[alias].filter(
            //@ts-ignore
            entry => entry.id !== filter.id,
          );
        } else {
          storeCurrentFilters.currentFilters[alias].push(filter);
        }
        return;
      }
      storeCurrentFilters.currentFilters[alias] = filter;
    });
  },
  clearFilters() {
    runInAction(() => {
      for (const key in storeCurrentFilters.currentFilters) {
        if (!storeCurrentFilters.currentFilters.hasOwnProperty(key)) continue;
        storeCurrentFilters.currentFilters[key] = [];
      }
    });
  },
  getFormattedFilters(): ShopFilters {
    let formattedFilters = {};
    for (const key in storeCurrentFilters.currentFilters) {
      if (
        !storeCurrentFilters.currentFilters.hasOwnProperty(key) ||
        !storeCurrentFilters.currentFilters[key].length
      )
        continue;
      formattedFilters[key] = storeCurrentFilters.currentFilters[key].map(
        entry => entry.id,
      );
    }
    return formattedFilters;
  },
});

export default storeCurrentFilters;
