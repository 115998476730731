import React, { FC } from 'react';

import __ from '../../../../utils/translation';

import parts from '../../store/parts';

import DetailHider from './hider';
import DetailCarApplicabilityTable from './car-applicability-table';

const DetailApplicableHider: FC = () => {
  if (!(parts.currentPart.cars?.length > 0)) {
    return null;
  }

  const partTitle = `${ __('DENSO part number') }: ${ parts.currentPart.name }`;

  return (<>
    <div id="applicability" />
    <DetailHider title="Applicability">
      <div>{ partTitle } { __('fit on the cars listed below') }</div>

      <DetailCarApplicabilityTable
        list={parts.currentPart.cars}
        isShowAll={false}
      />
    </DetailHider>
  </>);
};

export default DetailApplicableHider;
