import React, {ChangeEvent, FC, FormEvent, useMemo, useState} from 'react';

import { UserVehicle } from '../../model';

import { CAR_TYPES } from '../../../../../d-search-bar/constants';
import DropDown from '../../../../../d-search-bar/components/dropdown';
import UserVehicleChain from '../user-vehicle-chain';

const UserVehicleSelect: FC<{
  name: string,
  carType: number | string,
  initialVehicle: any,
  onSave: (UserVehicle: UserVehicle) => void,
  onCancel: () => void,
}> = ({
  name,
  carType,
  initialVehicle,
  onSave,
  onCancel,
}) => {
  const [currentVehicle, setCurrentVehicle] = useState({
    ...initialVehicle.keys,
    name,
    type: carType,
    isChanged: false,
  });

  const types = useMemo(() => CAR_TYPES.map((carType) => ({
    key: carType.id,
    val: carType.label,
  })), []);

  // Reset all others fields, if vehicle type changed
  const onTypeChange = ({ key }) => setCurrentVehicle({
    type: key,
  });

  const handleChange = (action) => (value) => {
    setCurrentVehicle({
      ...currentVehicle,
      [action]: value.key,
      isChanged: true,
    })
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => handleChange('name')({key: e.target.value});

  const handleSubmit = (e: FormEvent) => {
    const vehicleData = new FormData(e.target as HTMLFormElement);

    e.preventDefault();

    if (currentVehicle.types) {
      onSave({
        name: vehicleData.get('name') as string,
        type: currentVehicle.type,
        id: currentVehicle.types,
        old_id: initialVehicle.keys.types,
      });
    }
  };


  const handleVehicleChange = (newVehicle) => {
    setCurrentVehicle({
      type: currentVehicle.type,
      ...newVehicle,
    });
  };

  return (
    <form
      className="auth__form auth__form_single"
      onSubmit={handleSubmit}
    >
      <div className="auth__form-row">
        <label className="d-search-bar__content-label">Name</label>
        <input
          className="d-search-bar__input"
          name="name"
          type="text"
          placeholder="Vehicle name"
          onChange={onNameChange}
          defaultValue={name}
          autoComplete="off"
        />
      </div>

      <div className="auth__form-row">
        <label className="d-search-bar__content-label">Vehicle</label>
        <DropDown
          placeholder='Select vehicle type'
          selected={types.find(item => item.key.toString() === currentVehicle.type.toString())}
          list={types}
          onChange={onTypeChange}
          isFullWidth
        />
      </div>

      <UserVehicleChain
        initialFilters={initialVehicle.filters}
        currentVehicle={currentVehicle}
        onChange={handleVehicleChange}
      />

      <div className="auth__form-row auth__form-row_flex">
        <button
          className="button user-account__edit-save"
          disabled={!(currentVehicle.isChanged && currentVehicle.types)}
        >Save</button>

        <button
          className="user-account__vehicles-btn"
          type="button"
          onClick={onCancel}
        >Cancel</button>
      </div>
    </form>
  );
};

export default UserVehicleSelect;
