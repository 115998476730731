import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import __ from '../../../../utils/translation';

import api from '../../../../api';

import storeUserAccount from '../../../auth/store/user-account';
import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';
import storeCarTypes from '../../../d-search-bar/store/car-types';

import ArrowLink from '../arrow-link';
import Icon from '../icon';

const SaveVehicle: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveCar = e => {
    e.preventDefault();

    if (isLoading) {
      return false;
    }

    setIsLoading(true);
    api
      .account
      .addVehicle(
        storeUserAccount.userId,
        storeCurrentVehicle.currentId,
        storeCarTypes.currentId,
      )
      .then(res => res.json())
      .then((res) => {
        if (res && res.data?.length > 0) {
          const normalizeVehicles = res.data.map(({id, name, type}) => ({
            id,
            name,
            type,
          }));

          storeUserAccount.setUserVehicles(normalizeVehicles);
        }

        // Catch error about user permission or elastic search
        if (res.status === 'error') {
          console.error('Error while saving car:', res.message);
        }
      })
      .catch(error => console.error(error))
      .then(() => setIsLoading(false));
  };

  if (!storeUserAccount.isCanAddVehicles) {
    return null;
  }

  if (storeUserAccount.userVehicles.length > 0 && storeUserAccount.userVehicles.some((item) => item.id == storeCurrentVehicle.currentId)) {
    return (
      <div className="page-fmp__vehicle-saved">
        <Icon
          name="check"
          className="page-fmp__vehicle-saved-icon"
        />
        <div className="page-fmp__vehicle-saved-text">
          { __('This vehicle is saved in your') }{' '}
          <a href="/account#vehicles" target="_blank">{ __('account') }</a>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(
      'page-fmp__vehicle-save',
      {'page-fmp__vehicle-save_loading': isLoading},
    )}>
      <ArrowLink
        title={isLoading ? __('Saving...') : __('Save as my vehicle')}
        link="#"
        onClick={handleSaveCar}
      />
    </div>
  );
};

export default observer(SaveVehicle);
