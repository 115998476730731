import React, { FC } from 'react';

import overall from '../../store/overall';
import parts from '../../store/parts';

import titleByLang from '../../utils/titleByLang';

import NestingList from '../nesting-list';

const DetailCarApplicabilityTable: FC<{
  list: Array<any>,
  isShowAll: boolean,
}> = ({
  list,
  isShowAll = false,
}) => {
  const generateNestingList = (list) => {
    if (list.items?.length > 0) {
      return list.items.map((item) => (
        <NestingList
          key={`applicable-list-${list.maker_tid}-${item.model_tid}`}
          title={item.title}
          dynamicItem={{
            maker: list.maker_tid,
            model: item.model_tid,
            partName: parts.currentPart.name,
            vehicleType: item.vehicle_type,
          }}
        />
      ));
    }
  };

  if (list?.length === 0) {
    return null;
  }

  return (
    <div className="catalog-detail__applicability-table">
      {
        list.map((item) => {
          const itemTitle = `${item.total} model${item.total > 1 ? 's' : ''}`;

          return (
            <div
              key={item.maker_tid}
              className="catalog-detail__applicability-table-row"
            >
              <div className="catalog-detail__applicability-table-title">
                <div className="catalog-detail__applicability-table-sticky">
                  { titleByLang(item.title, overall.langId) }
                </div>
              </div>
              <div className="catalog-detail__applicability-table-dd">
                <NestingList
                  title={itemTitle}
                  isOpen={isShowAll}
                >
                  {generateNestingList(item)}
                </NestingList>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default DetailCarApplicabilityTable;
