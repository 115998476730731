import { PASS, USERNAME } from '../constants';
import { Store } from '../store/stores/model';

export interface ShopFilters {
  type_of_sales?: number[];
  type_of_store?: number[];
  type_of_location?: number[];
  city?: string;
  subtype_of_store?: string[];
}

export interface FiltersResponse {
  type_of_sales: Record<number, string>;
  type_of_store: Record<number, string>;
  type_of_locations: Record<number, string>;
  product_group: Record<number, string>;
  tooltips: Record<string, unknown>;
  service_types: Record<string, unknown>;
  product_types: Record<string, unknown>;
}

const langPrefix = (window as any).DENSO?.languageHandle ? `/${(window as any).DENSO.languageHandle}` : '';
const BASE_URL = `${langPrefix}/where-to-buy/api/v1`;

const headers = new Headers();
headers.set('content-type', 'application/json');
headers.set('Authorization', 'Basic ' + window.btoa(USERNAME + ':' + PASS));

async function defaultResponseHandler<T = any>(
  response: Response,
): Promise<T | null> {
  const json = await response.json();
  if (json.status !== 'success') {
    throw new Error(`Request failed with status: ${response.status}`);
  }

  return 'data' in json ? json.data : null;
}

export async function getFilters(): Promise<FiltersResponse> {
  try {
    const response = await fetch(`${BASE_URL}/filters`, {
      method: 'get',
      headers,
    });
    return await defaultResponseHandler(response);
  } catch (e) {
    console.error(e);
  }
}

export async function findShops(filters: ShopFilters = {}): Promise<Omit<Store, 'latlng'>[]> {
  try {
    const response = await fetch(`${BASE_URL}/shops`, {
      method: 'post',
      headers,
      body: JSON.stringify(filters),
    });
    return (await defaultResponseHandler(response)) ?? [];
  } catch (e) {
    console.error(e);
  }
}

export async function getFilterSubtype(filterId: number): Promise<string[]> {
  try {
    const response = await fetch(`${BASE_URL}/customer/${filterId}/types`, {
      method: 'get',
      headers,
    });
    return (await defaultResponseHandler(response)) ?? [];
  } catch (e) {
    console.error(e);
  }
}


