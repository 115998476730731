import React, { FC, useState, ChangeEvent, FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import __ from '../../../../utils/translation';

import storeUserForm from '../../store/user-account';

import api from '../../../../api';

const UserForm: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const refreshedArray = storeUserForm.fields.map((field) => {
      if (field.name === e.target.name) {
        return {
          ...field,
          value: e.target.value,
        };
      }

      return field;
    });

    storeUserForm.setFields(refreshedArray);
  };

  if (storeUserForm.fields.length === 0) {
    return null;
  }

  const handleSubmit = (e: FormEvent) => {
    const formData = new FormData(e.target as HTMLFormElement);

    e.preventDefault();

    if (isLoading) {
      return false;
    }

    setIsLoading(true);
    api
      .auth
      .register(formData)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          setErrors({});

          if (res.csrfTokenValue) {
            // Reload page if get new CSRF Token = Password changes successfully
            window.location.reload();
          }
        }

        if (res.errors) {
          setErrors(res.errors);
        }
      })
      .catch(err => console.error(err))
      .then(() => setIsLoading(false));
  };

  return (
    <div className="user-account__form">
      <form
        className={cn(
          'auth__form',
          'auth__form_single',
          { 'auth__form_loading': isLoading },
        )}
        onSubmit={handleSubmit}
      >
        {
          storeUserForm.fields.map(({
            label,
            placeholder,
            required,
            name,
            value,
            type = 'text',
          }) => {
            if (type === 'hidden') {
              return (
                <input
                  key={`user-form-row-${name}`}
                  type="hidden"
                  name={name}
                  value={value}
                />
              );
            }

            return (
              <div key={`user-form-row-${name}`} className="auth__form-row">
                <label className="d-search-bar__content-label">{ label }</label>
                <input
                  className={cn(
                    'd-search-bar__input', {
                    'd-search-bar__input_error': errors.hasOwnProperty(name),
                    })}
                  name={name}
                  type={type}
                  placeholder={placeholder ?? label}
                  required={required}
                  value={value}
                  onChange={handleChange}
                  autoComplete={type === 'password' ? 'new-password' : null}
                />

                {
                  errors.hasOwnProperty(name)
                    ? <div className="auth__form-row-error">
                      { errors[name].join('\n') }
                    </div>
                    : null
                }
              </div>
            );
          })
        }

        <div className="auth__form-row">
          <button className="button">{ __('Save') }</button>
        </div>
      </form>
    </div>
  );
};

export default observer(UserForm);
