import React, { FC } from 'react';

import ArrowLink from '../arrow-link';

const DetailBack: FC<{
  backLink?: string;
}> = ({ backLink = '/' }) => {

  return (
    <div className="catalog-detail__back">
      <ArrowLink
        title="Back"
        link={backLink}
        reverseArrow
      />
    </div>
  );
};

export default DetailBack;
