import categories from './endpoints/categories';
import parts from './endpoints/parts';

const api = {
  categories,
  parts,
};

export const apiRequest: (
  endPoint: string,
  method?: 'GET' | 'POST',
  params?: object,
  requestParams?: object,
) => Promise<Response> = (
  endPoint = '/',
  method = 'POST',
  params,
  requestParams,
): Promise<Response> => {
  const baseURl = '/api/v1';
  const langPrefix = (window as any).DENSO?.languageHandle ? `/${(window as any).DENSO.languageHandle}` : '';
  const body = method === 'POST'
    ? JSON.stringify(params)
    : null;

  return fetch(`${langPrefix}${baseURl}${endPoint}`, {
    method,
    // headers: {
    //     'Content-Type': 'application/json;charset=utf-8',
    // },
    body,
    ...requestParams,
  });
};

export default api;
