import React, {FC, useState, useEffect} from 'react';

import api from '../../../../../../api';

import DropDown from '../../../../../d-search-bar/components/dropdown';

const fieldList = [{
  title: 'Make',
  placeholder: 'Select brand',
  slug: 'makers',
  isSearch: true,
  vt: [1, 2, 3],
}, {
  title: 'Model',
  placeholder: 'Select model',
  slug: 'models',
  isSearch: true,
  vt: [1, 2, 3],
}, {
  title: 'Year',
  placeholder: 'Select year',
  slug: 'years',
  isSearch: true,
  vt: [1, 2],
}, {
  title: 'Body',
  placeholder: 'Body type',
  slug: 'bodies',
  vt: [1],
}, {
  title: 'Engine type',
  placeholder: 'Select engine type',
  slug: 'volumes',
  vt: [1],
}, {
  title: 'Trim',
  placeholder: 'Select trim',
  slug: 'types',
  vt: [1, 2, 3],
}];

const UserVehicleChain: FC<{
  initialFilters: any;
  currentVehicle: any;
  onChange?: (newVehicle: any) => void;
}> = ({
  initialFilters,
  currentVehicle,
  onChange,
}) => {
  const [currentFilter, setCurrentFilter] = useState(initialFilters);

  const isSelectedObject = (existArray, currentValue) => {
    if (!existArray || !currentValue) {
      return null;
    }

    return existArray.find(item => item.key.toString() === currentValue.toString());
  };

  const handleChange = (slug) => (selectedItem) => {
    let isLatestSlug = false;

    // Clear current filter values
    const filterObject = fieldList.reduce((acc, field) => {
      if (isLatestSlug) {
        return acc;
      }

      if (field.slug === slug) {
        isLatestSlug = true;
      }

      return {
        ...acc,
        [field.slug]: currentFilter[field.slug],
      };
    }, {});
    setCurrentFilter(filterObject);

    // Trigger onChange function for parent component
    isLatestSlug = false;
    const resultObject = fieldList.reduce((acc, field) => {
      if (isLatestSlug) {
        return acc;
      }

      if (field.slug === slug) {
        isLatestSlug = true;
        return {
          ...acc,
          [slug]: selectedItem.key
        };
      }

      return {
        ...acc,
        [field.slug]: currentVehicle[field.slug],
      };
    }, {});

    onChange({
      ...resultObject,
      isChanged: true,
    });
  };

  useEffect(() => {
    if (currentVehicle.types) {
      return;
    }

    let updateSlug = 'type';
    for (const item in fieldList) {
      if (!currentVehicle[fieldList[item].slug]) {
        break;
      }

      updateSlug = fieldList[item].slug;
    }

    // Condition for different vehicle types
    if (
      currentVehicle.type === 2 && updateSlug === 'years'
      || currentVehicle.type === 3 && updateSlug === 'models'
    ) {
      updateSlug = 'volumes';
    }

    switch (updateSlug) {
      case 'type':
        api
          .car
          .getMakes(currentVehicle.type)
          .then(res => res.json())
          .then(res => {
            if (res.data.all) {
              setCurrentFilter({
                makers: res.data.all.sort((a, b) => a.slug.localeCompare(b.slug)),
              });
            }
          })
          .catch(error => console.error(error));
        break;

      case 'makers':
        api
          .car
          .getModels(currentVehicle.makers, currentVehicle.type)
          .then(res => res.json())
          .then(res => {
            if (res.data) {
              setCurrentFilter({
                makers: currentFilter.makers,
                models: res.data.sort((a, b) => a.slug.localeCompare(b.slug)),
              });
            }
          })
          .catch(error => console.error(error));
        break;

      case 'models':
        api
          .car
          .getYears(currentVehicle.makers, currentVehicle.models, currentVehicle.type)
          .then(res => res.json())
          .then(res => {
            if (res.data) {
              setCurrentFilter({
                makers: currentFilter.makers,
                models: currentFilter.models,
                years: res.data,
              });
            }
          })
          .catch(error => console.error(error));
        break;

      case 'years':
        api
          .car
          .getBodies(currentVehicle.makers, currentVehicle.models, currentVehicle.years, currentVehicle.type)
          .then(res => res.json())
          .then(res => {
            if (res.data) {
              setCurrentFilter({
                makers: currentFilter.makers,
                models: currentFilter.models,
                years: currentFilter.years,
                bodies: res.data,
              });
            }
          })
          .catch(error => console.error(error));
        break;

      case 'bodies':
        api
          .car
          .getVolumes(currentVehicle.makers, currentVehicle.models, currentVehicle.years, currentVehicle.bodies, currentVehicle.type)
          .then(res => res.json())
          .then(res => {
            if (res.data) {
              setCurrentFilter({
                makers: currentFilter.makers,
                models: currentFilter.models,
                years: currentFilter.years,
                bodies: currentFilter.bodies,
                volumes: res.data,
              });
            }
          })
          .catch(error => console.error(error));
        break;

      case 'volumes':
        api
          .car
          .getTypes(
            currentVehicle.makers,
            currentVehicle.models,
            currentVehicle.years || 0,
            currentVehicle.bodies || 0,
            currentVehicle.volumes || 0,
            currentVehicle.type,
          )
          .then(res => res.json())
          .then(res => {
            if (res.data) {
              setCurrentFilter({
                makers: currentFilter.makers,
                models: currentFilter.models,
                years: currentFilter.years,
                bodies: currentFilter.bodies,
                volumes: currentFilter.volumes,
                types: res.data,
              });
            }
          })
          .catch(error => console.error(error));
        break;
    }
  }, [currentVehicle]);

  return (
    <div className="auth__form-uservehicle">
      {
        fieldList.map(({ title, slug, isSearch, vt }) => {
          if (!vt.some(item => item === currentVehicle.type)) {
            return null;
          }

          const normalizedArray = currentFilter[slug]
            ? (
              slug === 'years'
                ? currentFilter[slug].map(year => ({
                  key: year,
                  val: year.toString(),
                }))
                : currentFilter[slug]
            )
            : [];

          return (
            <div key={`user-vehicle-row-${ slug }`} className="auth__form-uservehicle-row">
              <label className="d-search-bar__content-label">{ title }</label>
              <DropDown
                placeholder='Select vehicle type'
                selected={isSelectedObject(normalizedArray, currentVehicle[slug])}
                list={normalizedArray}
                withSearch={isSearch}
                onChange={handleChange(slug)}
                disabled={!currentFilter[slug]}
                isFullWidth
              />
            </div>
          );
        })
      }
    </div>
  );
};

export default UserVehicleChain;
