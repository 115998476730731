import { makeAutoObservable, runInAction } from 'mobx';
import { CheckboxFiltersStore } from '../model';

const storeSalesFilters = makeAutoObservable<CheckboxFiltersStore>({
  list: [],
  label: 'Type of sales',
  alias: 'type_of_sales',
  setList(list) {
    runInAction(() => {
      storeSalesFilters.list = Object.entries(list)
        .map(([key, value]) => ({ id: parseInt(key), label: value }));
    });
  },
});

export default storeSalesFilters;
