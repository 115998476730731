import { OverlayViewSafe } from './overlay-view-safe';

export type CustomMarker = CustomOverlay & google.maps.Marker;
export class CustomOverlay extends OverlayViewSafe {
  private div: HTMLDivElement;

  get type(): number {
    return this._type;
  }

  constructor(
    private _html: string,
    private _latlng: google.maps.LatLng,
    // store type
    private _type: number,
  ) {
    super();
  }

  private _createDiv() {
    this.div = document.createElement('div');
    this.div.style.position = 'absolute';
    if (this._html) {
      this.div.innerHTML = this._html;
    }
    google.maps.event.addDomListener(this.div, 'click', () => {
      google.maps.event.trigger(this, 'click');
    });
  }

  private appendDivToOverlay() {
    const panes = this.getPanes();
    (panes as any).overlayImage.appendChild(this.div);
  }

  private positionDiv() {
    const point = this.getProjection().fromLatLngToDivPixel(this._latlng);
    let offset = 25;
    if (point) {
      this.div.style.left = `${point.x - offset}px`;
      this.div.style.top = `${point.y - offset}px`;
    }
  }

  onAdd(): void {
    this._createDiv();
    this.appendDivToOverlay();
  }

  draw() {
    if (this.div) {
      this.positionDiv();
    }
  }

  remove() {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

  getPosition() {
    return this._latlng;
  }

  getDraggable() {
    return false;
  }
}
