import React, { FC, useEffect } from 'react';

import { FilterCategory } from '../../store/filter/model';

import __ from '../../../../utils/translation';

import ArrowLink from '../arrow-link';
import filter from '../../store/filter';
import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';

const ArrowList: FC<{
  list: FilterCategory[];
  title?: string;
  linkPrefix?: string;
  levels?: string[];
}> = ({
  list = [],
  title = __('Categories'),
  linkPrefix = '',
  levels = [] }) => {

  const handleLevels = () => {
    if (levels.length) {
      filter.setInitialLevels([levels[0]]);
    }
  };

  useEffect(() => {
    handleLevels();
  }, [storeCurrentVehicle.current]);

  return (
    <div className="arrow-list">
      {list.length ? (
        <>
          <div className="arrow-list__name">{title}</div>
          <ul className="arrow-list__list">
            {list.map(({ id, title, slug, parts }) => {
              if (parts === 0) {
                return null;
              }

              // Hardcode for Wiper Blades
              const itemLink = slug === 'wiper-blades'
                ? `${linkPrefix || '/'}${slug}/all`
                : `${linkPrefix || '/'}${slug}`

              return (
                <li key={id} className="arrow-list__item">
                  <ArrowLink
                    title={title}
                    link={itemLink}
                    count={parts}
                  />
                </li>
              );
            })}
          </ul>
        </>
      ) : null}
    </div>
  );
};

export default ArrowList;
