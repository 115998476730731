const navigationWrapper = document.querySelector('.navigation') as HTMLDivElement;
const navigationItems = document.querySelectorAll('.navigation__link') as NodeListOf<HTMLAnchorElement>;
const categories = document.querySelectorAll('.category-wrapper') as NodeListOf<HTMLDivElement>;

const setActiveCategory = (e: Event) => {
  const target = e.target as HTMLElement;
  if (target.classList.contains('navigation__link')) {
    const slug = (target as HTMLAnchorElement).hash.substr(1);
    categories.forEach(el => el.classList.toggle('category-wrapper_active',
      el.dataset.attr === slug));
    navigationItems.forEach(el => el.classList.toggle('navigation__link_active',
      el.hash.substr(1) === slug));
  }
};

const init = () => {
  if (navigationWrapper) {
    navigationWrapper.addEventListener('click', setActiveCategory);
  }
};

export default {
  init,
};