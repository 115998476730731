import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { PER_PAGE } from '../../constants';
import { useResize } from '../../hooks';

import Icon from '../icon';

const Pagination: FC<{
  total: number,
  current: number,
  pathname: string,
  filter?: string,
  perPage?: number,
  scrollOnChangeTo?: number,
}> = ({
  total = 0,
  current = 0,
  pathname = '/',
  filter,
  perPage = PER_PAGE,
  scrollOnChangeTo,
}) => {
  if (!total || total <= perPage) {
    return null;
  }

  const isDesktop = useResize();
  const pages = Math.ceil(total / perPage);
  const from = pages > 7 && current > 1
    ? (
      current < pages - 3
        ? current - 2
        : pages - 5
    )
    : 0;
  const resultPages = [...Array(pages)].slice(from, from + 5);

  const handleClick = () => {
    if (typeof scrollOnChangeTo !== 'undefined') {
      window.scrollTo(0, scrollOnChangeTo);
    }
  };

  const prevLink = current > 0
    ? <Link
      key={'page-prev'}
      to={{
        pathname,
        search: [
          filter ? filter : null,
          current === 1 ? null : `page=${current}`,
        ].filter(Boolean).join('&'),
      }}
      className="pagination__page"
      onClick={handleClick}
    >
      <Icon
        name="left-arrow"
        className="pagination__page-icon"
      />
    </Link>
    : null;

  const nextLink = current < pages - 1
    ? <Link
      key={'page-next'}
      to={{
        pathname,
        search: `?${filter ? `${filter}&` : '' }page=${current + 2}`,
      }}
      className="pagination__page"
      onClick={handleClick}
    >
      <Icon
        name="right-arrow"
        className="pagination__page-icon"
      />
    </Link>
    : null;

  // Mobile version
  if (!isDesktop) {
    return (
      <div className="pagination">
        { prevLink }

        <div className="pagination__page pagination__page_active">{ current + 1 }</div>

        { nextLink }
      </div>
    )
  }

  return (
        <div className="pagination">
            { prevLink }

            {
                resultPages.map((item, index) => {
                  const pageNumber = index + (from + 1);
                  const search = [
                    filter ? filter : null,
                    pageNumber === 1 ? null : `page=${pageNumber}`,
                  ].filter(Boolean).join('&');

                  return (
                        <Link
                            key={`page-${pageNumber}`}
                            to={{
                              pathname,
                              search,
                            }}
                            className={cn(
                              'pagination__page',
                              { 'pagination__page_active': current === (pageNumber - 1) },
                            )}
                            onClick={handleClick}
                        >{pageNumber}</Link>
                  );
                })
            }

            {
                pages > 7 && current < pages - 3
                  ?
                    <>
                        {
                            current < pages - 4
                              ? <div className="pagination__page pagination__page_empty">...</div>
                              : null
                        }
                        <Link
                            key={`page-${pages}`}
                            to={{
                              pathname,
                              search: `?${filter ? `${filter}&` : '' }page=${pages}`,
                            }}
                            className="pagination__page"
                            onClick={handleClick}
                        >{pages}</Link>
                    </>
                  : null
            }

          { nextLink }
        </div>
  );
};

export default Pagination;
