import React, { FC } from 'react';
import cn from 'classnames';
import { Link, useRouteMatch } from 'react-router-dom';

import storeUserAccount from '../../../auth/store/user-account';
import { USER_SIDEBAR_MENU } from '../../constants';

import Icon from '../../../denso-catalog/components/icon';
import useResize from "../../../denso-catalog/hooks/resize";

const UserSidebar: FC = () => {
  const isDesktop = useResize();
  const listItem = (label, to, isActive) => {
    if (!isDesktop) {
      return (
          <Link
            to={ to }
            className={cn(
                'user-account__sidebar-nav',
                {'user-account__sidebar-nav_active': isActive},
            )}
          >{ label }</Link>
      );
    }

    return (
        <div
          className={cn(
            'arrow-link',
            'arrow-link_small',
            {'arrow-link_selected': isActive}
          )}
        >
          <span className="arrow-link__icon">
            <Icon name="right-arrow" className="arrow-link__icon-svg"/>
          </span>
          <Link
            to={ to }
            className="arrow-link__text"
          >{ label }</Link>
        </div>
    );
  };

  return (
    <div className={cn(
      'user-account__sidebar',
      {'user-account__sidebar_mobile': !isDesktop},
    )}>
      {
        USER_SIDEBAR_MENU.map(item => {
          const isActive = useRouteMatch({
            path: item.isRoot ? '/' : `/${item.handle}`,
            exact: true,
          });

          // Check Empty Vehicles
          if (storeUserAccount.userVehicles.length === 0 && item.handle === 'vehicles') {
            return null;
          }

          return (
            <div
              key={`user-account-tab-${item.handle}`}
              className="user-account__sidebar-item"
            >
              {
                listItem(
                  item.label,
                  item.isRoot ? '/' : `/${item.handle}`,
                  isActive,
                )
              }
            </div>
          );
        })
      }
    </div>
  );
};

export default UserSidebar;
