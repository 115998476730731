import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import storeUserAccount from '../../../auth/store/user-account';
import Icon from '../../../denso-catalog/components/icon';

const UserLinks: FC = () => {
  if (storeUserAccount.userLinks.length === 0) {
    return null;
  }

  return (
    <div className="user-account__links">
      <div className="banners-grid banners-grid_user-links">
        <div className="banners-grid__inner">
          {
            storeUserAccount.userLinks.map(({
              url,
              title,
              img,
            }, index) => {
              return (
                <div key={`user-links-${index}`} className="banners-grid__item">
                  {
                    img
                      ? <picture
                        className="banners-grid__item-media"
                        aria-hidden="true"
                      >
                        <img
                            srcSet={ img }
                            alt={ title }
                            loading="lazy"
                            decoding="async"
                            className="banners-grid__item-media-img"
                        />
                      </picture>
                      : null
                  }
                  <div className="banners-grid__item-content">
                    <a
                      href={ url }
                      className="banners-grid__item-title"
                      target="_blank"
                      rel="noopener noreferrer"
                    >{ title }</a>
                    <div className="banners-grid__item-arrow" aria-hidden="true">
                      <Icon name="right-arrow" className="banners-grid__item-arrow-svg" />
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default observer(UserLinks);
