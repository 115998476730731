import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { EXCLUDED_PARAMS } from '../../constants';
import filter from '../../store/filter';

const Chips: FC = () => {
  const history = useHistory();
  const { search } = useLocation();

  if (
    filter.levels.length < 3
    && filter.criteriaFilter?.filter(({ title }) => title).length === 0
  ) {
    return null;
  }

  const removeLevel = () => {
    history.push({
      pathname: `/car/${filter.levels
        .slice(0, 2)
        .map(item => item.slug)
        .join('/')}`,
      search,
    });
  };

  const removeFilter = (key: string, type: string, value: string) => () => {
    const queryUrl = new URLSearchParams(search);
    const queryKey = `${key}:${type}`;
    const blockParam = queryUrl.get(queryKey);
    let values = [value];

    EXCLUDED_PARAMS.forEach((excludedParam) => queryUrl.has(excludedParam) && queryUrl.delete(excludedParam));

    if (blockParam) {
      values = blockParam.split('#,#');

      values = values.filter(item => item !== value);

      // Remove Duplicates
      values = values.filter((item, pos, self) => self.indexOf(item) === pos);
    }

    if (values.length) {
      queryUrl.set(queryKey, values.join('#,#'));
    } else {
      queryUrl.delete(queryKey);
    }

    history.push({
      search: queryUrl.toString(),
    });
  };

  const handleRemoveFilters = () => {
    history.push({
      search: null,
    });
  };

  return (
    <div className="page-fmp__result-chips">
      <div className="chips">
        <div className="chips__inner">
          <div className="chips__title">Filters</div>
          {filter.levels.length === 3 ? (
            <div className="chips__item">
              <div className="chips__item-text">{filter.levels[2].title}</div>
              <button
                className="chips__item-delete"
                type="button"
                aria-label="Remove"
                onClick={removeLevel}
              />
            </div>
          ) : null}

          {filter.criteriaFilter.length > 0 && filter.criteria?.length > 0
            ? filter.criteriaFilter.map(chipsItem => {
              const { key, title, items, type } = chipsItem;

              if (items.length && title) {
                return items.map(({ itemKey, itemTitle }) => (
                    <div key={`${key}-${itemKey}`} className="chips__item">
                      <div className="chips__item-text">
                        {title}: {itemTitle}
                      </div>
                      <button
                        className="chips__item-delete"
                        type="button"
                        aria-label="Remove"
                        onClick={removeFilter(key, type, itemKey)}
                      />
                    </div>
                ));
              }

              return null;
            })
            : null}
        </div>
      </div>

      <button
        className="page-fmp__vehicle-link page-fmp__vehicle-link_remove page-fmp__result-chips-remove"
        onClick={handleRemoveFilters}
        type="button"
      >Remove all filters</button>
    </div>
  );
};

export default observer(Chips);
