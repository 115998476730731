import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import refreshVehicleStore from '../../utils/refresh-store'; // pageSearchString

import overall from '../../store/overall';
import storeCarTypes from '../../store/car-types';
import storeVehicleBrands from '../../store/vehicle-brands';
import storeVehicleTypes from '../../store/vehicle-type';
import storeCurrentVehicle from '../../store/current-vehicle';
import storeAlternativeSearch from '../../store/alternative';

import Button from '../button';
import Column from '../column';

import VehicleBrandField from '../vehicle-brand';
import VehicleModelField from '../vehicle-model';
import VehicleYearField from '../vehicle-year';
import VehicleBodyField from '../vehicle-body';
import VehicleVolumeField from '../vehicle-volume';
import VehicleTypeField from '../vehicle-type';
import VehiclePartSearch from '../vehicle-part-search';
import storeVehicleBodies from '../../store/vehicle-body';
import storeVehicleModels from '../../store/vehicle-model';
import storeVehicleYears from '../../store/vehicle-year';
import storeVehicleVolumes from '../../store/vehicle-volume';
import breadcrumbs from '../../../denso-catalog/store/breadcrumbs';
import { useHistory } from 'react-router-dom';
import filter from '../../../denso-catalog/store/filter';

const Content: FC = () => {
  const history = useHistory();

  const handleSearch = () => {
    if (overall.isLinkSearch) {
      window.location.href = `/catalog/${storeCarTypes.current?.slug ? `${storeCarTypes.current.slug}/` : ''}${storeVehicleTypes.current?.key || ''}`;
      return;
    }

    storeCurrentVehicle.setCurrent({
      id: storeVehicleTypes.current?.key,
      brand: storeVehicleBrands.current?.val,
      model: storeVehicleModels.current?.val,
      year: storeVehicleYears.current?.val,
      body: storeVehicleBodies.current?.val,
      volume: storeVehicleVolumes.current?.val,
      type: storeVehicleTypes.current?.val,
      vehicleType: storeCarTypes.current?.slug,
    });

    if (breadcrumbs.list.length > 1) {
      filter.resetInitialLevels();
      history.push(`/${storeCarTypes.current?.slug ? `${storeCarTypes.current.slug}/` : ''}${storeVehicleTypes.current?.key || ''}`);
    }
  };

  const handleClearFilter = () => {
    refreshVehicleStore('brand', null);
    storeCurrentVehicle.setCurrent(null);
  };

  if (storeCarTypes.list.length === 0) {
    return null;
  }

  return (
    <div className="d-search-bar__content">
      <div className="d-search-bar__content-alt">{ __('Select your car') }</div>

      <VehicleBrandField />
      <VehicleModelField />

      {storeCarTypes.currentId !== 3 ? <VehicleYearField /> : null}

      {storeCarTypes.currentId === 1 ? <VehicleBodyField /> : null}

      {storeVehicleBodies.current ? (
        <>
          {storeCarTypes.currentId === 1 ? <VehicleVolumeField /> : null}
          <VehicleTypeField />
        </>
      ) : null}

      {storeVehicleBrands.current ? null : (
        <>
          <Column type="or">{ __('or') }</Column>
          <VehiclePartSearch />
        </>
      )}

      <Column type="search">
        {storeVehicleBrands.current ? (
          <button
            className="d-search-bar__content-link"
            type="button"
            onClick={handleClearFilter}
          >
            { __('Remove filters') }
          </button>
        ) : null}

        <Button
          width="full"
          onClick={handleSearch}
          disabled={
            !(
              storeVehicleTypes.current ||
              (!storeVehicleBrands.current &&
                storeAlternativeSearch.isAvailable)
            )
          }
        >
          { __('Search') }
        </Button>
      </Column>
    </div>
  );
};

export default observer(Content);
