import React, {FC, useState, useEffect, useRef, useMemo} from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import __ from '../../../../utils/translation';

import api from '../../../../api';
import storeAlternativeSearch from '../../store/alternative';
import overall from '../../store/overall';
import storeOverall from '../../../denso-catalog/store/overall';

import TextInput from '../text-input';
import DropDownItem from '../dropdown/components/dropdown-item';

let debounce = null;

let abortController: AbortController;
let abortSignal: AbortSignal;

const VehiclePartSearch: FC<{
  onEnter?: () => void,
}> = ({
  onEnter,
}) => {
  const history = useHistory();
  const listElement = useRef<HTMLUListElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [searchString, setSearchString] = useState(storeAlternativeSearch.current || '');
  const [resultList, setResultList] = useState([]);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [isSearching, setIsSearching] = useState(false);
  const resultText = searchString.trim().length >= 3
    ? (
      isSearching
        ? __('Searching...')
        : resultList.length === 0 ? __('No matches found') : ''
    )
    : `${ __('Please enter') } ${3 - searchString.trim().length} ${ __('or more characters') }`;

  const calculateScrollPosition = (
    elementOffsetTop: number,
    elementHeight: number,
    visibleArea: number,
  ): number => {
    const activeElementBottomPosition = elementOffsetTop + elementHeight;

    if (elementOffsetTop < listElement.current.scrollTop || activeElementBottomPosition > visibleArea) {
      return activeElementBottomPosition > visibleArea
        ? activeElementBottomPosition - listElement.current.offsetHeight + 8
        : elementOffsetTop - 8;
    }

    return -1;
  };

  const setCurrentItemFocus = () => {
    if (listElement && listElement.current) {
      const visibleArea = listElement.current.scrollTop + listElement.current.offsetHeight;
      const activeElement = listElement.current.querySelector('.d-search-bar__dd-item_hovered') as HTMLLIElement;

      if (activeElement) {
        const scrollToY = calculateScrollPosition(activeElement.offsetTop, activeElement.offsetHeight, visibleArea);

        scrollToY !== -1
          ? listElement.current.scrollTo(0, scrollToY)
          : null;
      }
    }
  };

  const chooseSearchItem = (partName) => {
    if (overall.isLinkSearch) {
      window.location.href = `/catalog/part/${partName}`;
      return;
    } else {
      history.push(`/part/${partName}`);
    }
  };

  const handleClickPart = () => {
    // VIN Search
    if (resultList[hoverIndex].type === 'registration') {
      window.location.href = resultList[hoverIndex].url;
      return;
    }

    setSearchString(resultList[hoverIndex].val);
    chooseSearchItem(resultList[hoverIndex].part_name);
  };

  const handleItemHover = (itemIndex) => () => {
    setHoverIndex(itemIndex);
  };

  const catchEnter = (e) => {
    if (e.code === 'Enter' && searchString.trim().length > 0 && resultList.length > 0) {
      e.target.blur();
      handleClickPart();
    }

    // Clear search input
    if (e.code === 'Escape') {
      setSearchString('');
    }

    // Change item index with arrows
    if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
      let nextHoverIndex = e.code === 'ArrowDown' ? hoverIndex + 1 : hoverIndex - 1;

      nextHoverIndex = nextHoverIndex < 0
        ? resultList.length - 1
        : (
          nextHoverIndex >= resultList.length
            ? 0
            : nextHoverIndex
        );

      setHoverIndex(nextHoverIndex);
    }
  };

  const getSearchData = () => {
    api
      .altSearch
      .findPart(searchString.trim(), abortSignal)
      .then(res => res.json())
      .then((res) => {
        setHoverIndex(0);

        setResultList(res.data?.length > 0 ? res.data : []);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
        }
      })
      .then(() => setIsSearching(false));
  };

  useEffect(() => {
    if (hoverIndex !== -1) {
      setCurrentItemFocus();
    }
  }, [hoverIndex]);

  useEffect(() => {
    // Clear timeout and abort signal
    if (abortController) {
      abortController.abort();
    }
    clearTimeout(debounce);

    if (searchString.trim().length > 2) {
      setIsSearching(true);

      // Create new signal with timeout
      abortController = new AbortController();
      abortSignal = abortController.signal;
      debounce = setTimeout(getSearchData, 300);
    }
  }, [searchString]);

  const handleFocus = (isFocus = false) => () => {
    setIsFocus(isFocus);
  };

  const handleChange = inputValue => setSearchString(inputValue);

  const searchColumnTitle = useMemo(() => {
    return storeOverall.isVINAvailable
      ? __('Alternative search')
      : __('Part number search')
  }, []);

  const placeholderText = useMemo(() => {
    if (storeOverall.isVINAvailable) {
      return `${ __('License plate') } / ${ __('Part number / OE') }`
    }

    return __('Part number / OE');
  }, []);

  return (
        <div className="d-search-bar__content-col d-search-bar__content-col_as">
            <div className="d-search-bar__content-label">{ searchColumnTitle }</div>

            <div className="d-search-bar__content-alt d-search-bar__content-alt_line">
              <span className="d-search-bar__content-alt-text">Alternative search</span>
            </div>

            <div className="d-search-bar__fake-input" />
            <div className={cn(
              'd-search-bar__search',
              { 'd-search-bar__search_focus': isFocus },
            )}>
                <TextInput
                    placeholder={ placeholderText }
                    value={searchString}
                    onChange={handleChange}
                    onKeyUp={catchEnter}
                    onFocus={handleFocus(true)}
                    onBlur={handleFocus()}
                />

                {
                    isFocus
                      ? <div className="d-search-bar__dd-window d-search-bar__dd-window_full">
                            {
                                searchString.trim().length >= 3
                                    && resultList.length > 0
                                    && !isSearching
                                  ? <ul
                                        className="d-search-bar__dd-list"
                                        ref={listElement}
                                    >
                                        {
                                            resultList.map((item, index) => (
                                                <DropDownItem
                                                    key={`dd-item-${item.key}`}
                                                    title={item.val}
                                                    value={item.key}
                                                    index={index}
                                                    hovered={hoverIndex === index}
                                                    onMouseDown={handleClickPart}
                                                    onHover={handleItemHover(index)}
                                                />
                                            ))
                                        }
                                    </ul>
                                  : <div className="d-search-bar__dd-text">{resultText}</div>
                            }
                        </div>
                      : null
                }
            </div>
            {
                storeAlternativeSearch.error
                  ? <div className="d-search-bar__content-col-error">{storeAlternativeSearch.error}</div>
                  : null
            }
        </div>
  );
};

export default observer(VehiclePartSearch);
