let newsTabs;
let tabsContent;

const toggleTab = (tabName) => {
  tabsContent
    .querySelectorAll('.news-tabs__tab')
    .forEach((tab) => {
      tab.classList.toggle('news-tabs__tab_active', tab.dataset.tabname === tabName);
    });
};

const handleTabClick = (e) => {
  const { target } = e;

  if (target.classList.contains('news-tabs__head-button')) {
    e.preventDefault();

    target
      .parentElement
      .querySelectorAll('.news-tabs__head-button')
      .forEach((btn) => {
        btn.classList.toggle('news-tabs__head-button_active', btn === target);
      });

    toggleTab(target.dataset.tab);
  }
};

const events = () => {
  newsTabs.addEventListener('click', handleTabClick);
};

const init = () => {
  newsTabs = document.querySelector('.news-tabs');

  if (newsTabs) {
    tabsContent = newsTabs.querySelector('.news-tabs__content');

    events();
  }
};

export default {
  init,
};
