import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import refreshVehicleStore from '../../utils/refresh-store';

import storeVehicleBrands from '../../store/vehicle-brands';
import { VehicleBrand } from '../../store/vehicle-brands/model';
import storeCarTypes from '../../store/car-types';

import DropDown from '../dropdown';
import Column from '../column';

const VehicleBrandField: FC = () => {
  const selected = storeVehicleBrands.current
    ? {
      val: storeVehicleBrands.current.val,
      key: storeVehicleBrands.current.key,
    }
    : null;

  const handleChange = (selectedItem: VehicleBrand) => {
    refreshVehicleStore('brand', selectedItem);
  };

  const fieldTitle = useMemo(() => __('Select brand'), [storeCarTypes.current]);

  return (
        <Column label={ __('Make') }>
            <DropDown
                placeholder={fieldTitle}
                selected={selected}
                popularList={storeVehicleBrands.popularList}
                list={storeVehicleBrands.list}
                multiList
                onChange={handleChange}
                withSearch
            />
        </Column>
  );
};

export default observer(VehicleBrandField);
