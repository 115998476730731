import React, { FC, useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';
import filter from '../../store/filter';

import Sidebar from '../sidebar';
import CatHeader from '../cat-header';
import CurrentCarText from '../current-car-text';
import Banner from '../banner';
import AlertBox from '../alert-box';
import CategoriesGrid from '../categories-grid';
import Parts from '../parts';
import Pagination from '../pagination';

const List: FC = () => {
  const { catLevel2 } = useParams<{ catLevel2: string }>();

  const [isLoading, setIsLoading] = useState(true);
  const handleLoading = isLoading => setIsLoading(isLoading);

  return (
    <div className="wrapper">
      <div className="page-fmp__content">
        <div className="grid">
          <div className="grid__col grid__col_md_3 page-fmp__content-sidebar">
            <Sidebar isLoading={isLoading}  handleLoading={handleLoading} />
          </div>

          <div className="grid__col grid__col_12 grid__col_lg_9">
            <CatHeader />
            <CurrentCarText />

            {
              // Alert box if no car is selected
              storeCurrentVehicle.current ? null : <AlertBox />
            }

            {catLevel2 && filter.currentCategory ? <Parts /> : <CategoriesGrid isLoading={isLoading} />}

            <Route path={'/'} component={Pagination} />

            <Banner />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(List);
