import React, { FC } from 'react';

import Icon from '../icon';

const Checkbox: FC<{
  name: string,
  label: string,
  value: string | number,
  checked?: boolean,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  docCount?: string | number,
}> = ({
  name,
  label,
  value,
  checked = false,
  onChange,
  docCount,
}) => {
  return (
        <label className="denso-checkbox denso-checkbox_small">
            <input
                className="denso-checkbox__input"
                type="checkbox"
                value={value}
                name={name}
                checked={checked}
                onChange={onChange}
            />
            <span className="denso-checkbox__box" aria-hidden="true">
                <Icon
                    name="check"
                    className="denso-checkbox__box-svg"
                />
            </span>
            <span className="denso-checkbox__label">
                {label}
                {
                    typeof docCount !== 'undefined'
                      ? <span className="page-fmp__filter-block-count">{docCount}</span>
                      : null
                }
            </span>
        </label>
  );
};

export default Checkbox;
