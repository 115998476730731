import { UserVehicle } from '../../components/user-account/components/user-vehicles/model';

import { apiRequest } from '../';

const addVehicle = (userId: number | string, vehicleId: number | string, vehicleType: number | string) => {
  return apiRequest(`/accounts/${userId}/vehicles?vt=${vehicleType}`, 'POST', {
    id: vehicleId,
  });
};

const editVehicle = (userId: number | string, vehicleId: number | string, vehicleType: number | string) => {
  return apiRequest(`/accounts/${userId}/vehicles/${vehicleId}?vt=${vehicleType}`, 'GET');
};

const saveVehicle = (
  userId: number | string,
  vehicleData: UserVehicle,
) => {
  return apiRequest(`/accounts/${userId}/vehicles?vt=${vehicleData.type}`, 'POST', {
    ...vehicleData,
  });
};

const removeVehicle = (userId: number | string, vehicleId: number | string, vehicleType: number | string) => {
  return apiRequest(`/accounts/${userId}/vehicles/${vehicleId}?vt=${vehicleType}`, 'DELETE');
};

const account = {
  addVehicle,
  editVehicle,
  saveVehicle,
  removeVehicle,
};

export default account;
