import React, { useState } from 'react';
import { FC } from 'react';
import { useLayer, Arrow, useHover } from 'react-laag';

const Tooltip: FC<{ className?: string }> = ({ children, className }) => {
  const [isOver, hoverProps] = useHover({ delayLeave: 300, delayEnter: 100, hideOnScroll: false });
  const [isOpen, setIsOpen] = useState(false);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen: isOpen || isOver,
    placement: 'right-start',
    auto: true,
    triggerOffset: 16,
    arrowOffset: 8,
    onOutsideClick: () => setIsOpen(false),
  });

  return (
        <>
            <div
                {...triggerProps}
                {...hoverProps}
                onClick={e => {
                  setIsOpen(true);
                  e.stopPropagation();
                }}
                className={className}
            >
                <div className="tooltip__trigger">
                    <span className="tooltip__trigger_text">i</span>
                </div>
            </div>
            {isOver &&
                renderLayer(
                    <div
                        {...layerProps}
                        {...hoverProps}
                        style={{ ...layerProps.style }}
                        className="tooltip"
                    >
                        <div className="tooltip-content">{children}</div>
                        <Arrow {...arrowProps} size={8} roundness={0} />
                    </div>,
                )}
        </>
  );
};

export default Tooltip;