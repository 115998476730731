import { makeAutoObservable, runInAction } from 'mobx';
import LocationsStore, { Store } from './model';

const storeStores = makeAutoObservable<LocationsStore>({
  currentStore: null,
  visibleStoreList: [],
  globalStoreList: [],
  setCurrentStore(location) {
    runInAction(() => {
      storeStores.currentStore = location;
    });
  },
  setGlobalStoresList(list: Omit<Store, 'latlng'>[]) {
    runInAction(() => {
      storeStores.globalStoreList = list.map(store => ({
        ...store,
        latlng: new google.maps.LatLng(parseFloat(store.lat), parseFloat(store.lng)),
      }));
    });
  },
  setVisibleStoresList(list: Store[]) {
    runInAction(() => {
      storeStores.visibleStoreList = list;
    });
  },
});

export default storeStores;
