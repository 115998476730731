import { makeAutoObservable, runInAction } from 'mobx';
import { CheckboxFiltersStore } from '../model';

const storeProductGroupFilters = makeAutoObservable<CheckboxFiltersStore>({
  list: [],
  label: 'Product group',
  alias: 'product_group',
  setList(list) {
    runInAction(() => {
      storeProductGroupFilters.list = Object.entries(list)
        .map(([key, value]) => ({ id: parseInt(key), label: value }));
    });
  },
});

export default storeProductGroupFilters;
