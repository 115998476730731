let items: Element[];
let button: HTMLButtonElement;
let icon: HTMLElement;

const toggleShowMore = () => {
  items.forEach(el => el.classList.toggle('partners-block__item_hidden'));
  icon.classList.toggle('arrow-link__icon_down');
  icon.classList.toggle('arrow-link__icon_up');
  button.querySelector('span').innerHTML = button.innerText === 'Show more' ? 'Show less' : 'Show more';
};

const events = (): void => {
  button.addEventListener('click', toggleShowMore);
};

const init = (): void => {
  items = [...document.querySelectorAll('.partners-block__item')].filter(
    (el: HTMLElement) => el.dataset.attr === 'partners-more-item');
  button = document.querySelector('.partners-block__button');
  icon = button ? button.querySelector('.arrow-link__icon') : null;

  if (items.length && button) {
    events();
  }
};

export default {
  init,
};
