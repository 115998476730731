import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import overall from '../../store/overall';
import breadcrumbs from '../../store/breadcrumbs';
import filter from '../../store/filter';

import { useResize } from '../../hooks';

import Icon from '../icon';
import { FilterCategory } from '../../store/filter/model';

const Breadcrumbs: FC = () => {
  const homeIconSize = 16;
  const isDesktop = useResize();

  useEffect(() => {
    const rootEl = breadcrumbs.list[0];
    const existLevels: FilterCategory[] = filter.levels.filter(Boolean);

    if (existLevels.length > 0) {
      const levels = existLevels.map((level, index) => ({
        title: level.title,
        link: `/${existLevels
          .slice(0, index + 1)
          .map(item => item.slug)
          .join('/')}`,
      }));

      breadcrumbs.setList([rootEl, ...levels]);
      return;
    }
    if (rootEl) {
      breadcrumbs.setList([rootEl]);
    }
  }, [filter.levels, filter.categories]);

  const handleLinkClick = (e) => {
    const { initialLevels } = filter;
    const selectedLevel = initialLevels
      .find(el => `/${overall.baseUrl}/${overall.prefix}/${el}` === e.target.pathname);

    // remove levels when click on root catalog
    if (e.target.pathname === `/${overall.baseUrl}/${overall.prefix}`) {
      filter.resetInitialLevels();
    }

    // remove initial levels after selected
    filter.setInitialLevels(
      initialLevels.filter(
        el =>initialLevels.indexOf(el) <= initialLevels.indexOf(selectedLevel),
      ));
  };

  if (!isDesktop) {
    return null;
  }

  return (
    <div className="breadcrumbs">
      <div className="wrapper">
        <ul className="breadcrumbs__list">
          <li className="breadcrumbs__item">
            <a href="/" className="breadcrumbs__item-link">
              <svg
                className="breadcrumbs__item-link-svg"
                width={homeIconSize}
                height={homeIconSize}
                viewBox={`0 0 ${homeIconSize} ${homeIconSize}`}
              >
                <path
                  fillRule="nonzero"
                  d="M8.695.293a1 1 0 00-1.414 0l-7 7a.999.999 0 001.414 1.414l.293-.293V15a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1V8.414l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>
            </a>
          </li>

          {breadcrumbs.list.length
            ? breadcrumbs.list.map((item, index) => {
              const prefix = index === 0 ? '' : `/${overall.prefix}`;

              return (
                  <li
                    key={`breadcrumb-item-${index}`}
                    className="breadcrumbs__item"
                  >
                    <Icon
                      name="right-arrow"
                      className="breadcrumbs__item-icon"
                    />

                    {item.link && index < breadcrumbs.list.length - 1 ? (
                      <Link
                        to={`${prefix}${item.link}`}
                        className="breadcrumbs__item-link"
                        onClick={ (e) => handleLinkClick(e) }
                      >
                        {item.title}
                      </Link>
                    ) : (
                      <span className="breadcrumbs__item-text">
                        {item.title}
                      </span>
                    )}
                  </li>
              );
            })
            : null}
        </ul>
      </div>
    </div>
  );
};

export default observer(Breadcrumbs);
