import __ from '../../utils/translation';

// Banner Anchors
export const AVAILABLE_USER_GROUPS = [{
  label: __('Driver'),
  handle: 'drivers',
}, {
  label: __('Distributor'),
  handle: 'distributors',
}, {
  label: __('Garage'),
  handle: 'garages',
}];
