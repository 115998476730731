import React, { FC, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Icon from '../icon';

const ArrowLink: FC<{
  title: string;
  link: string;
  count?: number | string;
  reverseArrow?: boolean;
  isSmall?: boolean;
  isDown?: boolean;
  onClick?: (e?: MouseEvent) => void;
}> = ({
  title,
  link,
  count,
  reverseArrow = false,
  isSmall = true,
  isDown = false,
  onClick,
}) => {
  return (
    <div
      className={cn(
        'arrow-link',
        { 'arrow-link_reverse': reverseArrow },
        { 'arrow-link_small': isSmall },
      )}
    >
      <div
        className={cn('arrow-link__icon', { 'arrow-link__icon_down': isDown })}
      >
        <Icon name="right-arrow" className="arrow-link__icon-svg" />
      </div>
      <Link to={link} className="arrow-link__text" onClick={onClick}>
        {title}

        {typeof count !== 'undefined' ? (
          <span className="arrow-link__count">{count}</span>
        ) : null}
      </Link>
    </div>
  );
};

export default ArrowLink;
