import { makeAutoObservable, runInAction } from 'mobx';

import VehicleFields from './model';

const storeVehicleTypes = makeAutoObservable<VehicleFields>({
  list: [],
  setList: (list = []) => {
    runInAction(() => {
      storeVehicleTypes.list = list;
    });
  },
  current: null,
  setCurrent: newVehicleBody => {
    runInAction(() => {
      storeVehicleTypes.current = newVehicleBody;
    });
  },
  get currentId() {
    return storeVehicleTypes.current?.key;
  },
});

export default storeVehicleTypes;
