export interface OverlayViewSafe extends google.maps.OverlayView {}

/**
 * Extends an object's prototype by another's.
 * @param type1 The Type to be extended.
 * @param type2 The Type to extend with.
 */
function extend(type1: any, type2: any): void {
  for (let property in type2?.prototype) {
    type1.prototype[property] = type2.prototype[property];
  }
}

/**
 * @hack
 * Correct extends OverlayView from google.maps.OverlayView,
 * because google loaded in runtime
 */
export class OverlayViewSafe {
  constructor() {
    extend(OverlayViewSafe, google.maps.OverlayView);
  }
}
