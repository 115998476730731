import React, { FC, useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import __ from '../../../../utils/translation';

import Icon from '../../../denso-catalog/components/icon';
import AuthVehicles from '../vehicles';

const AccountDD: FC<{
  isAccountPage: boolean,
}> = ({
  isAccountPage = false,
}) => {
  const [isShow, setIsShow] = useState(false);
  const toggleDDLinkRef = useRef<HTMLAnchorElement>(null);

  const handleAccountClick = (e: React.MouseEvent): void => {
    e.preventDefault();

    setIsShow(!isShow);
  };
  const handleClickOutside = (e: MouseEvent): void => {
    const target = e.target as HTMLAnchorElement;

    if (toggleDDLinkRef && !toggleDDLinkRef.current.isSameNode(target)) {
      setIsShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleLogout = (): void => {
    localStorage.removeItem('denso-current-user');
  };

  return (
        <>
            <a
                href="/account"
                className={cn(
                  'header__nav-link',
                  'header__nav-link_arr',
                  { 'header__nav-link_active': isAccountPage },
                )}
                ref={toggleDDLinkRef}
                onClick={handleAccountClick}
            >{ __('Account') }</a>

            <div className={cn(
              'auth__dd',
              { 'auth__dd_open': isShow },
            )}>
              <AuthVehicles />

                <div className="auth__dd-block">
                    <div className="arrow-link arrow-link_small">
                        <div className="arrow-link__icon">
                            <Icon
                                name="right-arrow"
                                className="arrow-link__icon-svg"
                            />
                        </div>
                        <a
                            href="/account"
                            className="arrow-link__text"
                        >{ __('Account settings') }</a>
                    </div>
                </div>
                <div className="auth__dd-block">
                    <div className="arrow-link arrow-link_small">
                        <div className="arrow-link__icon">
                            <Icon
                                name="right-arrow"
                                className="arrow-link__icon-svg"
                            />
                        </div>
                        <a
                            href="/logout"
                            className="arrow-link__text"
                            onClick={handleLogout}
                        >{ __('Log out') }</a>
                    </div>
                </div>
            </div>
        </>
  );
};

export default AccountDD;
