import React, { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import __ from '../../../../utils/translation';

import storeUserAccount from '../../store/user-account';
import { CAR_TYPES } from '../../../d-search-bar/constants';

import Icon from '../../../denso-catalog/components/icon';

const AuthVehicles: FC = () => {
  if (storeUserAccount.userVehicles.length === 0) {
    return null;
  }

  const handleVehicleClick = (vehicleObj) => (e: MouseEvent): void => {
    e.preventDefault();

    storeUserAccount.setSelectedVehicle(vehicleObj);
  };

  return (
    <div className="auth__dd-block">
      <div className="auth__vehicles">
        <div className="auth__vehicles-title">{ __('My vehicles') }</div>

        <div className="auth__vehicles-list">
          {
            storeUserAccount.userVehicles.map((vehicle) => {
              const isActiveElement = storeUserAccount.selectedVehicle?.id === vehicle.id;
              const vehicleTypeObj = CAR_TYPES.find(type => type.id == vehicle.type);

              return (
                <div
                  key={`auth-vehicle-${vehicle.type}-${vehicle.id}`}
                  className={cn(
                    'auth__vehicles-item',
                    {'auth__vehicles-item_active': isActiveElement},
                  )}
                >
                  <div className="auth__vehicles-item-icon">
                    <Icon
                      name="car"
                      className="auth__vehicles-item-svg"
                    />
                  </div>
                  <a
                    href={vehicleTypeObj ? `/catalog/${vehicleTypeObj.slug}/${vehicle.id}` : '#'}
                    className="auth__vehicles-item-name"
                    title={vehicle.name}
                    onClick={vehicleTypeObj ? null : handleVehicleClick(vehicle)}
                  >{ vehicle.name }</a>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default observer(AuthVehicles);
