import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import storeStores from '../../../../store/stores';
import LocationsNearbyListItem from './components/locations-nearby-list-item';
import { Store } from '../../../../store/stores/model';

const LocationsNearby: FC = () => {

  const selectLocation = (store: Store) => {
    storeStores.setCurrentStore(store);
  };

  const storesList = storeStores.visibleStoreList.map(item => {
    return (
      <LocationsNearbyListItem
        selectLocation={selectLocation}
        key={item.id}
        location={item}
      />
    );
  });

  return (
    <div className="where-to-buy__sidebar-locations-nearby">
      <div className="where-to-buy__sidebar-filter-label">
        {storesList.length ? `${storesList.length} locations in your area` : 'No locations found'}
      </div>
      {storesList.length ? <div className="where-to-buy__sidebar-locations-list">{storesList}</div> : null}
    </div>
  );
};

export default observer(LocationsNearby);
