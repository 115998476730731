import React, { FC, ReactElement, useState } from 'react';
import cn from 'classnames';

import __ from '../../../../utils/translation';

import Icon from '../icon';
import ExpandList from '../expand-list';

const fieldIcons = Object.freeze({
  200: 'diameter',
});

const renderFieldIcon = (fieldId: number | string): ReactElement => {
  if (!fieldId || !fieldIcons.hasOwnProperty(fieldId)) {
    return null;
  }

  return (
        <Icon
            name={fieldIcons[fieldId]}
            className="catalog-specs__row-icon"
        />
  );
};

const Specs: FC<{
  list: {
    id: number | string,
    label: string,
    value: string,
    isActive?: boolean,
  }[],
  isSmall?: boolean,
  isBorder?: boolean,
  isCut?: boolean,
  isCluster?: boolean,
}> = ({
  list = [],
  isSmall = false,
  isBorder = false,
  isCut = false,
  isCluster = false,
}) => {
  if (!list || list.length === 0) {
    return null;
  }

  const [isFull, setIsFull] = useState(false);
  const normalizeList = isCut && list.length > 6
    ? (
      isFull
        ? list
        : list.slice(0, 6)
    )
    : list;
  const toggleFull = () => setIsFull(!isFull);

  return (
      <div className={cn(
        'catalog-specs',
        { 'catalog-specs_small': isSmall },
        { 'catalog-specs_bordered': isBorder },
        { 'catalog-specs_full': isFull },
        { 'catalog-specs_cluster': isCluster },
      )}>
          {
              normalizeList.map(({ id, label, value, isActive }, index) => (
                  <div
                      key={`${id}-${index}`}
                      className={cn(
                        'catalog-specs__row',
                        { 'catalog-specs__row_active': isActive },
                      )}
                  >
                      <div className="catalog-specs__row-label">
                          {label}
                          {renderFieldIcon(id)}
                      </div>
                      {
                          Array.isArray(value)
                            ? <div className="catalog-specs__row-value">
                                  <ExpandList list={value} />
                              </div>
                            : <div
                                  className="catalog-specs__row-value"
                                  dangerouslySetInnerHTML={{ __html: value }}
                              />
                      }
                  </div>
              ))
          }

          {
              isCut && list.length > 6
                ? <div className="catalog-specs__toggler">
                      <button
                          className="catalog-specs__toggler-btn"
                          type="button"
                          onClick={toggleFull}
                      >
                            <span className="catalog-specs__toggler-btn-icon">
                                <Icon
                                    name="down-arrow"
                                    className="catalog-specs__toggler-btn-svg"
                                />
                            </span>
                          <span
                              className="catalog-specs__toggler-btn-text"
                          >{ isFull ? __('Show less') : __('Show more') }</span>
                      </button>
                      <div
                          className="catalog-specs__toggler-line"
                          aria-hidden="true"
                      />
                  </div>
                : null
          }
      </div>
  );
};

export default Specs;
