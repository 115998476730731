import React, { FC, ReactNode, useState } from 'react';
import cn from 'classnames';

import __ from '../../../../../utils/translation';

import { useResize } from '../../../hooks';

import Icon from '../../icon';

const SidebarWrapper: FC<{
  children: ReactNode,
  isFiltered?: Boolean,
}> = ({
  children,
  isFiltered,
}) => {
  const isDesktop = useResize();
  const [isOpen, setIsOpen] = useState(false);

  if (isDesktop) {
    return (<div className="page-fmp__filter">{ children }</div>);
  }

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  return (
    <div className={cn(
      'page-fmp__mfilter',
      {'page-fmp__mfilter_open': isOpen},
      {'page-fmp__mfilter_filtered': isFiltered},
    )}>
      <div
        className="page-fmp__mfilter-bg"
        aria-label="hidden"
        role="button"
        onClick={handleClose}
      />

      <div className="page-fmp__mfilter-mover">
        <div className="page-fmp__mfilter-inner">
          <header className="page-fmp__mfilter-header">
            <div className="page-fmp__mfilter-header-title">{ __('Filter') }</div>
            <button
              className="page-fmp__mfilter-header-close"
              type="button"
              onClick={handleClose}
            >
              <span className="denso-filter__header-close-text">{ __('Close') }</span>
              <span className="denso-filter__header-close-icon">
                <Icon
                  name="cross"
                  className="denso-filter__header-close-svg"
                />
              </span>
            </button>
          </header>

          <div className="page-fmp__mfilter-body">
            <div className="page-fmp__mfilter-body-content">{ children }</div>
          </div>

          <div className="page-fmp__mfilter-footer">
            <button
              className="button page-fmp__result-btn"
              type="button"
              onClick={handleClose}
            >
              <Icon
                name="filter"
                className="page-fmp__result-btn-svg"
              />
              <span className="page-fmp__result-btn-text">{ __('Show results') }</span>
            </button>
          </div>
        </div>
      </div>

      <div className="page-fmp__mfilter-fixed">
        <button
          className="page-fmp__mfilter-fixed-btn"
          type="button"
          onClick={handleOpen}
        >{ __('Filter') }</button>
      </div>
    </div>
  );
};

export default SidebarWrapper;
