export type VehicleType = 'mc' | 'pv' | 'cv';

export const vTypeParam = new Map<VehicleType, number>([
  ['pv', 1],
  ['cv', 2],
  ['mc', 3],
]);

export function getVTypeId(type: VehicleType): number {
  return vTypeParam.get(type) ?? 1;
}

export function getVTypeName(vehicleId: number): VehicleType {
  const vehicleEntry = [...vTypeParam.entries()].find(([, value]) => value === vehicleId);

  return vehicleEntry
    ? vehicleEntry[0]
    : 'pv';
}
