import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import overall from '../../store/overall';
import parts from '../../store/parts';
import { useResize } from '../../hooks';

import ArrowLink from '../arrow-link';
import DetailSlider from './slider';
import DetailCarApplicability from './car-applicability';
import Specs from '../specs';
import Icon from '../icon';
import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';
import filter from '../../store/filter';
import PartsItemReplaces from '../parts/itemReplaces';

const DetailOverall: FC = () => {
  if (!parts.currentPart) {
    return null;
  }

  const isDesktop = useResize();
  const {
    name,
    images,
    spin_image_url,
    docs,
    applicable,
    levels,
    replaces,
    replaced_by,
  } = parts.currentPart;
  const specifications = parts.specifications;
  const overviewSpecs = parts.overview;
  const partTitle = `${ __('DENSO part number') }: ${ name }`;
  const isWiperBlades = levels.some(level => level.slug === 'wiper-blades');

  const handleSpecificationClick = e => {
    const specificationsBlock = (window as any)['part-specifications'];

    e.preventDefault();

    if (specificationsBlock) {
      window.scrollTo({
        top: specificationsBlock.offsetTop - 74 - 16,
        behavior: 'smooth',
      });
    }
  };

  const replacesElements = useMemo(() => {
    const isBothAvailable = replaces.length > 0 && replaced_by.length > 0;

    if (isBothAvailable) {
      const postfixArray = [
        ...replaces.map(replacesItem => ({...replacesItem, name: `${replacesItem.name} (${ __('older') })`})),
        ...replaced_by.map(replacedItem => ({...replacedItem, name: `${replacedItem.name} (${ __('newer') })`}))
      ];

      return <PartsItemReplaces title={__('Replacements')} items={postfixArray} />;
    }

    // Separated rows
    const prefix = replaces.length > 0 ? 'Replaces' : 'Replaced by';
    const resultArray = [...replaces, ...replaced_by];
    const title = `${prefix} ${resultArray.length > 1 ? __('DENSO part numbers') : __('DENSO part number')}`;

    return <PartsItemReplaces
      title={title}
      items={resultArray}
      isReplaced={replaced_by.length > 0}
    />;
  }, []);

  if (!isDesktop) {
    return (
      <div className="catalog-detail__overall">
        <div className="catalog-detail__overall-info">
          <h1 className="heading heading_h2 catalog-detail__overall-title">{ partTitle }</h1>
          <div className="catalog-detail__overall-number">
            { parts.currentPart.title }
          </div>

          {
            // Car applicability
            parts.currentPart.cars?.length > 0
              ? (
                <DetailCarApplicability
                  partName={ partTitle }
                  list={ parts.currentPart.cars }
                  isApplicable={ applicable }
                />
              )
              : null
          }

          {
            storeCurrentVehicle.current
              ? (
                <div className="catalog-detail__overall-other">
                  <ArrowLink
                      title='Other products fitting this car'
                      link={`/${overall.currentPrefix}`}
                      onClick={() => filter.resetInitialLevels()}
                  />
                </div>
              )
              : null
          }

          {
            // Image Slider
            images?.length > 0
              ? <DetailSlider images={images} spinImage={spin_image_url} />
              : <div className="catalog-detail__overall-empty">
                <Icon
                  name="empty-image"
                  className="catalog-detail__overall-empty-icon"
                />
              </div>
          }

          <a
            href={overall.WTBLink}
            className="button catalog-detail__overall-wtb"
          >{ __('Where to buy?') }</a>
        </div>
      </div>
    );
  }

  return (
    <div className="catalog-detail__overall">
      <div className="grid">
        <div className="grid__col grid__col_12 grid__col_lg_5">
          {
            <DetailSlider
              images={images}
              spinImage={spin_image_url}
              docs={docs}
              isWiperBlades={isWiperBlades}
            />
          }
        </div>
        <div className="grid__col grid__col_12 grid__col_lg_7">
          <div className="catalog-detail__overall-info">
            <div className="catalog-detail__overall-number">
              { parts.currentPart.title }
            </div>
            <h1 className="heading heading_h2 catalog-detail__overall-title">{ partTitle }</h1>
            <div className="catalog-detail__overall-meta">
              <a
                href={overall.WTBLink}
                className="button button_small catalog-detail__overall-wtb"
              >
                { __('Where to buy?') }
              </a>

              <div className="catalog-detail__overall-vehicle">
                {
                  parts.currentPart.cars?.length > 0
                    ? (
                        <DetailCarApplicability
                            partName={ partTitle }
                            list={ parts.currentPart.cars }
                            isApplicable={ applicable }
                        />
                    )
                    : null
                }

                {
                  storeCurrentVehicle.current
                    ? (
                        <div className="catalog-detail__overall-other">
                          <ArrowLink
                              title={ __('Other products fitting this car') }
                              link={'/' + overall.currentPrefix}
                              onClick={() => filter.resetInitialLevels()}
                          />
                        </div>
                    )
                    : null
                }
              </div>

            </div>
            <div className="catalog-detail__overall-specs">
              <Specs list={overviewSpecs} />

              { replacesElements }
            </div>
            {specifications.length > 0 ? (
              <div className="catalog-detail__overall-more">
                <ArrowLink
                  title={ __('Show all specifications') }
                  link="#specifications"
                  isDown
                  onClick={handleSpecificationClick}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(DetailOverall);
