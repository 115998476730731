import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';

import storeUserAccount from '../../../auth/store/user-account';

import UserVehiclesItem from './components/user-vehicles-item';

const UserVehicles: FC = () => {
  if (storeUserAccount.userVehicles.length === 0) {
    return <Redirect to={{pathname: '/'}} />;
  }

  return (
    <div className="user-account__vehicles">
      {
        storeUserAccount
          .userVehicles
          .map(({name, id, type}) => (
            <UserVehiclesItem
              key={`user-vehicle-${type}-${id}`}
              title={name}
              vehicleId={id}
              vehicleType={type}
            />
          ))
      }
    </div>
  );
};

export default observer(UserVehicles);
