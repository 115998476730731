import React, { FC } from 'react';
import { HashRouter } from 'react-router-dom';

import useResize from '../denso-catalog/hooks/resize';

import UserHeading from './components/user-heading';
import UserSidebar from './components/user-sidebar';
import UserContent from './components/user-content';

const App: FC = () => {
  const isDesktop = useResize();

  if (!isDesktop) {
    return (
        <div className="user-account">
          <div className="user-account__title">My account</div>
          <HashRouter hashType="noslash">
            <div className="user-account__mmenu">
                <UserSidebar />
            </div>

            <UserHeading />
            <UserContent />
          </HashRouter>
        </div>
    );
  }

  return (
    <div className="user-account">
      <HashRouter hashType="noslash">
        <UserHeading />

        <div className="grid">
          <div className="grid__col grid__col_12 grid__col_lg_3">
            <UserSidebar />
          </div>

          <UserContent />
        </div>
      </HashRouter>
    </div>
  );
};

export default App;
