const init = (): void => {
  const articleItems = document.querySelectorAll(
    '.article-item',
  ) as NodeListOf<HTMLDivElement>;

  if (!articleItems.length) {
    return;
  }

  for (const item of Array.from(articleItems)) {
    const cta = item.querySelector('.article-item__cta');
    const heading = item.querySelector('.article-item__heading');

    cta.addEventListener('mouseenter', () => {
      heading.classList.add('cta-hovered');
    });

    cta.addEventListener('mouseleave', () => {
      heading.classList.remove('cta-hovered');
    });
  }
};

export default {
  init,
};
