import youTubeParser from '../../scripts/utils/youTubeParser';

let discoverVideoList: NodeListOf<HTMLElement>;
let bigVideo: HTMLElement;

const handleVideoClick = (e: MouseEvent): void => {
  const target = e.target as HTMLElement;

  if (target.dataset.videoId) {
    e.preventDefault();
  }
};

const events = (): void => {
  document.addEventListener('click', handleVideoClick);
};

const generateBigThumb = (): void => {
  const videoLink = bigVideo.querySelector('.about__video-link') as HTMLAnchorElement;
  const videoId = videoLink ? youTubeParser(videoLink.href) : null;

  if (videoId) {
    videoLink.dataset.videoId = videoId;

    bigVideo.insertAdjacentHTML(
      'afterbegin',
      `<div class="about__video-media">
            <img
                class="about__video-img"
                src="https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg"
                loading="lazy"
                decoding="async"
                alt="YouTube Cover"
            />
        </div>`,
    );
  }
};

const generateActivitiesThumbs = (): void => {
  discoverVideoList.forEach((discoverBlock) => {
    const videoLink = discoverBlock.querySelector('.about__discover-item-link') as HTMLAnchorElement;
    const mediaBlock = discoverBlock.querySelector('.about__discover-item-video');
    const videoId = videoLink ? youTubeParser(videoLink.href) : null;

    if (videoId && mediaBlock) {
      videoLink.dataset.videoId = videoId;

      mediaBlock.insertAdjacentHTML(
        'afterbegin',
        `<div class="about__discover-item-media">
            <img
                class="about__discover-item-img"
                src="https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg"
                loading="lazy"
                decoding="async"
                alt="YouTube Cover"
            />
        </div>`,
      );
    }
  });
};

const init = (): void => {
  discoverVideoList = document.querySelectorAll('.about__discover-item');
  bigVideo = document.querySelector('.about__video-inner');

  if (discoverVideoList.length > 0) {
    generateActivitiesThumbs();
  }

  if (bigVideo) {
    generateBigThumb();
  }

  if (discoverVideoList.length > 0 || bigVideo) {
    events();
  }
};

export default {
  init,
};
