import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import filter from '../../store/filter';
import storeCarTypes from '../../../d-search-bar/store/car-types';

const CatHeader: FC = () => {
  const rootTitle = useMemo(() => {
    if (filter.currentCategory) {
      return filter.currentCategory.title;
    }

    return `${ storeCarTypes.current.typeName } ${ __('parts') }`;
  }, [storeCarTypes.current.slug, filter.currentCategory]);

  return (
        <div className="page-fmp__content-block">
            <div className="heading-link">
                <h1 className="heading heading_h2 heading-link__title">{ rootTitle }</h1>
            </div>
        </div>
  );
};

export default observer(CatHeader);
