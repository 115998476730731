import React, { FC, ReactNode, useState } from 'react';
import cn from 'classnames';

import Icon from '../icon';

const DetailHider: FC<{
  children: ReactNode,
  title: string,
  isText?: boolean,
}> = ({
  children,
  title,
  isText = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => setIsOpen(!isOpen);

  return (
    <div className="catalog-detail__hider">
      <header className="catalog-detail__hider-header">
        <button
          className={cn(
            'catalog-detail__hider-btn',
            {'catalog-detail__hider-btn_active': isOpen},
          )}
          type="button"
          onClick={handleClick}
        >
          <span className="catalog-detail__hider-btn-text">{ title }</span>
          <span className="catalog-detail__hider-btn-icon">
            <Icon
              name="down-arrow"
              className="catalog-detail__hider-btn-svg"
            />
          </span>
        </button>
      </header>

      {
        isOpen
          ? <div className={cn(
            'catalog-detail__hider-content',
            {'catalog-detail__hider-content_text': isText},
          )}>{ children }</div>
          : null
      }
    </div>
  );
};

export default DetailHider;
