let acceptBtn = document.querySelector('.js-cookie-accept') as HTMLElement;
let cookieBar = document.querySelector('.cookie-bar') as HTMLElement;

const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const setCookie = (name, value) => {
  let expiresTime = 3.154e+7;
  let updatedCookie = `${encodeURIComponent(name)} = ${encodeURIComponent(value)}; max-age=${expiresTime}`;

  document.cookie = updatedCookie;
};

const cookiebarShow = (): void => {
  cookieBar.classList.add('cookie-bar_active');
};

const cookiebarHide = (): void => {
  cookieBar.classList.remove('cookie-bar_active');
};

const events = () => {
  acceptBtn.addEventListener('click', function (e) {
    e.preventDefault();
    setCookie('cookieAccept', '1');
    cookiebarHide();
  });
};
const init = (): void => {

  if (!getCookie('cookieAccept')) {
    cookiebarShow();
  }

  if (acceptBtn) {
    events();
  }
};

export default {
  init,
};
