import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import api from '../../../../api';

import storeVehicleBrands from '../../store/vehicle-brands';
import storeVehicleModels from '../../store/vehicle-model';
import storeVehicleYears from '../../store/vehicle-year';
import storeCarTypes from '../../store/car-types';
import { VehicleYear } from '../../store/vehicle-year/model';

import refreshVehicleStore from '../../utils/refresh-store';

import DropDown from '../dropdown';
import Column from '../column';

const VehicleYearField: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const selected = storeVehicleYears.current
    ? {
      val: storeVehicleYears.current.val,
      key: storeVehicleYears.current.key,
    }
    : null;

  const handleChange = (selectedItem: VehicleYear) => {
    refreshVehicleStore('year', selectedItem);
  };

  useEffect(() => {
    if (storeVehicleModels.current && !storeVehicleYears.current) {
      api
        .car
        .getYears(
          storeVehicleBrands.currentId,
          storeVehicleModels.currentId,
          storeCarTypes.currentId,
        )
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            const yearsList = res.data.map(year => ({
              key: year,
              val: year.toString(),
            }));

            storeVehicleYears.setList(yearsList);
          }

          setIsLoading(false);
        })
        .catch(error => console.error(error));
    }

  }, [storeVehicleModels.current]);

  return (
        <Column label={ __('Year') }>
            <DropDown
                placeholder={ __('Select year') }
                selected={selected}
                list={storeVehicleYears.list}
                onChange={handleChange}
                withIncludeSearch
                disabled={!storeVehicleModels.current}
                isOpen={storeVehicleModels.current && storeVehicleYears.list?.length > 1 && !isLoading}
            />
        </Column>
  );
};

export default observer(VehicleYearField);
