import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import storeCurrentVehicle from '../../../d-search-bar/store/current-vehicle';

import DensoSearchBar from '../../../d-search-bar/app';
import CurrentCar from '../current-car';
import overall from '../../store/overall';

import { CarType } from 'src/components/d-search-bar/store/car-types/model';
import { useHistory, useParams } from 'react-router-dom';

const VehicleSearch: FC = () => {
  const history = useHistory();
  const { vType } = useParams<{ vType: string }>();

  const onLinkClick = (type: CarType) => {
    overall.setPrefix(type.slug);
    history.push(type.link);
  };

  const typeLinks: CarType[] = [
    {
      id: 1,
      label: __('Passenger vehicle'),
      typeName: __('Automotive car'),
      slug: 'pv',
      link: '/pv',
      onClick: onLinkClick,
    },
    {
      id: 2,
      label: __('Commercial vehicle'),
      typeName: __('Commercial'),
      slug: 'cv',
      link: '/cv',
      onClick: onLinkClick,
    },
    {
      id: 3,
      label: __('Motorcycle'),
      typeName: __('Motorcycle'),
      slug: 'mc',
      link: '/mc',
      onClick: onLinkClick,
    },
  ];

  if (!storeCurrentVehicle.current) {
    return overall.prefix ? (
      <div className="page-fmp__search">
        <div className="wrapper">
          <DensoSearchBar typeList={typeLinks} initialTypeSlug={vType} />
        </div>
      </div>
    ) : null;
  }

  return <CurrentCar />;
};

export default observer(VehicleSearch);
