import React, { FC, useState } from 'react';

import __ from '../../../../utils/translation';

import MenuWrap from '../menu-wrap';
import AuthLogin from '../../../auth/components/login';
import AuthRegister from '../../../auth/components/register';
import AuthForgot from '../../../auth/components/forgot';

const AuthModal: FC = () => {
  const [currentScreen, setCurrentScreen] = useState<'login' | 'register' | 'forgot'>('login');

  const changeModalType = (newModalType: 'login') => {
    setCurrentScreen(newModalType);
  };

  const availableModals = {
    login: {
      title: __('Login'),
      component: <AuthLogin isFlat handleModalChange={changeModalType} />,
    },
    register: {
      title: __('Register'),
      component: <AuthRegister isFlat handleModalChange={changeModalType} />,
    },
    forgot: {
      title: __('Forgot password?'),
      component: <AuthForgot isFlat handleModalChange={changeModalType} />,
    },
  };

  return (
    <MenuWrap title={ availableModals[currentScreen].title }>
      <div className="mobile-menu__content mobile-menu__content_single">
        { availableModals[currentScreen].component }
      </div>
    </MenuWrap>
  );
};

export default AuthModal;
