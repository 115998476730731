import { makeAutoObservable, runInAction } from 'mobx';
import { Filter, MainFiltersStore } from '../model';

const storeStoresFilters = makeAutoObservable<MainFiltersStore>({
  list: [],
  subfiltersList: [],
  alias: 'type_of_store',
  subfilterAlias: 'subtype_of_store',
  setList(list, isSubfilter) {
    runInAction(() => {
      const convertedList: Filter[] = Object.entries(list)
        .map(([key, value]) => ({ id: parseInt(key), label: value }));

      if (isSubfilter) {
        storeStoresFilters.subfiltersList = convertedList;
      } else {
        storeStoresFilters.list = convertedList;
      }
    });
  },
});

export default storeStoresFilters;
