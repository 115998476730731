import React, { FC, ChangeEvent, KeyboardEvent } from 'react';

const TextInput: FC<{
  placeholder?: string,
  value: string,
  onChange: (value: string) => void,
  onKeyUp?: (e: KeyboardEvent) => void,
  onFocus?: () => void,
  onBlur?: () => void,
}> = ({
  placeholder = null,
  value,
  onChange,
  onKeyUp,
  onFocus,
  onBlur,
}) => {
  const setNewVin = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
        <input
            className='d-search-bar__input'
            type='text'
            placeholder={placeholder}
            value={value}
            onChange={setNewVin}
            onKeyUp={onKeyUp}
            onFocus={onFocus}
            onBlur={onBlur}
        />
  );
};

export default TextInput;
