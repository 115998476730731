import React, { FC, useEffect, ChangeEvent } from 'react';
import cn from 'classnames';

import __ from '../../../../utils/translation';

import { VehicleBrand } from '../../store/vehicle-brands/model';
import { useResize } from '../../../denso-catalog/hooks';

import DropDownItem from './components/dropdown-item';
import DropDownSearch from './components/dropdown-search';

interface SelectedItem {
  val: string,
  key: string | number,
}

const DropDown: FC<{
  placeholder?: string,
  disabled?: boolean,
  selected?: SelectedItem | null,
  withSearch?: boolean,
  withIncludeSearch?: boolean,
  popularList?: VehicleBrand[],
  list?: VehicleBrand[],
  multiList?: boolean,
  onChange?: (newItem: VehicleBrand | null) => void,
  isOpen?: boolean,
  isFullWidth?: boolean,
}> = ({
  placeholder = '',
  disabled = false,
  selected = null,
  withSearch = false,
  withIncludeSearch = false,
  popularList,
  list,
  multiList,
  onChange,
  isOpen = false,
  isFullWidth = false,
}) => {
  const isDesktop = useResize();
  const [focus, setFocus] = React.useState<boolean>(false);
  const [hoverIndex, setHoverIndex] = React.useState<number | null>(null);
  const [hoverPopularIndex, setHoverPopularIndex] = React.useState<number | null>(null);
  const [currentSearch, setCurrentSearch] = React.useState<string>('');
  const dropDownRef = React.useRef<HTMLDivElement>(null);

  const currentTitle = selected ? selected.val : placeholder;
  const selectedValue = selected ? selected.key : -1;

  const toggleFocus = (): void => {
    if (disabled) {
      return;
    }

    setFocus(!focus);
  };

  const handleClickOutside = (e: MouseEvent) => {
    const target = e.target as HTMLElement;

    if (!isDesktop) {
      return;
    }

    if (dropDownRef && !dropDownRef.current.contains(target)) {
      setFocus(false);
    }
  };

  const onSearchChange = (searchString: string) => {
    setCurrentSearch(searchString);
  };

  // Normalize List if Search
  let normalizedList = list;

  // Search from start of string
  if (withSearch) {
    normalizedList = normalizedList.filter((item) => {
      return item.val.toLowerCase().startsWith(currentSearch.trim().toLowerCase());
    });
  }

  // Search with contain search string
  if (withIncludeSearch) {
    normalizedList = normalizedList.filter((item) => {
      return item.val.toLowerCase().indexOf(currentSearch.trim().toLowerCase()) > -1;
    });
  }

  // Search Item Label Corrections
  const searchLetterDecoration = (label: string): string => {
    const searchStr = currentSearch.trim();
    const boldPart = label.substr(0, searchStr.length);
    const restPart = label.substr(searchStr.length);

    return `<strong>${boldPart}</strong>${restPart}`;
  };

  const handleChangeValue = (itemId: number | string) => {
    onChange(list.find((item) => item.key == itemId));
    setFocus(false);
    setHoverIndex(null);
    setHoverPopularIndex(null);
    setCurrentSearch('');
  };

  const handleItemHover = (itemIndex: number | null) => {
    setHoverIndex(itemIndex);
  };

  const handlePopularItemHover = (itemIndex: number | null) => {
    setHoverPopularIndex(itemIndex);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setFocus(false);
    }
  };

  useEffect(() => {
    setFocus(isOpen);
  }, [isOpen]);

  // Select First Element from Filtered List on Keypress Enter
  const selectFirstElement = () => {
    if (normalizedList.length && currentSearch.trim().length > 0) {
      handleChangeValue(normalizedList[0].key);
    }
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    handleChangeValue(e.target.value);
  };

  React.useEffect(() => {
    if (focus) {
      document.addEventListener('mousedown', handleClickOutside, false);
      document.addEventListener('keydown', handleKeyDown, false);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [focus]);

  if (!isDesktop) {
    return (
      <div
        className={cn(
          'd-search-bar__dd',
          { 'd-search-bar__dd_has-value': selected },
          { 'd-search-bar__dd_disabled': disabled },
        )}
      >
        <div
          className="d-search-bar__dd-current"
          title={currentTitle}
          onClick={toggleFocus}
        >
          <div className="d-search-bar__dd-current-text">
            { currentTitle }
          </div>
          <svg className="d-search-bar__dd-current-icon">
            <use xlinkHref="/dist/images/denso-sprite.svg#down-arrow" />
          </svg>
        </div>

        {
          !disabled
            ? <select
              className="d-search-bar__dd-select"
              onChange={handleSelectChange}
            >
              <option value={null} selected disabled />

              {
                popularList?.length > 0 && !currentSearch.trim().length
                  ? <optgroup label={ __('Popular makes') }>
                    {
                      popularList.map((item) => <option value={item.key}>{item.val}</option>)
                    }
                  </optgroup>
                  : null
              }

              {
                normalizedList && normalizedList.length
                  ? <optgroup label={placeholder}>
                    {
                      normalizedList.map((item) => (
                        <option
                          key={`dd-item-${item.key}`}
                          selected={selectedValue === item.key}
                          value={item.key}
                        >{item.val}</option>
                      ))
                    }
                  </optgroup>
                  : null
              }
            </select>
            : null
        }
      </div>
    );
  }

  return (
        <div
            className={cn(
              'd-search-bar__dd',
              { 'd-search-bar__dd_focus': focus && !disabled },
              { 'd-search-bar__dd_has-value': selected },
              { 'd-search-bar__dd_disabled': disabled },
            )}
            ref={dropDownRef}
        >
            <div
                className="d-search-bar__dd-current"
                title={currentTitle}
                onClick={toggleFocus}
            >
                <div className="d-search-bar__dd-current-text">
                    { currentTitle }
                </div>
                <svg className="d-search-bar__dd-current-icon">
                    <use xlinkHref="/dist/images/denso-sprite.svg#down-arrow" />
                </svg>
            </div>

            {
                focus
                  ? <div className={cn(
                    'd-search-bar__dd-window',
                    { 'd-search-bar__dd-window_full': isFullWidth },
                    { 'd-search-bar__dd-window_multi': multiList },
                  )}>
                            {
                                withSearch || withIncludeSearch
                                  ?   <DropDownSearch
                                            currentSearch={currentSearch}
                                            onChange={onSearchChange}
                                            onEnter={selectFirstElement}
                                        />
                                  : null
                            }

                            {
                                popularList?.length > 0 && !currentSearch.trim().length
                                  ? <>
                                        <div className='d-search-bar__dd-window-top'>
                                            <div className='d-search-bar__content-label'>{ __('Popular makes') }</div>
                                        </div>

                                        <ul className="d-search-bar__dd-list">
                                            {
                                                popularList.map((item, index) => {
                                                  return (
                                                        <DropDownItem
                                                            key={`dd-popular-item-${item.key}`}
                                                            title={item.val}
                                                            value={item.key}
                                                            index={index}
                                                            hovered={hoverPopularIndex === index}
                                                            onClick={handleChangeValue}
                                                            onHover={handlePopularItemHover}
                                                        />
                                                  );
                                                })
                                            }
                                        </ul>
                                    </>
                                  : null
                            }

                            {
                                multiList
                                  ? <div className='d-search-bar__dd-window-top'>
                                        <div className='d-search-bar__content-label'>{ __('All makes') }</div>
                                    </div>
                                  : null

                            }
                            <div className="d-search-bar__dd-wrap">
                              <ul className="d-search-bar__dd-list">
                                  {
                                      normalizedList && normalizedList.length
                                        ? normalizedList
                                          .map((item, index) => {
                                            const correctLabel = currentSearch !== '' && !withIncludeSearch
                                              ? searchLetterDecoration(item.val)
                                              : item.val;

                                            return (
                                                      <DropDownItem
                                                          key={`dd-item-${item.key}`}
                                                          title={correctLabel}
                                                          value={item.key}
                                                          index={index}
                                                          selected={selectedValue === item.key}
                                                          hovered={hoverIndex === index}
                                                          onClick={handleChangeValue}
                                                          onHover={handleItemHover}
                                                      />
                                            );
                                          })
                                        : (
                                          withSearch || withIncludeSearch
                                            ? <div className="page-fmp__filter-block-empty">{ __('No matches found') }</div>
                                            : null
                                        )
                                  }
                              </ul>
                            </div>
                        </div>
                  : null
            }
        </div>
  );
};

export default DropDown;
