import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import __ from '../../../../utils/translation';

import api from '../../../../api';

import { VehicleBody } from '../../store/vehicle-body/model';

import refreshVehicleStore from '../../utils/refresh-store';

import DropDown from '../dropdown';
import Column from '../column';
import storeVehicleBrands from '../../store/vehicle-brands';
import storeVehicleModels from '../../store/vehicle-model';
import storeVehicleYears from '../../store/vehicle-year';
import storeVehicleBodies from '../../store/vehicle-body';

const VehicleBodyField: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const selected = storeVehicleBodies.current
    ? {
      val: storeVehicleBodies.current.val,
      key: storeVehicleBodies.current.key,
    }
    : null;

  const handleChange = (selectedItem: VehicleBody) => {
    refreshVehicleStore('body', selectedItem);
  };

  useEffect(() => {
    if (storeVehicleYears.current && !storeVehicleBodies.current) {
      api
        .car
        .getBodies(
          storeVehicleBrands.currentId,
          storeVehicleModels.currentId,
          storeVehicleYears.currentId,
        )
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            storeVehicleBodies.setList(res.data);

            if (res.data.length === 1) {
              storeVehicleBodies.setCurrent(res.data[0]);
            }
          }
          setIsLoading(false);
        })
        .catch(error => console.error(error));
    }

  }, [storeVehicleYears.current]);

  return (
        <Column label={ __('Body') }>
            <DropDown
                placeholder={ __('Body type') }
                selected={selected}
                list={storeVehicleBodies.list}
                onChange={handleChange}
                withSearch
                disabled={!storeVehicleYears.current}
                isOpen={storeVehicleYears.current && storeVehicleBodies.list?.length > 1 && !isLoading}
            />
        </Column>
  );
};

export default observer(VehicleBodyField);
