import { makeAutoObservable, runInAction } from 'mobx';
import UserLocation from './model';

const storeUserLocation = makeAutoObservable<UserLocation>({
  position: null,
  setPosition(position) {
    runInAction(() => {
      storeUserLocation.position = position;
    });
  },
});

export default storeUserLocation;
