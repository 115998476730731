import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { BANNER_ANCHORS } from '../../constants';
import filter from '../../store/filter';

import Icon from '../icon';

const BannerAnchors: FC = () => {
  // Get only first or second level of category
  const levelsCount = filter.levels.length;
  const currentLevel = levelsCount > 0
    ? filter.levels[levelsCount === 1 ? 0 : 1]
    : null;

  if (!currentLevel || !currentLevel.contentBlockHandles) {
    return null;
  }

  const filteredAnchors = BANNER_ANCHORS.filter(({hash}) => hash in currentLevel.contentBlockHandles);

  return (
        <div className="product-banner__anchors">
            {
              filteredAnchors.map(({ icon, title, hash }) => (
                    <div key={`anchor-${hash}`} className="product-banner__anchors-item">
                        <div className="product-banner__anchors-item-inner">
                            <div className="product-banner__anchors-icon">
                                <Icon
                                    name={icon}
                                    className="product-banner__anchors-svg"
                                />
                            </div>
                            <a
                                href={`${currentLevel.link}#${hash}`}
                                className="product-banner__anchors-link"
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                            <div className="product-banner__anchors-arrow">
                                <Icon name="right-arrow" />
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
  );
};

export default observer(BannerAnchors);
