import React, { FC } from 'react';

import { GlobalSearchItem } from '../../model';

const SearchResultOther: FC<{
  resultList?: GlobalSearchItem[];
}> = ({
  resultList = [],
}) => {
  if (resultList?.length === 0) {
    return null;
  }

  return <>
    {
      resultList.map(({
        key,
        val,
        description,
        url,
      }) => (
        <li
          key={`denso-global-other-${key}`}
          className="global-search__modal-list-item"
        >
          <div className="global-search__other">
            <a
              href={url}
              className="global-search__other-link"
              tabIndex={-1}
            >{val}</a>

            {
              description
                ? <div className="global-search__other-section">{description}</div>
                : null
            }
          </div>
        </li>
      ))
    }
  </>;
};

export default SearchResultOther;
