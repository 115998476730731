import storeVehicleModels from '../store/vehicle-model';
import storeCarTypes from '../store/car-types';
import storeVehicleBrands from '../store/vehicle-brands';
import storeVehicleYears from '../store/vehicle-year';
import storeVehicleBodies from '../store/vehicle-body';
import storeVehicleVolumes from '../store/vehicle-volume';
import storeVehicleTypes from '../store/vehicle-type';
import refreshStore from './refresh-store';

export const setCarLists = (carData, currentCar) => {
  storeCarTypes.setCurrent(
    storeCarTypes.list.find(el => el.id === carData.vehicle_type),
  );

  storeVehicleBrands.setList(carData.filters.makers);
  storeVehicleBrands.setCurrent(
    storeVehicleBrands.list.find(el => el.val === currentCar.brand),
  );

  storeVehicleModels.setList(carData.filters.models);
  refreshStore('model', 
    storeVehicleModels.list.find(el => el.val === currentCar.model),
  );

  if (storeCarTypes.current.slug === 'cv' || storeCarTypes.current.slug === 'pv') {
    storeVehicleYears.setList(
      carData.filters.years.map(
        el => ({ key: el, val: el.toString() }),
      ),
    );

    if (currentCar.year) {
      refreshStore('year',
        storeVehicleYears.list.find(el => el.val === currentCar.year.substr(0, 4)),
      );
    } else {
      refreshStore('year', storeVehicleYears.list[0]);
    }


  }

  if (storeCarTypes.current.slug === 'pv') {
    storeVehicleBodies.setList(carData.filters.bodies);
    storeVehicleBodies.setCurrent(
      storeVehicleBodies.list.find(el => el.val === currentCar.body),
    );

    storeVehicleVolumes.setList(carData.filters.volumes);
    storeVehicleVolumes.setCurrent(
      storeVehicleVolumes.list.find(el => el.val === currentCar.volume),
    );
  }


  storeVehicleTypes.setList(carData.filters.types);
  storeVehicleTypes.setCurrent(
    storeVehicleTypes.list.find(el => el.val === currentCar.type),
  );
};
